import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('PublicationNavigateLogicService.js');
import PromiseUtil from '@/services/utils/PromiseUtil';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';

import Highlighter from '@shared/publication/highlighter';

import Locator from '@shared/publication/locator';
import AppStateEnum from '@/enums/AppStateEnum';

async function openPublication(store, router, openParams) {
  const appState = store.getters['ContextStore/appState'];
  try {
    if (!openParams || Object.keys(openParams || {}).length === 0) {
      logger.error(
        `Try open publication with empty open parameters in appState: ${appState}`
      );
      return;
    }

    const identifier = openParams.slug || openParams.publicationId;
    const currentPublicationId =
      store.getters['OpenParameterStore/getPublicationId'];
    const currentSlug = store.getters['OpenParameterStore/getSlug'];
    const isCurrentPreviewMode =
      store.getters['OpenParameterStore/isPreviewMode'];

    const hasRouteParams =
      identifier &&
      ([currentPublicationId, currentSlug].indexOf(identifier) === -1 ||
        isCurrentPreviewMode);

    const inPublicationState =
      openParams.previewMode || appState === AppStateEnum.PRESENT_PUBLICATION;

    if (inPublicationState && openParams.reopen) {
      await _reopenCurrentPublication(store);
    } else if (
      inPublicationState &&
      openParams?.openSamePara &&
      openParams?.paragraphId
    ) {
      await _reopenSamePara(store, openParams);
    } else if (inPublicationState && !hasRouteParams) {
      _beforeReopen(store);
      await store.dispatch(
        'OpenParameterStore/buildOpenParameters',
        openParams
      );
    } else if (hasRouteParams) {
      const needAddSlug = openParams.publicationId && !openParams.slug;
      if (needAddSlug) {
        _addSlugToOpenParams(store, openParams);
      }

      router.push({
        name: AppStateEnum.PRESENT_PUBLICATION,
        params: { ...openParams }
      });
    } else {
      logger.error(
        `Try to open the publication without publication id or slug by open parameters: ${JSON.stringify(
          openParams || {}
        )} in app state: ${appState}`
      );
    }
  } catch (error) {
    logger.error(
      `Get error on try to open publication with open parameters: ${openParams} in app state: ${appState}, error: ${error}`
    );
  }
}

function _addSlugToOpenParams(store, openParams) {
  const publication = store.getters['LibraryStore/getPublicationById'](
    openParams.publicationId
  );
  openParams.slug = publication.slug || publication.id;
}

function _beforeReopen(store) {
  store.commit('PublicationStore/setIsFABDisabled', true);
  store.commit('PublicationStore/setIsShownTextSelection', false);
  store.commit('OpenParameterStore/changeIsOpenFromLink', false);
}

async function reopenOnResetProgress(store, router, openParams) {
  store.commit('OpenParameterStore/changeParaId', '');
  await PromiseUtil.wait(0);
  openPublication(store, router, openParams);
}

async function reopenOnRotateKeepScrubberInViewport(store) {
  try {
    store.commit('ContextStore/setIsScrolling', true);
    const readingArea = store.getters['ProgressStore/getReturnReadingArea'];
    const currentScrubberLocator =
      store.getters['PlaybackStore/getCurrentLocator'];
    const desCurrentScrubLoc = currentScrubberLocator
      ? Locator.deserialize(currentScrubberLocator)
      : null;

    const isInsideReadingArea = desCurrentScrubLoc
      ? store.getters['ProgressStore/isInsideReadingArea'](desCurrentScrubLoc)
      : false;

    const requiredLocator = isInsideReadingArea ? currentScrubberLocator : '';
    const openLocator = new Locator.InTextRangeLocator(
      readingArea.startLocator,
      new Locator.InTextLocator(
        readingArea.startLocator.prefixedParagraphId,
        readingArea.startLocator.logicalCharOffset + 1
      )
    ).serialize();
    const isDevice = store.getters['ContextStore/isDevice'];
    const isDeviceBrowser = store.getters['ContextStore/isDeviceBrowser'];
    const renderTime = isDevice || isDeviceBrowser ? 400 : 100;
    await PromiseUtil.wait(renderTime);
    const scrollContainer = store.getters['PublicationStore/getScrollElement'];
    if (!scrollContainer) {
      logger.warn(`Didn't find scroll container on handle rotate event`);
      return;
    }
    const className = 'reading-area-anchor';
    const viewportHeight =
      scrollContainer.getBoundingClientRect().height -
      AppConstantsUtil.PROGRESS_TOOLBAR_HEIGHT -
      AppConstantsUtil.TOOLBAR_HEIGHT;

    const highlightLocator = requiredLocator || openLocator;
    highlightAnchor(highlightLocator, className, scrollContainer);
    let el = scrollContainer.querySelector(`.${className}`);
    if (!el) {
      const inTextRangeLocator = Locator.deserialize(highlightLocator);
      el = scrollContainer.querySelector(
        `#${inTextRangeLocator.startLocator.prefixedParagraphId}`
      );
    }
    if (!el) {
      logger.fatal(
        `Didn't find element after highlight by locator: ${highlightLocator} className: ${className} and by paraId`,
        {
          sendError: true
        }
      );
      return;
    }
    const correction = requiredLocator ? Math.round(viewportHeight / 2) : 0;
    const elTop =
      el.getBoundingClientRect().top -
      AppConstantsUtil.TOOLBAR_HEIGHT -
      correction;
    scrollContainer.scrollTop += elTop;
    store.commit('PublicationStore/setRotateScroll', true);
    Highlighter.undecorateByClass(className, scrollContainer);
  } catch (error) {
    logger.error(`Get error on handle orienting change error: ${error}`);
  }
}

function highlightAnchor(serializedLocator, className, scrollContainer) {
  const inTextRangeLocator = Locator.deserialize(serializedLocator);
  const element = scrollContainer.querySelector(
    `#${inTextRangeLocator.startLocator.prefixedParagraphId}`
  );
  const end = inTextRangeLocator.endLocator.logicalCharOffset;
  const start = inTextRangeLocator.startLocator.logicalCharOffset;
  const stableOffsets = [[start, end]];
  Highlighter.decorateStableOffsets(stableOffsets, element, className);
}

async function _reopenCurrentPublication(store) {
  const publicationId = store.getters['OpenParameterStore/getPublicationId'];
  store.commit('OpenParameterStore/changePublicationId', '');
  await PromiseUtil.wait(0);
  store.commit('OpenParameterStore/changePublicationId', publicationId);
}
async function _reopenSamePara(store, { paragraphId }) {
  store.commit('OpenParameterStore/changeParaId', '');
  await PromiseUtil.wait(0);
  store.commit('OpenParameterStore/changeParaId', paragraphId);
}

async function openToStartParagraphRange(store, router) {
  const paraRange = store.getters['OpenParameterStore/getParagraphRange'];
  const openParaId = store.getters['OpenParameterStore/getParaId'];
  const paragraphId = paraRange?.start || openParaId;

  store.commit('OpenParameterStore/changeParaId', '');
  await PromiseUtil.wait(0);
  return openPublication(store, router, {
    paragraphId,
    previewMode: true
  });
}

export default {
  openPublication,
  reopenOnRotateKeepScrubberInViewport,
  openToStartParagraphRange,
  reopenOnResetProgress
};
