'use strict';

function getStopWordsList() {
  return {
    أو: null,
    فى: null,
    في: null,
    كل: null,
    لم: null,
    لن: null,
    له: null,
    من: null,
    هو: null,
    هي: null,
    قوة: null,
    كما: null,
    لها: null,
    منذ: null,
    وقد: null,
    ولا: null,
    لقاء: null,
    مقابل: null,
    هناك: null,
    وقال: null,
    وكان: null,
    وقالت: null,
    وكانت: null,
    فيه: null,
    لكن: null,
    وفي: null,
    ولم: null,
    ومن: null,
    وهو: null,
    وهي: null,
    يوم: null,
    فيها: null,
    منها: null,
    يكون: null,
    يمكن: null,
    حيث: null,
    االا: null,
    اما: null,
    االتى: null,
    التي: null,
    اكثر: null,
    ايضا: null,
    الذى: null,
    الذي: null,
    الان: null,
    الذين: null,
    ابين: null,
    ذلك: null,
    دون: null,
    حول: null,
    حين: null,
    الى: null,
    انه: null,
    اول: null,
    انها: null,
    ف: null,
    و: null,
    و6: null,
    قد: null,
    لا: null,
    ما: null,
    مع: null,
    هذا: null,
    واحد: null,
    واضاف: null,
    واضافت: null,
    فان: null,
    قبل: null,
    قال: null,
    كان: null,
    لدى: null,
    نحو: null,
    هذه: null,
    وان: null,
    واكد: null,
    كانت: null,
    واوضح: null,
    ب: null,
    ا: null,
    أ: null,
    '،': null,
    عن: null,
    عند: null,
    عندما: null,
    على: null,
    عليه: null,
    عليها: null,
    تم: null,
    ضد: null,
    بعد: null,
    بعض: null,
    حتى: null,
    اذا: null,
    احد: null,
    بان: null,
    اجل: null,
    غير: null,
    بن: null,
    به: null,
    ثم: null,
    اف: null,
    ان: null,
    او: null,
    اي: null,
    بها: null
  };
}

module.exports = {
  getStopWordsList: getStopWordsList
};
