<template>
  <div class="editor-container" :class="containerClass">
    <div class="editor-block">
      <div v-if="displayCounter" class="editor-counter">
        <span class="counter-label">
          {{ $t('ContextNote.textarea.label') }}
        </span>
        <div class="textarea-counter">
          {{ currentTextLength }} /
          {{ textMaxLength }}
        </div>
      </div>
      <vue-editor
        ref="editor"
        v-model="editorContent"
        :editor-toolbar="toolbarActions"
      />
    </div>
    <div v-if="editorActions.length" class="d-flex flex-column actions-block">
      <BaseButton
        v-for="action in editorActions"
        :key="action.id"
        :class="action.actionClass"
        fab
        elevation="0"
        @click="action.handler"
      >
        <BaseSpriteIcon :icon-name="action.icon" />
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/base/BaseButton/BaseButton';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import BaseTextEditorActionNamesEnum from '@/enums/BaseTextEditorActionNamesEnum';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import PromiseUtil from '@/services/utils/PromiseUtil';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('_slug.vue');

let VueEditor, Quill;
if (process.client) {
  ({ VueEditor, Quill } = require('vue2-editor'));
}

const actionNamesByOrder = [
  BaseTextEditorActionNamesEnum.SAVE,
  BaseTextEditorActionNamesEnum.BACK
];

class ActionItem {
  constructor(buildData) {
    this.id = buildData.id;
    this.actionClass = buildData.actionClass || 'secondary-action';
    this.icon = buildData.icon;
    this.handler = buildData.handler || this.closeWithoutSave;
  }
}

class ActionItemBuilder {
  setId(id) {
    this.id = id;
    return this;
  }

  setClass(actionClass) {
    this.actionClass = actionClass;
    return this;
  }

  setIcon(icon) {
    this.icon = icon;
    return this;
  }

  setHandler(handler) {
    this.handler = handler;
    return this;
  }

  build() {
    return new ActionItem(this);
  }
}

export default {
  name: 'BaseTextEditor',
  components: {
    BaseButton,
    VueEditor,
    BaseSpriteIcon
  },
  props: {
    content: {
      type: String,
      default: ''
    },
    containerClass: {
      type: String,
      default: ''
    },
    actionNames: {
      type: Array,
      default: () => []
    },
    displayCounter: {
      type: Boolean,
      default: true
    },
    counterLabel: {
      type: String,
      default: ''
    },
    processIconVisibility: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      quillInstance: null,
      actionLabel: this.$t('Save'),
      saveActionLabel: this.$t('Save'),
      editActionLabel: this.$t('Edit'),
      editorContent: this.content,
      editorActions: [],
      toolbarActions: [
        ['bold', 'italic', 'underline'],
        [{ color: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link']
      ],
      isLinkTooltipVisible: false,
      textMaxLength: AppConstantsUtil.MAX_NOTE_LEN,
      currentTextLength: 0,
      tooltip: null,
      QUILL_DEFAULT_REPOSITION_TIME: 50
    };
  },
  computed: {
    getEditingAnnotationShowIcon() {
      return this.$store.getters[
        'AnnotationsStore/getEditingAnnotationShowIcon'
      ];
    }
  },
  watch: {
    content(val) {
      this.editorContent = val;
    }
  },
  mounted() {
    if (!this.$refs?.editor || !this.$refs?.editor?.quill) {
      this.closeWithoutSave();
      return;
    }
    this.quillInstance = this.$refs.editor.quill;

    const editor = document.querySelector('.ql-editor');
    editor?.setAttribute('dir', 'auto');
    const toolbar = document.querySelector('.ql-toolbar');
    toolbar?.setAttribute('dir', 'ltr');
    this.setTooltipData();
    this.addEditorEvents();
    if (this.actionNames.length) {
      this.createEditorActions();
    }
  },
  methods: {
    setTooltipActionLabel(label) {
      this.actionLabel = label;
      this.tooltip
        .querySelector('.ql-action')
        .setAttribute('data-action', label);
    },
    setTooltipData() {
      this.tooltip = document.querySelector('.ql-tooltip');
      this.tooltip.setAttribute('data-label', 'Link:');
      const tooltipRemoveLink = this.tooltip.querySelector('.ql-remove');
      tooltipRemoveLink.setAttribute('data-action', 'Remove');
      const tooltipInput = this.tooltip.querySelector('input');
      tooltipInput.dataset.link = '';
    },
    addFixingUrls() {
      const Link = Quill.import('formats/link');
      Link.sanitize = function(url) {
        const hasWhitelistedProtocol = this.PROTOCOL_WHITELIST.some(protocol =>
          url.startsWith(protocol)
        );
        return hasWhitelistedProtocol ? url : `http://${url}`;
      };
      Quill.register(Link, true);
    },
    selectionChangeEventsHandler(range) {
      const hasSelection = range?.length;
      if (hasSelection) {
        this.isLinkTooltipVisible = false;
        this.changeLinkTooltipStyles();
        return;
      }

      if (!this.isLinkTooltipVisible && range?.hasOwnProperty('length')) {
        this.setTooltipActionLabel(this.editActionLabel);
        this.isLinkTooltipVisible = true;
      } else if (range === null) {
        this.setTooltipActionLabel(this.saveActionLabel);
        this.isLinkTooltipVisible = true;
      } else {
        this.isLinkTooltipVisible = false;
      }

      this.changeLinkTooltipStyles();
    },
    textChangeHandler(delta) {
      const changeLink = delta?.ops.some(item =>
        item?.attributes?.hasOwnProperty('link')
      );
      if (changeLink) {
        this.tooltip.removeAttribute('style');
        this.setTooltipActionLabel(this.editActionLabel);
      }
      const editorTextLength = this.quillInstance.getLength();
      if (editorTextLength - 1 > this.textMaxLength) {
        this.quillInstance.deleteText(this.textMaxLength, editorTextLength);
      }

      this.currentTextLength = this.quillInstance.getLength() - 1;

      if (this.processIconVisibility) {
        this.changeEditingAnnotationIconVisibility();
      }
    },
    addEditorEvents() {
      this.addFixingUrls();

      this.quillInstance.on('selection-change', range => {
        this.selectionChangeEventsHandler(range);
      });

      this.quillInstance.on('text-change', delta => {
        this.textChangeHandler(delta);
      });
    },
    changeEditingAnnotationIconVisibility() {
      if (this.currentTextLength > 0 && this.getEditingAnnotationShowIcon) {
        return;
      }
      this.$store.dispatch(
        'AnnotationsStore/changeEditingAnnotationShowIcon',
        !!this.currentTextLength
      );
    },
    createEditorActions() {
      this.editorActions = actionNamesByOrder.reduce((actions, actionName) => {
        const action = this.createActionByName(actionName);
        actions.push(action);
        return actions;
      }, []);
    },
    async changeLinkTooltipStyles() {
      this.tooltip.style.marginTop = '';
      if (this.tooltip.classList.contains('ql-hidden')) {
        this.tooltip.removeAttribute('style');
        return;
      }

      await PromiseUtil.wait(this.QUILL_DEFAULT_REPOSITION_TIME);
      this.tooltip.style.opacity = 0;

      const editor = document.querySelector('.ql-editor');
      const editorWidth = editor.getBoundingClientRect().width;
      const editorStyles = window.getComputedStyle(editor);
      const editorPaddingLeft =
        parseInt(editorStyles?.getPropertyValue('padding-left')) || 0;

      const tooltipWidth = this.tooltip.getBoundingClientRect().width;
      let tooltipLeft = parseFloat(this.tooltip.style.left);
      if (tooltipLeft < 0) {
        tooltipLeft = editorPaddingLeft;
      } else if (tooltipLeft > editorWidth - tooltipWidth) {
        tooltipLeft = editorWidth - tooltipWidth - editorPaddingLeft;
      }
      this.tooltip.style.left = `${tooltipLeft}px`;

      if (parseInt(this.tooltip.style.top) < 0) {
        const editorPaddingTop =
          parseInt(editorStyles?.getPropertyValue('padding-top')) || 0;
        this.tooltip.style.top = `${this.tooltip.offsetHeight +
          editorPaddingTop}px`;
      }

      this.tooltip.style.opacity = 1;
      this.tooltip.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
    createActionByName(actionName) {
      let actionItemBuilder = new ActionItemBuilder();

      switch (actionName) {
        case BaseTextEditorActionNamesEnum.SAVE:
          actionItemBuilder
            .setId(BaseTextEditorActionNamesEnum.SAVE)
            .setClass('main-action')
            .setIcon('ico-check')
            .setHandler(this.saveEditing);
          break;

        case BaseTextEditorActionNamesEnum.BACK:
          actionItemBuilder
            .setId(BaseTextEditorActionNamesEnum.BACK)
            .setIcon('ico-close')
            .setHandler(this.closeWithoutSave);
          break;

        default:
          logger.warn(`Get unsupported Action Name: ${actionName}`);
          break;
      }

      let actionItem = actionItemBuilder.build();
      return actionItem;
    },
    saveEditing() {
      this.removeEventListeners();
      this.$emit('editorEvent', {
        type: BaseTextEditorActionNamesEnum.SAVE,
        content: this.editorContent
      });
    },
    closeWithoutSave() {
      this.removeEventListeners();
      this.$emit('editorEvent', { type: BaseTextEditorActionNamesEnum.BACK });
    },
    removeEventListeners() {
      if (this.quillInstance) {
        this.quillInstance.off('text-change');
        this.quillInstance.off('selection-change');
      }
    }
  }
};
</script>

<style lang="less" src="./BaseTextEditor.less" />
