import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('LocalAssetReader.vue');
import throttle from 'lodash/throttle';
import AssetResourcesEnum from '@/enums/AssetResourcesEnum';
import AssetTypeEnum from '@/enums/AssetTypeEnum';
import TrackingItemChangeTypes from '@/enums/TrackingItemChangeTypes';
import CustomErrorEnum from '@/enums/CustomErrorEnum';

import { AssetsManagerConstans } from '@/services/AssetsManager/AssetsManagerConstans';
import FileTransport from '@/services/AssetsManager/FileTransport/FileTransport';
import PoolService from '@/services/PoolService';
import PromiseUtil from '@/services/utils/PromiseUtil';

import TrackingItem from './TrackingItem';
import AssetInfo from './AssetInfo';
import ContentTable from './ContentTable';
import { AbstractAssetReader } from './AbstractAssetReader';

class LocalAssetReader extends AbstractAssetReader {
  constructor(coverExtension, audioExtension) {
    super(coverExtension, audioExtension);
    this.hasSdcard = false;
    this.localResoures = this.hasSdcard
      ? [
          AssetResourcesEnum.SD_CARD,
          AssetResourcesEnum.FS,
          AssetResourcesEnum.LOCAL_DB
        ]
      : [AssetResourcesEnum.FS, AssetResourcesEnum.LOCAL_DB];
    this.allResources = [...this.localResoures, AssetResourcesEnum.REMOTE];
    this.tempSources = [AssetResourcesEnum.FS_TEMP];

    this.throttleUpdateContentTable = throttle(this._updateContentTable, 1000);
    this.throttleUpdateMapSource = throttle(this._updateMapSource, 1000);
  }

  async initBookAssets(bookId, forced) {
    if (this.hasTrackingItemById(bookId) && !forced) {
      return this._getTrackingItemById(bookId);
    }
    this._addOrUpdateTrackingItemsMapObj(bookId);
    const publicationTrackingItem = TrackingItem.createPublicationTrackingItem(
      bookId
    );
    const mapSourcePath = publicationTrackingItem.createFileMapPath();
    const contentsTablePath = publicationTrackingItem.createСontentsTablePath();

    const [fileMaps, contentTables] = await Promise.all([
      this._readMapSource(mapSourcePath, forced),
      this._readLocalContentTables(contentsTablePath)
    ]);
    const readFileMapObj = this._findReadFileMap(fileMaps);
    const updateFileMapObj = this._findUpdateFileMap(fileMaps);
    const readFileMap = readFileMapObj.fileMap;
    const updateFileMap = updateFileMapObj.fileMap;
    if (!readFileMapObj.assetInfo || !readFileMapObj.assetInfo.isValid) {
      this._resetTrackingItemDefer(bookId);
      const error = new Error(
        `Did not find valid readFileMap for publication with bookId:${bookId}`
      );
      error.type = CustomErrorEnum.READ_FILE_MAP_ERROR;
      throw error;
    }
    publicationTrackingItem.initFileMap(
      readFileMap,
      updateFileMap,
      contentTables
    );
    this.destroyTrackingItem(bookId);
    this._setTrackingItem(bookId, publicationTrackingItem);
    return publicationTrackingItem;
  }

  _optimizeFileMap(fileMap) {
    const assetsForOptimize = [AssetTypeEnum.COVER, AssetTypeEnum.IMAGE];
    assetsForOptimize.forEach(assetName => {
      const asset = fileMap.assets[assetName];
      const optAsset = asset.reduce((optAssetList, logicalFileName) => {
        if (logicalFileName.indexOf(this.coverExtension) === -1) {
          delete fileMap.map[logicalFileName];
        } else {
          optAssetList.push(logicalFileName);
        }
        return optAssetList;
      }, []);
      fileMap.assets[assetName] = optAsset;
    });

    return fileMap;
  }

  async _readMapSource(mapSourcePath, forced) {
    if (forced) {
      const [local, remote] = await Promise.all([
        this._readLocalMapSource(mapSourcePath),
        this._readRemoteMapSource(mapSourcePath)
      ]);
      return [...local, remote];
    }
    const local = await this._readLocalMapSource(mapSourcePath);
    const hasLocal = local.some(mapSource => mapSource.fileMap.source);
    if (hasLocal) {
      return local;
    }
    const remote = await this._readRemoteMapSource(mapSourcePath);
    return [remote];
  }

  _readLocalMapSource(mapSourcePath) {
    const readOptions = this.readOptionsHelper.getParsedJsonOptions();
    // TODO: remove when TrackingItem will be divided into BookTrackingItem and CompilationTrackingItem
    const shouldIncludeLocalDB = mapSourcePath.includes('compilation');
    const resource = shouldIncludeLocalDB ? ['fs', 'localDb'] : ['fs'];
    const promises = resource.map(async localResoure => {
      try {
        const serializedfileMap = await this._readLocalFile(
          mapSourcePath,
          localResoure,
          readOptions
        );
        const fileMap = this._optimizeFileMap(serializedfileMap);
        fileMap.source = localResoure;
        return {
          fileMap: fileMap,
          assetInfo: AssetInfo.createAssetInfo(fileMap)
        };
      } catch (error) {
        const isMissing = error ? error.code === 8 : false;
        if (error && error.type === CustomErrorEnum.FS_UNAVAILABLE) {
          logger.warn(error.message);
        } else if (!isMissing) {
          logger.error(`get error on read map source error: ${error}`);
        }
        const fileMap = {
          source: null
        };
        return {
          fileMap,
          assetInfo: AssetInfo.createAssetInfo(fileMap)
        };
      }
    });

    return Promise.all(promises);
  }

  _readRemoteMapSource(mapSourcePath) {
    // TODO: remove when TrackingItem will be divided into BookTrackingItem and CompilationTrackingItem
    const shouldMakeRequest = !mapSourcePath.includes('compilation');
    const fileMap = {
      source: null
    };
    const defaultResponse = {
      fileMap,
      assetInfo: AssetInfo.createAssetInfo(fileMap)
    };
    if (!shouldMakeRequest) {
      return Promise.resolve(defaultResponse);
    }
    const remoteFileAccess = this._createFileAccess(
      mapSourcePath + '?_=' + new Date().getTime(),
      AssetResourcesEnum.REMOTE
    );
    const readOptions = this.readOptionsHelper.getParsedJsonOptions();
    return FileTransport.readFile(remoteFileAccess, readOptions)
      .then(remote => {
        remote.source = AssetResourcesEnum.REMOTE;
        const optimizeFileMap = this._optimizeFileMap(remote);
        return {
          fileMap: optimizeFileMap,
          assetInfo: AssetInfo.createAssetInfo(remote)
        };
      })
      .catch(() => {
        return defaultResponse;
      });
  }

  _readLocalFile(filePath, localResoure, readOptions) {
    const contentsTableAccess = this._createFileAccess(filePath, localResoure);
    return FileTransport.readFile(contentsTableAccess, readOptions);
  }

  _readLocalContentTables(contentsTablePath) {
    // TODO: remove when TrackingItem will be divided into BookTrackingItem and CompilationTrackingItem
    const resource = contentsTablePath.includes('compilation')
      ? ['fs', 'localDb']
      : ['fs'];
    const promises = resource.map(localResoure => {
      return this._readLocalContentTable(contentsTablePath, localResoure);
    });
    return Promise.all(promises);
  }

  async _readLocalContentTable(contentsTablePath, localResoure) {
    try {
      const contentsTableAccess = this._createFileAccess(
        contentsTablePath,
        localResoure
      );
      const readOptions = this.readOptionsHelper.getParsedJsonOptions();
      const serializedContentTable = await FileTransport.readFile(
        contentsTableAccess,
        readOptions
      );

      return ContentTable.createContentTable(
        serializedContentTable,
        localResoure
      );
    } catch (error) {
      const isMissing = error ? error.code === 8 : false;
      if (error && error.type === CustomErrorEnum.FS_UNAVAILABLE) {
        logger.warn(error.message);
      } else if (!isMissing) {
        logger.error(`get error on read content table error:${error}`);
      }
      const defaultVal = {};
      return ContentTable.createContentTable(defaultVal, localResoure);
    }
  }

  _findReadFileMap(fileMaps) {
    return fileMaps.reduce(function(prevFileMap, currentFileMap) {
      return prevFileMap.assetInfo.compareReadSources(
        currentFileMap.assetInfo
      ) > 0
        ? prevFileMap
        : currentFileMap;
    });
  }

  _findUpdateFileMap(fileMaps) {
    return fileMaps.reduce(function(prevFileMap, currentFileMap) {
      return prevFileMap.assetInfo.compareUpdateSources(
        currentFileMap.assetInfo
      ) > 0
        ? prevFileMap
        : currentFileMap;
    });
  }

  addChangeListener(publicationId, listener, id) {
    return this._getTrackingItemById(publicationId).then(function(
      trackingItem
    ) {
      trackingItem.addListener(listener, id);
    });
  }

  removeDownloadListener(publicationId, id) {
    return this._getTrackingItemById(publicationId).then(function(
      trackingItem
    ) {
      trackingItem.removeListener(id);
    });
  }

  readBookMetaFile(bookId) {
    const readOptions = this.readOptionsHelper.getParsedJsonOptions();
    return this._readFullFile(
      bookId,
      AssetsManagerConstans.bookMeta,
      readOptions
    );
  }

  readAudioFile(bookId, fileName) {
    return Promise.resolve().then(() => {
      const fullFileName = this._createFullFileNameByAsset(
        fileName,
        AssetTypeEnum.AUDIO
      );
      const useCache = true;
      const readOptions = this.readOptionsHelper.getBlobOptions(useCache);
      return this._readFullFile(bookId, fullFileName, readOptions);
    });
  }

  readAlignmentIndex(bookId) {
    const useCache = true;
    const readOptions = this.readOptionsHelper.getParsedJsonOptions(useCache);
    return this._readFullFile(
      bookId,
      AssetsManagerConstans.alignmentMap,
      readOptions
    );
  }

  readerPauseMapFile(bookId) {
    const useCache = true;
    const readOptions = this.readOptionsHelper.getParsedJsonOptions(useCache);
    return this._readFullFile(
      bookId,
      AssetsManagerConstans.pauseMapFileName,
      readOptions
    );
  }

  readContentIndexes(bookId) {
    const readOptions = this.readOptionsHelper.getParsedJsonOptions();
    return this._readFullFile(
      bookId,
      AssetsManagerConstans.contentIndexName,
      readOptions
    );
  }

  bookContentByChunk(bookId, chunkOffset) {
    const readOptions = this.readOptionsHelper.getArrayBufferChunkOptions(
      chunkOffset
    );
    return this._readChunkFile(
      bookId,
      AssetsManagerConstans.contentFileName,
      readOptions
    );
  }

  bookAlignmentByChunk(bookId, chunkOffset) {
    const useCache = true;
    const readOptions = this.readOptionsHelper.getArrayBufferChunkOptions(
      chunkOffset,
      useCache
    );
    return this._readChunkFile(
      bookId,
      AssetsManagerConstans.alignmentFileName,
      readOptions
    );
  }

  async removePublication(publicationId) {
    const trackingItem = await this._getTrackingItemById(publicationId);
    const targetSource = AssetResourcesEnum.FS;

    const assetNames = this.getAssetNameList(trackingItem);
    for (const assetName of assetNames) {
      await this._removeAsset(trackingItem, assetName);
    }

    const response = await this._removePublicationStateFiles(
      trackingItem,
      targetSource
    );
    const removeAssetResponse = response[1];
    const defaultVal = {};
    const contentTable = ContentTable.createContentTable(
      defaultVal,
      targetSource
    );
    trackingItem.updateContentsTables(contentTable, targetSource);
    trackingItem.calculateProgress();

    trackingItem.changeNotify({}, TrackingItemChangeTypes.REMOVE);
    return removeAssetResponse;
  }

  _removePublicationStateFiles(trackingItem, targetSource) {
    const self = this;
    const contentsTablePath = trackingItem.createСontentsTablePath();
    const mapSourcePath = trackingItem.createFileMapPath();
    return Promise.all([
      self._removeFile(mapSourcePath, targetSource),
      self._removeFile(contentsTablePath, targetSource)
    ]).catch(function(error) {
      logger.error(
        `Get error on _removePublicationStateFile from ${targetSource} ${mapSourcePath} ${contentsTablePath} file error: ${error}`
      );
    });
  }

  async _removeAsset(trackingItem, assetName) {
    const targetSource = AssetResourcesEnum.FS;
    const newSource = trackingItem.updateMapper.assetInfo.source;
    const logicalNames = trackingItem.getAssetsByName(assetName);

    const promises = logicalNames.map(logicalName => {
      const filePath = trackingItem.createAssetFilePath(logicalName);
      return this._removeFile(filePath, targetSource)
        .then(function() {
          const size = 0;
          trackingItem.removeFromContentTable(logicalName, targetSource, size);
          const source = trackingItem.getAssetSourceByLogicalName(logicalName);
          trackingItem.setAssetFileSource(logicalName, source);
        })
        .catch(function() {
          logger.error(`Get error on delet file ${filePath}`);
          trackingItem.setAssetFileSource(
            logicalName,
            AssetResourcesEnum.REMOTE
          );
        });
    });
    const contentsTablePath = trackingItem.createСontentsTablePath();
    await Promise.all(promises);
    await this._removeFile(contentsTablePath, targetSource);

    const contentsTable = trackingItem.findContentsTableBySource(targetSource);
    trackingItem.calculateProgress();
    await this.writeFile(
      contentsTablePath,
      contentsTable.toJson(),
      targetSource
    );

    return TrackingItem.createAssetActionResponse(assetName, newSource);
  }

  _removeFile(filePath, source) {
    const fileAccess = this._createFileAccess(filePath, source);
    return FileTransport.removeFile(fileAccess);
  }

  cancelDownload(publicationId) {
    const self = this;
    return self
      ._getTrackingItemById(publicationId)
      .then(function(trackingItem) {
        trackingItem.changeNotify({}, TrackingItemChangeTypes.CANCEL);
        const logicalNames = trackingItem.getAllFiles();
        return self._removeLogicalFilesFromDownloadQueue(
          trackingItem,
          logicalNames
        );
      })
      .then(() => {
        return self.removePublication(publicationId);
      });
  }

  _removeLogicalFilesFromDownloadQueue(trackingItem, logicalNames) {
    const filePaths = logicalNames.map(function(logicalName) {
      return trackingItem.createAssetFilePath(logicalName);
    });
    PoolService.clearByIds(filePaths);
    return PromiseUtil.wait(100); //wait for finish all write operation on fs
  }

  async removeAll(accessType, excludeEntries) {
    const fileAccess = this._createFileAccess(null, accessType);
    PoolService.clear(excludeEntries);
    await FileTransport.removeAll(fileAccess, excludeEntries);
    this.destroyAllTrackingItem();
  }

  async cancelDownloadAsset(publicationId, assetName) {
    const trackingItem = await this._getTrackingItemById(publicationId);
    const logicalNames = trackingItem.getAssetsByName(assetName);
    trackingItem.changeNotify({}, TrackingItemChangeTypes.ASSET_CANCELED);
    await this._removeLogicalFilesFromDownloadQueue(trackingItem, logicalNames);
    return this._removeAsset(trackingItem, assetName);
  }

  async removeAssets(publicationId, assetNames) {
    const trackingItem = await this._getTrackingItemById(publicationId);
    for (const assetName of assetNames) {
      await this._removeAsset(trackingItem, assetName);
    }
    trackingItem.changeNotify({}, TrackingItemChangeTypes.ASSETS_REMOVED);
  }

  async updatePublication(publicationId, excludeAssetNames) {
    const trackingItem = await this._getTrackingItemById(publicationId);
    trackingItem.updateInfo.optimize();
    await this._downloadUpdateDelta(trackingItem, excludeAssetNames);
    const isExist = true;
    await this._updateMapSource(trackingItem, isExist);
    trackingItem.changeNotify({}, TrackingItemChangeTypes.UPDATED);
    await this._removeOldFiles(trackingItem, excludeAssetNames);
    await this._actualizeContentTable(trackingItem);
    this.destroyTrackingItem(publicationId);
    const updatedTrackingItem = await this.initBookAssets(publicationId);
    return updatedTrackingItem;
  }

  async _downloadUpdateDelta(trackingItem, excludeAssetNames) {
    trackingItem.useUpdateMapper();
    const downloadUpdateItems = trackingItem.getDownloadUpdateItems();

    const downloadPromises = [];
    const targetSource = AssetResourcesEnum.FS;
    downloadUpdateItems.forEach(downloadUpdateItem => {
      const { logicalName, assetName, source, hash } = downloadUpdateItem;
      if (excludeAssetNames.includes(assetName)) {
        return;
      }
      trackingItem.setAssetFileSource(logicalName, source);
      trackingItem.setFileHash(logicalName, hash);

      const priorityFnName = this._getDownloadPriority(assetName);

      const downloadPromise = this._downloadLogicalFile(
        trackingItem,
        logicalName,
        targetSource,
        priorityFnName
      );
      downloadPromises.push(downloadPromise);
    });

    PoolService.flush();
    await Promise.all(downloadPromises);
  }

  async _updateMapSource(trackingItem, isExist) {
    const mapSourcePath = trackingItem.createFileMapPath();
    const fromSource = trackingItem.getFileMapSource();
    const toSource = AssetResourcesEnum.FS;
    const downloadOptions = {
      cancelToken: null,
      cdnInvalidate: fromSource === AssetResourcesEnum.REMOTE
    };
    if (isExist) {
      await this._removeFile(mapSourcePath, toSource);
    }
    return this._downloadFile(
      mapSourcePath,
      mapSourcePath,
      fromSource,
      toSource,
      downloadOptions
    );
  }

  _removeOldFiles(trackingItem, excludeAssetNames) {
    const removeUpdateItems = trackingItem.getRemoveUpdateItems();
    const downloadUpdateItems = trackingItem.getDownloadUpdateItems();
    const removePromises = removeUpdateItems.map(removeUpdateItem => {
      const { logicalName, source, hash, assetName } = removeUpdateItem;
      if (excludeAssetNames.includes(assetName)) {
        return;
      }
      const filePath = trackingItem.createFilePath(hash);
      return this._removeFile(filePath, source).then(function() {
        trackingItem.progress -= 1;
        const updateItem = downloadUpdateItems.find(function(item) {
          return item.logicalName === logicalName;
        });
        if (!updateItem) {
          trackingItem.removeAssetFileSource(logicalName);
        }

        const updateItemByHash = downloadUpdateItems.find(function(item) {
          return item.hash === hash;
        });
        if (!updateItemByHash) {
          trackingItem.removeFromContentTableByFileHash(hash, source, 0);
        }
      });
    });
    return Promise.all(removePromises);
  }

  _actualizeContentTable(trackingItem) {
    const targetSource = AssetResourcesEnum.FS;
    const contentsTable = trackingItem.findContentsTableBySource(targetSource);
    const contentsTablePath = trackingItem.createСontentsTablePath();
    return this._removeFile(contentsTablePath, targetSource)
      .catch(function(error) {
        logger.error(
          `Get error on remove ${contentsTablePath} from ${targetSource} for update publication error: ${error}`
        );
      })
      .then(() => {
        return this.writeFile(
          contentsTablePath,
          contentsTable.toJson(),
          targetSource
        );
      });
  }

  async downloadAsset(publicationId, assetName) {
    const trackingItem = await this._getTrackingItemById(publicationId);
    return this._downloadAsset(trackingItem, assetName).then(() => {
      this._notifyAssetDownloaded(trackingItem, assetName);
    });
  }

  async isAssetDownloaded(publicationId, assetName) {
    const trackingItem = await this._getTrackingItemById(publicationId);
    const mapSourcePath = trackingItem.createFileMapPath();
    const fileAccess = this._createFileAccess(
      mapSourcePath,
      AssetResourcesEnum.FS
    );
    const isExist = await FileTransport.isExist(fileAccess);
    if (!isExist) {
      await this.throttleUpdateMapSource(trackingItem, isExist);
    }
    return this._isDownloaded(trackingItem, assetName);
  }

  _isDownloaded(trackingItem, assetName) {
    return (
      trackingItem.isAssetAvailable(assetName, AssetResourcesEnum.FS) ||
      trackingItem.isAssetAvailable(assetName, AssetResourcesEnum.SD_CARD)
    );
  }

  downloadPublication(publicationId) {
    const self = this;
    let trackingItem;
    return self
      ._getTrackingItemById(publicationId)
      .then(function(_trackingItem) {
        trackingItem = _trackingItem;
        const isExist = false;
        return self._updateMapSource(trackingItem, isExist);
      })
      .then(function() {
        const assetNames = self.getAssetNameList(trackingItem.hasAudio);
        const promises = assetNames.map(function(assetName) {
          return self._downloadAsset(trackingItem, assetName).then(() => {
            self._notifyAssetDownloaded(trackingItem, assetName);
          });
        });
        return Promise.all(promises);
      });
  }

  _notifyAssetDownloaded(trackingItem, assetName) {
    const assetDownloadedInfo = {
      assetName
    };
    trackingItem.changeNotify(
      assetDownloadedInfo,
      TrackingItemChangeTypes.ASSET_DOWNLOADED
    );
  }

  _getDownloadPriority(assetType) {
    switch (assetType) {
      case AssetTypeEnum.CONTENT:
      case AssetTypeEnum.COVER:
      case AssetTypeEnum.IMAGE:
        return 'unshift';
      case AssetTypeEnum.ALIGNMENT:
      case AssetTypeEnum.AUDIO:
        return 'push';
      default:
        return 'push';
    }
  }

  async _downloadAsset(trackingItem, assetName) {
    const self = this;
    const targetSource = AssetResourcesEnum.FS;
    const assetLogicalNames = trackingItem.getAssetsByName(assetName);
    const priorityFnName = self._getDownloadPriority(assetName);

    const promises = assetLogicalNames.map(function(logicalName) {
      return self._downloadLogicalFile(
        trackingItem,
        logicalName,
        targetSource,
        priorityFnName
      );
    });
    PoolService.flush();
    await Promise.all(promises);
    return TrackingItem.createAssetActionResponse(assetName, targetSource);
  }

  async _updateContentTable(trackingItem, targetSource) {
    const contentsTablePath = trackingItem.createСontentsTablePath();
    const contentsTable = trackingItem.findContentsTableBySource(targetSource);
    await this.writeFile(
      contentsTablePath,
      contentsTable.toJson(),
      targetSource
    );
  }

  _downloadLogicalFile(
    trackingItem,
    logicalName,
    targetSource,
    priorityFnName
  ) {
    const self = this;

    const source = trackingItem.getAssetFileSource(logicalName);
    if (source === targetSource) {
      return Promise.resolve();
    }

    const filePath = trackingItem.createAssetFilePathBySource(
      logicalName,
      targetSource
    );
    const hasVersionFile = trackingItem.hasVersionFile(logicalName);
    if (!hasVersionFile) {
      const versionError = new Error(
        `Did not find version file logicalName:${logicalName} for publicationId:${trackingItem.id}`
      );
      versionError.type = CustomErrorEnum.INVALID_ASSET_VERSION_FILE;
      throw versionError;
    }
    const downloadFilePath = trackingItem.createDownloadFilePath(logicalName);
    const cancelToken = PoolService.createCancelToken('Canceled queue');
    const downloadOptions = {
      cancelToken,
      cdnInvalidate: false
    };
    const toSource = AssetResourcesEnum.FS;
    const command = self._downloadFile.bind(
      self,
      downloadFilePath,
      filePath,
      source,
      toSource,
      downloadOptions
    );

    return PoolService[priorityFnName](command, filePath, cancelToken)
      .then(function(downloadInfo) {
        if (cancelToken && cancelToken.isCanceled) {
          return Promise.reject({
            type: CustomErrorEnum.CLEAR_QUEUE,
            message: cancelToken.message
          });
        }
        trackingItem.progress += 1;
        downloadInfo.progress = trackingItem.progress;
        trackingItem.changeNotify(
          downloadInfo,
          TrackingItemChangeTypes.DOWNLOAD
        );
        trackingItem.updateContentTable(
          logicalName,
          targetSource,
          downloadInfo.size
        );
        trackingItem.setAssetFileSource(logicalName, targetSource);
        return self.throttleUpdateContentTable(trackingItem, targetSource);
      })
      .catch(function(err) {
        if (err && err.type === CustomErrorEnum.CLEAR_QUEUE) {
          return;
        }
        logger.warn(`Error while downloading: ${err}`);
        trackingItem.changeNotify(err, TrackingItemChangeTypes.ERROR);
        throw err;
      });
  }

  _downloadFile(
    downloadFilePath,
    filePath,
    fromSource,
    toSource,
    downloadOptions
  ) {
    const { cancelToken, cdnInvalidate } = downloadOptions;
    const self = this;
    if (cancelToken && cancelToken.isCanceled) {
      return Promise.reject({
        type: CustomErrorEnum.CLEAR_QUEUE,
        message: cancelToken.message
      });
    }
    return self
      ._readFullFileAsBlob(downloadFilePath, fromSource, cdnInvalidate)
      .then(function(blob) {
        if (cancelToken && cancelToken.isCanceled) {
          return Promise.reject({
            type: CustomErrorEnum.CLEAR_QUEUE,
            message: cancelToken.message
          });
        }
        return Promise.all([self.writeFile(filePath, blob, toSource), blob]);
      })
      .then(function(response) {
        const blob = response[1];
        const size = blob.size;
        const downloadInfo = {
          size
        };
        return downloadInfo;
      });
  }

  _readFullFileAsBlob(filePath, fromSource, cdnInvalidate) {
    const self = this;
    const suffix = cdnInvalidate ? '?_=' + new Date().getTime() : '';
    const fileAccess = self._createFileAccess(filePath + suffix, fromSource);
    const readOptions = this.readOptionsHelper.getBlobOptions();
    return FileTransport.readFile(fileAccess, readOptions);
  }

  writeFile(filePath, data, source) {
    const fileAccess = this._createFileAccess(filePath, source);
    return FileTransport.writeFile(fileAccess, data);
  }

  _readFullFile(bookId, fileName, readOptions) {
    const self = this;
    return self
      ._getTrackingItemById(bookId)
      .then(function(publicationTrackingItem) {
        const filePath = publicationTrackingItem.createAssetFilePath(fileName);
        const accessType = publicationTrackingItem.getAssetFileSource(fileName);

        const fileAccess = self._createFileAccess(filePath, accessType);
        return FileTransport.readFile(fileAccess, readOptions);
      });
  }

  _readChunkFile(bookId, fileName, readOptions) {
    const self = this;
    return self
      ._getTrackingItemById(bookId)
      .then(function name(publicationTrackingItem) {
        const contentFilePath = publicationTrackingItem.createAssetFilePath(
          fileName
        );
        const accessType = publicationTrackingItem.getAssetFileSource(fileName);

        const fileAccess = self._createFileAccess(contentFilePath, accessType);
        return FileTransport.readFile(fileAccess, readOptions);
      });
  }

  getFileSourcePath(bookId, fileName, assetType) {
    const self = this;
    return self
      ._getTrackingItemById(bookId)
      .then(function name(publicationTrackingItem) {
        const fullFileName = self._createFullFileNameByAsset(
          fileName,
          assetType
        );
        return self._getFilePath(fullFileName, publicationTrackingItem);
      });
  }

  _getFilePath(fullFileName, publicationTrackingItem) {
    const accessType = publicationTrackingItem.getAssetFileSource(fullFileName);
    const filePath = publicationTrackingItem.createAssetFilePathBySource(
      fullFileName,
      accessType
    );
    const fileAccess = this._createFileAccess(filePath, accessType);
    const readOptions = this.readOptionsHelper.getBinaryOptions();

    return FileTransport.createFileSourcePath(fileAccess, readOptions);
  }

  readFile(bookId, fileName) {
    const readOptions = this.readOptionsHelper.getUtf8Options();
    return this._readFullFile(bookId, fileName, readOptions);
  }
  readFileByChunk(bookId, fileName, chunkOffset) {
    const readOptions = this.readOptionsHelper.getUtf8ChunkOptions(chunkOffset);
    return this._readChunkFile(bookId, fileName, readOptions);
  }

  async switchAssetInfo(bookId, fromAssetName, toAssetName, assetType) {
    const trackingItem = await this._getTrackingItemById(bookId);
    const fullFromAssetName = this._createFullFileNameByAsset(
      fromAssetName,
      assetType
    );
    const fullToAssetName = this._createFullFileNameByAsset(
      toAssetName,
      assetType
    );
    trackingItem.switchAssetInfo(fullFromAssetName, fullToAssetName);
  }

  async replaceAssetInfo(bookId, source, target) {
    const trackingItem = await this._getTrackingItemById(bookId);
    const fullFromAssetName = this._createFullFileNameByAsset(
      source,
      AssetTypeEnum.AUDIO
    );
    const fullToAssetName = this._createFullFileNameByAsset(
      target,
      AssetTypeEnum.AUDIO
    );
    trackingItem.replaceAssetInfo(fullFromAssetName, fullToAssetName);
  }

  async removeAssetInfo(bookId, target) {
    const trackingItem = await this._getTrackingItemById(bookId);
    const fullAssetName = this._createFullFileNameByAsset(
      target,
      AssetTypeEnum.AUDIO
    );
    trackingItem.removeAssetInfo(fullAssetName);
  }

  async deleteAssetFile(bookId, assetFileName, assetType) {
    const trackingItem = await this._getTrackingItemById(bookId);
    const fullAssetFileName = this._createFullFileNameByAsset(
      assetFileName,
      assetType
    );
    trackingItem.deleteAssetFile(fullAssetFileName);
  }

  async checkPublicationAssetsOnSources(bookId, assetsNames, sources) {
    const promises = assetsNames.map(assetName => {
      return this.checkPublicationAssetOnSources(bookId, assetName, sources);
    });

    return Promise.all(promises);
  }

  async checkPublicationAssetOnSources(bookId, assetName, sources) {
    const trackingItem = await this._getTrackingItemById(bookId);
    const assetLogicalNames = trackingItem.getAssetsByName(assetName);

    const promises = assetLogicalNames.map(async logicalName => {
      const source = trackingItem.getAssetSourceByLogicalName(logicalName);
      if (!sources.includes(source)) {
        return Promise.resolve();
      }

      const filePath = trackingItem.createDownloadFilePath(logicalName);
      const fileAccess = this._createFileAccess(filePath, source);
      const fileData = await FileTransport.getFileInfo(fileAccess);
      return fileData;
    });
    const filesData = await Promise.all(promises);
    const valid = filesData.every(fileData => {
      return fileData && fileData.size !== 0;
    });
    return {
      assetName,
      valid
    };
  }
}

export { LocalAssetReader };
