<template>
  <div class="essay-task">
    <div class="pic-block">
      <BaseSpriteIcon
        custom-class="essay-icon"
        icon-name="pic-essay"
        view-box="0 0 32 32"
      />
      <BaseSpriteIcon
        v-if="essay.isCompleted"
        custom-class="completed-icon"
        icon-name="ico-checked"
      />
    </div>
    <div class="text-block">
      <div class="text-block-title">
        {{ essay.topic }} Class aptent taciti sociosqu ad litora torquent per
        conubia nostra
      </div>
      <div class="text-block-comment">
        {{ essay.comment }} Class aptent taciti sociosqu ad litora torquent per
        conubia nostra
      </div>
      <div class="text-block-controls">
        <div>{{ essaySymbolsNumLabel }}</div>
        <div
          v-if="essay.isCompleted"
          class="text-block-statistics"
          @click="viewCompletedEssay"
        >
          <span>{{ completedAt }}</span>
          <span class="link">View</span>
        </div>
        <div v-if="!essay.isCompleted" class="link" @click="editEssay">
          Edit
        </div>
      </div>
    </div>
    <EssayPopup
      :is-shown="isEssayPopupShown"
      :essay="essay"
      :essay-words-count="essayWordsCount"
      :get-symbols-num-label-fn="getSymbolsNumLabel"
      :get-completed-at-label-fn="getCompletedAtLabel"
      @essayPopupEvent="essayPopupEventHandler"
    />
  </div>
</template>

<script>
import MenuTasks from '@/enums/MenuTasks';
import MaterialTypes from '@/enums/MaterialTypes';
import EssayPopup from '@/components/views/EssayPopup/EssayPopup';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import dayJS from '@/dayJS';

export default {
  name: 'Essay',
  components: {
    EssayPopup,
    BaseSpriteIcon
  },
  props: {
    materialView: Object
  },
  data() {
    return {
      isEssayPopupShown: false,
      essay: this.materialView,
      isSubmitMenuShown: false,
      essayWordsCount: 0
    };
  },
  computed: {
    essaySymbolsNumLabel() {
      return this.getSymbolsNumLabel(this.essayWordsCount);
    },
    completedAt() {
      return this.getCompletedAtLabel(this.essay.completedAt);
    }
  },
  watch: {
    materialView() {
      this.essay = this.materialView;
    }
  },
  mounted() {
    this.essayWordsCount = this.$_wordsCount(this.essay.text);
  },

  methods: {
    editEssay() {
      this.isEssayPopupShown = true;
    },
    getCompletedAtLabel(completedAt) {
      return dayJS.get(completedAt).format('MM.DD.YYYY');
    },
    viewCompletedEssay() {
      this.isEssayPopupShown = true;
    },
    essayPopupEventHandler(payload) {
      if (payload.text) {
        this.essay.text = payload.text;
        if (payload.shouldRecalculateSymbols) {
          this.essayWordsCount = this.$_wordsCount(this.essay.text);
          return;
        }
        const materialPayload = {
          materialData: this.essay,
          task: payload.isCompleted ? MenuTasks.ADD : MenuTasks.CHANGE,
          materialType: MaterialTypes.ESSAY
        };

        this.$emit('materialEvent', materialPayload);
        if (payload.isCompleted) {
          this.essay.isCompleted = true;
        }
      }
      this.isEssayPopupShown = false;
    },
    getSymbolsNumLabel(wordsNum) {
      return `${this.$t(
        'PresentPublicationEssayTaskTemplate.words.label'
      )} ${wordsNum}/${this.essay.wordsLimit}`;
    },
    $_wordsCount(text) {
      this.essay.text = text;
      let trimmedText = text.trim();
      if (trimmedText.length === 0) {
        return 0;
      }
      return trimmedText.split(' ').length;
    }
  }
};
</script>

<style scoped lang="less">
@import 'Essay.less';
</style>
