import LoggerFactory from '@/services/utils/LoggerFactory';
import AssetsManager from '@/services/AssetsManager/AssetsManager';
import { sizes as thumbSize } from '@shared/enums/ThumbSizesEnum';
import AssetResourcesEnum from '@/enums/AssetResourcesEnum';
import MetaInfoConfigUtils from '@/services/utils/MetaInfoConfigUtils';

const logger = LoggerFactory.getLogger('utils/FontLoader.js');

const getCoverPath = async meta => {
  return await AssetsManager.buildBookCoverPath(
    meta.fileName,
    meta.cover,
    thumbSize.XLARGE,
    AssetResourcesEnum.REMOTE,
    { fileFormat: '.png' }
  );
};

const pricingPageHeadInfo = async (clientReaderUrl, pageName) => {
  try {
    if (!pageName) {
      logger.error('No pagename provided in pricing page head info');
      return MetaInfoConfigUtils.emptyHeadInfo();
    }
    const url = MetaInfoConfigUtils.getPageUrl(clientReaderUrl, pageName);
    const metaInfo = [
      {
        name: 'title',
        hid: 'title',
        content: pageName
      }
    ];
    const canonicalLink = [MetaInfoConfigUtils.getCanonicalLinkSetting(url)];
    return MetaInfoConfigUtils.createHeadInfoBuilder()
      .setMeta(metaInfo)
      .setLink(canonicalLink)
      .setTitle(pageName)
      .build();
  } catch (error) {
    logger.warn(`Get pricing page meta info ended with ${error}`);
    return MetaInfoConfigUtils.emptyHeadInfo();
  }
};

const categoryPageHeadInfo = async (categoryUrl, categoryName) => {
  try {
    if (!categoryName) {
      logger.error(`No category name provided in category page head info`);
      return MetaInfoConfigUtils.emptyHeadInfo();
    }

    const url = MetaInfoConfigUtils.getPageUrl(categoryUrl, categoryName);
    const metaInfo = [
      {
        name: 'title',
        hid: 'title',
        content: categoryName
      }
    ];
    const canonicalLink = [MetaInfoConfigUtils.getCanonicalLinkSetting(url)];
    return MetaInfoConfigUtils.createHeadInfoBuilder()
      .setMeta(metaInfo)
      .setLink(canonicalLink)
      .setTitle(categoryName)
      .build();
  } catch (error) {
    logger.warn(`Get category page meta info ended with ${error}`);
    return MetaInfoConfigUtils.emptyHeadInfo();
  }
};

const bookPageHeadInfo = async (
  clientReaderUrl,
  meta,
  appDescription,
  publicationId
) => {
  try {
    if (!meta) {
      logger.error(`No meta provided in book ${publicationId} page head info`);
      return MetaInfoConfigUtils.emptyHeadInfo();
    }

    const image = await getCoverPath(meta);
    const pageName = meta.slug || meta.fileName;
    const url = MetaInfoConfigUtils.getPageUrl(clientReaderUrl, pageName);
    const description = MetaInfoConfigUtils.applyDescriptionSEORules(
      meta.shortDescription || meta.description || appDescription
    );
    const title = meta.name || meta.title;
    const metaInfo = [
      {
        name: 'description',
        hid: 'description',
        content: description
      },
      {
        name: 'author',
        hid: 'author',
        content: meta.author || ''
      },
      {
        name: 'title',
        hid: 'title',
        content: title
      },
      {
        property: 'og:type',
        hid: 'og:type',
        content: 'book'
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: meta.author + ', ' + meta.name
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: description
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: image
      },
      {
        property: 'og:image:width',
        hid: 'og:image:width',
        content: '200'
      },
      {
        property: 'og:image:height',
        hid: 'og:image:height',
        content: '263'
      },
      {
        hid: 'og:url',
        property: 'og:url',
        content: url
      },
      {
        hid: 'twitter:title',
        property: 'twitter:title',
        content: meta.author + ', ' + meta.name
      },
      {
        hid: 'twitter:description',
        property: 'twitter:description',
        content: description
      },
      {
        hid: 'twitter:image',
        property: 'twitter:image',
        content: image
      },
      {
        hid: 'twitter:url',
        property: 'twitter:url',
        content: url
      }
    ];
    const linkInfo = [MetaInfoConfigUtils.getCanonicalLinkSetting(url)];

    return MetaInfoConfigUtils.createHeadInfoBuilder()
      .setMeta(metaInfo)
      .setLink(linkInfo)
      .setTitle(title)
      .build();
  } catch (error) {
    logger.warn(`Get book page meta info ended with ${error}`);
    return MetaInfoConfigUtils.emptyHeadInfo();
  }
};

const canonicalLinkSetting = (clientReaderUrl, pageName) => {
  const url = MetaInfoConfigUtils.getPageUrl(clientReaderUrl, pageName);
  return MetaInfoConfigUtils.getCanonicalLinkSetting(url);
};

export default {
  canonicalLinkSetting,
  bookPageHeadInfo,
  emptyHeadInfo: MetaInfoConfigUtils.emptyHeadInfo,
  categoryPageHeadInfo,
  pricingPageHeadInfo
};
