import { BNC } from '@shared/enums/TestTypesEnum';

export default {
  ANNOTATION: 'Annotation',
  BOOKMARK: 'Bookmark',
  BNC,
  PARA_NOTE: 'ParaNote',
  QUIZ: 'Quiz',
  ESSAY_TASK: 'EssayTask',
  ESSAY: 'Essay',
  FLASHCARD: 'Flashcard',
  PARA_SUMMARY: 'ParaSummary',
  VOCABULARY: 'Vocabulary'
};
