import Utils from '@/services/utils/Utils';
import CookieQueryParamsService from '@/services/CookieQueryParamsService';

function getAllParams(router) {
  const cookieQuery = CookieQueryParamsService.getAllParams();
  const route = _getCurrentRoute(router);
  const routerQuery = { ...route.query };
  return { ...cookieQuery, ...routerQuery };
}

function getQueryParamByRouter(parmaName, router) {
  const route = _getCurrentRoute(router);
  return getQueryParamByRoute(parmaName, route);
}

function getQueryParamByRoute(parmaName, route) {
  const cookieQuery = CookieQueryParamsService.getAllParams();
  return cookieQuery[parmaName]
    ? cookieQuery[parmaName]
    : route.query[parmaName];
}

function _getCurrentRoute(router) {
  const currentRoute = _getRouteByBiggerQuery(router);
  const hashQuery = Utils.parseUrlQuery(
    Utils.removeHashIfNeeded(currentRoute.hash)
  );
  const query = { ...currentRoute.query, ...hashQuery };
  const route = { ...currentRoute, query };
  return route;
}

function _getRouteByBiggerQuery(router) {
  const pendingRoute = router.history?.pending;
  const currentRoute = router.currentRoute;
  if (
    Object.keys(pendingRoute?.query || {}).length >
    Object.keys(currentRoute?.query || {}).length
  ) {
    return pendingRoute;
  }
  return currentRoute;
}

function removeAllParams(router) {
  const route = _getCurrentRoute(router);
  CookieQueryParamsService.removeAllParams();
  router.push({
    path: route.path,
    params: route.params,
    hash: '',
    query: {}
  });
}

function removeParams(router, paramNames, removeHash = false) {
  const route = _getCurrentRoute(router);
  const routerQuery = { ...route.query };
  paramNames.forEach(paramName => {
    delete routerQuery[paramName];
    CookieQueryParamsService.removeParam(paramName);
  });

  if (!Object.keys(routerQuery).length && !removeHash) {
    return;
  }

  router.push({
    name: route.name,
    params: route.params,
    hash: removeHash ? '' : route.hash,
    query: routerQuery
  });
}

export default {
  getAllParams,
  removeAllParams,
  removeParams,
  getQueryParamByRouter,
  getQueryParamByRoute
};
