<template>
  <div class="para-summary">
    <!-- ICON SHOULD BE HERE -->
    <v-textarea
      v-model="mView.text"
      :placeholder="
        $t('PresentPublicationMicrojournallingTemplate.add.placeholder')
      "
      :maxlength="maxSummaryLength"
      @blur="onBlur"
    />
  </div>
</template>

<script>
import MaterialTypes from '@/enums/MaterialTypes';
import MenuTasks from '@/enums/MenuTasks';

const MAX_SUMMARY_LENGTH = 500;

export default {
  name: 'ParagraphSummary',
  components: {},
  props: {
    materialView: Object
  },
  data() {
    return {
      mView: this.materialView,
      maxSummaryLength: MAX_SUMMARY_LENGTH
    };
  },

  methods: {
    onBlur() {
      this.$emit('materialEvent', {
        materialType: MaterialTypes.PARA_SUMMARY,
        materialData: this.mView,
        task: MenuTasks.CHANGE
      });
    }
  }
};
</script>

<style scoped lang="less">
@import 'ParagraphSummary.less';
</style>
