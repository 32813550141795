import { render, staticRenderFns } from "./CreateAnnotationPopup.vue?vue&type=template&id=0d10c349"
import script from "./CreateAnnotationPopup.vue?vue&type=script&lang=js"
export * from "./CreateAnnotationPopup.vue?vue&type=script&lang=js"
import style0 from "./CreateAnnotationPopup.less?vue&type=style&index=0&prod&lang=less&scopped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardActions } from 'vuetify/lib/components/VCard';
installComponents(component, {VCardActions})
