import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('OpenAppStrategyFactory.js');
import OpenAppStrategyEnum from '@/enums/OpenAppStrategyEnum';
import AppStateEnum from '@/enums/AppStateEnum';
import BrandsEnum from '@/enums/BrandsEnum';

class LoginOpenAppStrategy {
  constructor(type) {
    this.type = type;
    this.allowedPages = [
      AppStateEnum.LOGIN,
      AppStateEnum.RESET_PASSWORD,
      AppStateEnum.CONFIRMATION,
      AppStateEnum.FORBIDDEN_IN_BROWSER,
      AppStateEnum.TASK_CONFIRMATION
    ];
    this.defaultRoute = AppStateEnum.LOGIN;
    this.defaultGuestRoute = AppStateEnum.MANAGE_ABOUT;
    this.afterLoginRoute = AppStateEnum.MANAGE_PUBLICATION;
    this.afterLoginRouteEditor = AppStateEnum.BOOKS_LIST;
    this.afterConfirmResetRoute = AppStateEnum.RESET_PASSWORD;
    this.confirmationRoute = AppStateEnum.CONFIRMATION;
    this.afterOauthRoute = AppStateEnum.LOGIN;
    this.needGuestAutoLogin = false;
    this.showConfirmState = true;
  }

  toJSON() {
    return JSON.stringify({
      type: this.type,
      allowedPages: this.allowedPages,
      defaultRoute: this.defaultRoute,
      defaultGuestRoute: this.defaultGuestRoute,
      afterLoginRoute: this.afterLoginRoute,
      afterConfirmResetRoute: this.afterConfirmResetRoute,
      confirmationRoute: this.confirmationRoute,
      afterOauthRoute: this.afterOauthRoute,
      needGuestAutoLogin: this.needGuestAutoLogin,
      showConfirmState: this.showConfirmState
    });
  }

  getDefaultRoute(isGuest, brand) {
    if (isGuest && brand === BrandsEnum.FFA) {
      return this.defaultGuestRoute;
    }
    return this.defaultRoute;
  }

  getAfterLoginRoute() {
    if (process.env.IS_EDITOR) {
      return this.afterLoginRouteEditor;
    }
    return this.afterLoginRoute;
  }

  getAfterConfirmResetRoute() {
    return this.afterConfirmResetRoute;
  }

  getConfirmationRoute() {
    return this.confirmationRoute;
  }

  getAfterOauthRoute() {
    return this.afterOauthRoute;
  }

  isNeedShowConfirmState() {
    return this.showConfirmState;
  }

  isNeedGuestAutoLogin() {
    return this.needGuestAutoLogin;
  }

  isAllowedPage(name) {
    return this.allowedPages.some(path => {
      return name === path;
    });
  }
}

class LibraryOpenAppStrategy extends LoginOpenAppStrategy {
  constructor(type) {
    super(type);
    this.allowedPages = [];
    this.defaultRoute = AppStateEnum.MANAGE_PUBLICATION;
    this.defaultGuestRoute = AppStateEnum.MANAGE_ABOUT;
    this.confirmationRoute = AppStateEnum.MANAGE_PUBLICATION;
    this.afterConfirmResetRoute = AppStateEnum.MANAGE_PUBLICATION;
    this.afterOauthRoute = AppStateEnum.MANAGE_PUBLICATION;
    this.needGuestAutoLogin = true;
    this.showConfirmState = true;
  }

  isAllowedPage() {
    return true;
  }

  getDefaultRoute(isGuest, brand, isUserEmpty) {
    if (isUserEmpty || (isGuest && brand === BrandsEnum.FFA)) {
      return this.defaultGuestRoute;
    }
    return this.defaultRoute;
  }
}

function getOpenAppStrategy(type) {
  switch (type) {
    case OpenAppStrategyEnum.LOGIN_PAGE:
      return new LoginOpenAppStrategy(type);
    case OpenAppStrategyEnum.LIBRARY_PAGE:
      return new LibraryOpenAppStrategy(type);
    default:
      logger.warn(
        `Set default open app strategy ${OpenAppStrategyEnum.LOGIN_PAGE}`
      );
      return new LoginOpenAppStrategy(type);
  }
}

export default {
  getOpenAppStrategy
};
