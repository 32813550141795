import dayJS from '@/dayJS';
import Locator from '@shared/publication/locator';
import LoggerFactory from '@/services/utils/LoggerFactory';
import HighlightUtils from '@/services/utils/HighlightUtils';
import MarkerUtils from '@shared/publication/dom-utils/marker-utils';
import contentUtils from '@shared/utils/contentUtils';

import cryptoRandomString from 'crypto-random-string';
import BrandsEnum from '@/enums/BrandsEnum';
import PromiseUtil from '@/services/utils/PromiseUtil';

const logger = LoggerFactory.getLogger('Utils.js');

function shortUuid() {
  return 'xxxxyyy'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    /*jshint bitwise: false*/
    /*jshint eqeqeq:  false*/
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function encodeHex(str) {
  return str.replace(/./g, function(r, i) {
    return (i ? '-' : '') + r.charCodeAt(0).toString(16);
  });
}

function utf8ArrayToStr(content) {
  // http://www.onicos.com/staff/iz/amuse/javascript/expert/utf.txt
  var array = new Uint8Array(content);
  var out = '',
    i = 0,
    len = array.length,
    c;
  var char2, char3;
  while (i < len) {
    c = array[i++];
    switch (c >> 4) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        // 0xxxxxxx
        out += String.fromCharCode(c);
        break;
      case 12:
      case 13:
        // 110x xxxx   10xx xxxx
        char2 = array[i++];
        out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
        break;
      case 14:
        // 1110 xxxx  10xx xxxx  10xx xxxx
        char2 = array[i++];
        char3 = array[i++];
        out += String.fromCharCode(
          ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0)
        );
        break;
    }
  }
  return out;
}

function normalizeAlignment(arraybuffer, frameSize) {
  const delta = arraybuffer.byteLength % frameSize;
  return arraybuffer.slice(0, arraybuffer.byteLength - delta);
}

function parseRawIndex(arraybuffer) {
  var dataView = new DataView(arraybuffer);

  var FRAME_SIZE = 14;

  var paraId;
  var wordPosition;
  var wordLength;
  var audioStart;
  var audioEnd;
  var alignment = {};

  var para;

  if (arraybuffer.byteLength % FRAME_SIZE) {
    logger.warn(
      `Get invalid audio index buffer size ${arraybuffer.byteLength} to FRAME_SIZE ${FRAME_SIZE}. Remove last frame.`
    );
    arraybuffer = normalizeAlignment(arraybuffer, FRAME_SIZE);
    dataView = new DataView(arraybuffer);
  }

  for (
    var currentPosition = 0, l = arraybuffer.byteLength;
    currentPosition < l;
    currentPosition += FRAME_SIZE
  ) {
    paraId = dataView.getInt16(currentPosition);
    wordPosition = dataView.getInt16(currentPosition + 2);
    wordLength = dataView.getInt16(currentPosition + 4);
    audioStart = dataView.getInt32(currentPosition + 6);
    audioEnd = dataView.getInt32(currentPosition + 10);

    para = 'para_' + paraId;
    if (!alignment.hasOwnProperty(para)) {
      alignment[para] = [[], []];
    }

    alignment[para][0].push([audioStart, audioEnd]);
    alignment[para][1].push([paraId, wordPosition, wordLength].join('.'));
  }
  return alignment;
}

function ab2str(buf) {
  return String.fromCharCode.apply(null, new Uint16Array(buf));
}

function str2ab(str) {
  var buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
  var bufView = new Uint16Array(buf);
  for (var i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

function waitForWebfonts(fontCheckerContainer, fonts, callback) {
  const waitFontPromises = [];
  const wait = font => waitForFont(fontCheckerContainer, font);
  fonts.forEach(font => {
    waitFontPromises.push(wait(font));
  });
  Promise.all(waitFontPromises).then(() => {
    callback();
  });
}

function waitForFont(fontCheckerContainer, font) {
  return new Promise(resolve => {
    var node = fontCheckerContainer.cloneNode(false);
    fontCheckerContainer.appendChild(node);
    // Characters that vary significantly among different fonts
    node.innerHTML = 'giItT1WQy@!-/#';
    // Visible - so we can measure it - but not on the screen
    node.style.position = 'absolute';
    node.style.left = '-10000px';
    node.style.top = '-10000px';
    // Large font size makes even subtle changes obvious
    node.style.fontSize = '300px';
    // Reset any font properties
    // node.style.fontFamily = 'sans-serif';
    node.style.fontVariant = 'normal';
    node.style.fontStyle = 'normal';
    node.style.fontWeight = 'normal';
    node.style.letterSpacing = '0';

    var width = node.offsetWidth;
    node.style.fontFamily = font;
    node.style['line-height'] = 'normal';

    var interval = setInterval(() => {
      if (
        (node && node.offsetWidth != width) ||
        (document.fonts && document.fonts.check('1em ' + font))
      ) {
        logger.log(
          'loaded font:' + font,
          ' offsetWidth: ' + node.offsetWidth,
          ' width: ' + width
        );
        clearInterval(interval);
        resolve();
      }
    }, 50);
  });
}

function getBaseDeepLink(brand) {
  return brand === 'ffa' ? 'wholereader://' : 'oceanreader://';
}

function getShareLink(serverUrl, bookId, rangeLocator) {
  const { startLocator, endLocator } = rangeLocator;
  const shouldHighlightSelection =
    startLocator._paragraphNumber === endLocator._paragraphNumber;
  const selectionStart = Locator.serialize(startLocator);
  const selectionEnd = Locator.serialize(endLocator);
  const selection = shouldHighlightSelection
    ? '&selection=' + selectionStart + '_' + selectionEnd
    : '';
  const paraId = startLocator.prefixedParagraphId;
  return `${serverUrl}?key=ocean-viewer&bookId=${bookId}&paraId=${paraId +
    selection}`;
}

async function getPublicationLink({
  clientUrl,
  identifier,
  userId,
  locator,
  brand
}) {
  const params = { clientUrl, identifier, userId, locator, brand };

  return buildPublicationLink(params);
}

function buildPublicationLink({
  clientUrl,
  identifier,
  userId,
  locator,
  brand
}) {
  let link = `${clientUrl}${identifier}/?`;
  if (brand === BrandsEnum.FFA) {
    link += `uid=${userId}&`;
  }

  if (!locator) {
    return link;
  }

  const paraId = locator.startLocator.prefixedParagraphId;
  link += `paraId=${paraId}&selectionString=`;
  const startBlockId =
    MarkerUtils.getBlockIdByLocator(locator.startLocator) ||
    locator.startLocator.paragraphId;
  const endBlockId =
    MarkerUtils.getBlockIdByLocator(locator.endLocator) ||
    locator.endLocator.paragraphId;

  link += HighlightUtils.createSelectionStringWithBlockId(
    locator,
    startBlockId,
    endBlockId
  );

  return link;
}

function maskEmail(email) {
  const symbolBeforeAtIndex = email.indexOf('@') - 1;
  const domainString = email.substr(symbolBeforeAtIndex + 1);
  return `${email[0]}..${email[symbolBeforeAtIndex]}${domainString}`;
}

function safeJsonParse(data, defaultVal = null) {
  try {
    return JSON.parse(data);
  } catch (error) {
    logger.error(
      `Get error on safe pares json  data:${data}, return defaultVal:${defaultVal} error:${error}`
    );
    return defaultVal;
  }
}

function getTodayMidnight() {
  const date = new Date();
  date.setUTCHours(0, 0, 0, 0);
  return date;
}

function shiftDaysFromDate(date, number) {
  return date.setDate(date.getDate() - number);
}

function getFormattedPrice(price) {
  if (!Number.isInteger(price)) {
    return;
  }
  return (price > 0 ? price / 100 : price).toFixed(2);
}

function dateFormat(dateInMs, format = 'll') {
  return dayJS.get(dateInMs).format(format);
}

function getFormattedMinutes(minutes, format = 'H:mm') {
  return dayJS
    .get()
    .hour(0)
    .second(0)
    .minute(minutes)
    .format(format);
}

function parseUrlQuery(url) {
  let queryStrings = url.match(/[^&^/]*=[^&]*/gm) || [];
  let query = {};
  queryStrings.forEach(param => {
    let querySplit = param.split('=');
    query[querySplit[0]] = querySplit[1];
  });
  return query;
}

function removeHashIfNeeded(path) {
  return path[0] === '#' ? path.substring(1) : path;
}

function getWordsArrayFromString(string) {
  string = string || '';
  string = string.replace(/(\r\n|\n|\r)/gm, ' ');
  string = string.trim();
  return !string ? [] : string.split(/\s+/);
}

function getWordsCountInString(string) {
  const wordsArray = getWordsArrayFromString(string);
  return wordsArray.length;
}

function getTruncatedStringToWordsCount(string, wordsCount) {
  const wordsArray = getWordsArrayFromString(string);
  return wordsArray.slice(0, wordsCount).join(' ');
}

function getParsedBlogUrl(url) {
  try {
    return url.replace(/^(?:https?:\/\/)?|\/?$/gi, '');
  } catch (error) {
    logger.error(`Parsing blog url ${url} failed with ${error}`);
    return url;
  }
}

function getSafeAreaInsetTop() {
  return _getComputedPropertyVal('--sat');
}

function getSafeAreaInsetBottom() {
  return _getComputedPropertyVal('--sat-bottom');
}
function _getComputedPropertyVal(propertyName) {
  let safeAreaTopValue = getComputedStyle(
    document.documentElement
  ).getPropertyValue(propertyName);
  safeAreaTopValue = safeAreaTopValue.replace('px', '');
  return parseInt(safeAreaTopValue) || 0;
}

function pathJoin(paths) {
  let result = [];
  for (let i = 0; i < paths.length; i++) {
    result = result.concat(paths[i].split('/').filter(p => !!p));
  }
  return result.join('/');
}

function randomCryptoString(length) {
  return cryptoRandomString(length);
}

function estimateReadingTime(wordsNumber) {
  return (wordsNumber / 140) * 60000;
}

function estimateMinutesReadingTime(wordsNumber) {
  const timeInMs = estimateReadingTime(wordsNumber);
  return Math.floor(timeInMs / (1000 * 60));
}

function estimateHoursReadingTime(wordsNumber) {
  const timeInMs = estimateReadingTime(wordsNumber);
  return Math.floor(timeInMs / (1000 * 60 * 60));
}

function estimateNotRoundedHoursReadingTime(wordsNumber) {
  const timeInMs = estimateReadingTime(wordsNumber);
  return +(timeInMs / (1000 * 60 * 60)).toFixed(2);
}

async function waitDuration(audioEl, attempt = 0) {
  attempt += 1;
  await PromiseUtil.wait(100);
  const duration = audioEl.getDuration();
  if (isNaN(duration) && attempt < 20) {
    return this.waitDuration(audioEl, attempt);
  } else if (isNaN(duration)) {
    logger.warn(`Can't get audio duration after 20 attempt by 100ms ranges`);
  }
}

function convertBytesToProperSize(bytes) {
  const sizes = ['b', 'kb', 'mb', 'gb', 'tb'];
  if (!bytes) {
    return `${bytes} ${sizes[0]}`;
  }

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());
  if (i === 0) {
    return `${bytes} ${sizes[i]}`;
  }

  return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
}

export default {
  parseUrlQuery,
  removeHashIfNeeded,
  encodeHex,
  randomCryptoString,
  ab2str,
  str2ab,
  shortUuid,
  uuid,
  utf8ArrayToStr,
  waitForWebfonts,
  parseRawIndex,
  getBaseDeepLink,
  getShareLink,
  maskEmail,
  safeJsonParse,
  getTodayMidnight,
  shiftDaysFromDate,
  isRtl: contentUtils.isRtl,
  getDirection: contentUtils.getDirection,
  getPublicationLink,
  buildPublicationLink,
  getFormattedPrice,
  getFormattedMinutes,
  dateFormat,
  getWordsCountInString,
  getTruncatedStringToWordsCount,
  getParsedBlogUrl,
  getSafeAreaInsetTop,
  getSafeAreaInsetBottom,
  pathJoin,
  estimateReadingTime,
  estimateMinutesReadingTime,
  estimateHoursReadingTime,
  estimateNotRoundedHoursReadingTime,
  waitDuration,
  convertBytesToProperSize
};
