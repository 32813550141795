'use strict';

var englishStopWords = require('./englishStopWords.js');
var englishWordsUtils = require('./englishWordsUtils.js');
var exp = {}; 
[englishStopWords, englishWordsUtils].forEach(function(module) {
   Object.keys(module).forEach(function(methodName) {
      exp[methodName] = module[methodName];
   });
});

module.exports = exp;