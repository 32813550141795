function setCookie(cookieName, value) {
  if (process.server) {
    return;
  }
  document.cookie = `${cookieName}=${value}; Path=/;`;
}

function deleteCookie(cookieName) {
  if (process.server) {
    return;
  }
  document.cookie = `${cookieName}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

function getCookie(cookieName) {
  const defaultResp = null;
  if (process.server) {
    return defaultResp;
  }
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${cookieName}=`);
  if (parts.length === 2) {
    return parts
      .pop()
      .split(';')
      .shift();
  }
  return defaultResp;
}

function deleteAllCookies() {
  if (process.server) {
    return;
  }
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    const cookieName = name.trim();
    deleteCookie(cookieName);
  }
}

export default {
  setCookie,
  deleteCookie,
  deleteAllCookies,
  getCookie
};
