import LocalStorageService from './services/LocalStorageService';

import AppModeEnum from './enums/AppModeEnum';
import BrandsEnum from './enums/BrandsEnum';
// import LoggerFactory from './services/utils/LoggerFactory';
// const logger = LoggerFactory.getLogger('i18n.js');

const locales = process.env.CLIENT_CONFIG?.supportedLanguages || [];
const defaultLocale = 'en';

let _Context;

function _getLangSettingsKey(mode) {
  return `languages-${mode}_languages-${mode}`;
}

async function _getLocaleMessage(lang, brand, appMode) {
  let message = await _loadLocales(`language.cmn.${lang}.json`);

  if (appMode === AppModeEnum.EDITOR) {
    const editorMessage = await _loadLocales(
      `language.${appMode}.${lang}.json`
    );
    message = { ...message, ...editorMessage };
  }

  if (brand === BrandsEnum.OCEAN) {
    const oceanMessage = await _loadLocales(
      `locales-ocean/custom.language.cmn.${lang}.json`
    );
    message = { ...message, ...oceanMessage };
  }
  return message;
}

async function _mergeEditorFallbackMessage(appMode) {
  if (appMode !== AppModeEnum.EDITOR) {
    return;
  }

  const fallbackEditorTranslation = await _loadLocales(
    `language.${appMode}.${_Context.fallbackLocale}.json`
  );
  _Context.mergeLocaleMessage(
    _Context.fallbackLocale,
    fallbackEditorTranslation
  );
}

async function _updateLanguageInBackground(
  lang,
  brand,
  appMode,
  cachedTranslation
) {
  const [message] = await Promise.all([
    _getLocaleMessage(lang, brand, appMode),
    _mergeEditorFallbackMessage(appMode)
  ]);

  let data = { [lang]: message, $groupName: `languages-${appMode}` };
  LocalStorageService.set(_getLangSettingsKey(appMode), data);

  let needUpdate = false;

  for (const [key, val] of Object.entries(message)) {
    const cachedVal = cachedTranslation[key];
    if (!cachedVal || cachedVal !== val) {
      needUpdate = true;
      break;
    }
  }

  if (needUpdate) {
    if (_Context.messages[lang]) {
      _Context.mergeLocaleMessage(lang, message);
    } else {
      _Context.setLocaleMessage(lang, message);
    }
    _Context.setLocale(lang);
  }
}

function setContext(context) {
  _Context = context;
}

async function getDefaultSettings(brand, appMode) {
  const defaultMessage = await _getLocaleMessage(defaultLocale, brand, appMode);

  return {
    strategy: 'no_prefix',
    locales,
    defaultLocale,
    vueI18n: {
      locale: defaultLocale,
      fallbackLocale: defaultLocale,
      messages: { [defaultLocale]: defaultMessage }
    }
  };
}

async function changeLanguage(lang, brand, appMode) {
  let message = LocalStorageService.get(_getLangSettingsKey(appMode));

  if (!message) {
    message = await _getLocaleMessage(lang, brand, appMode);
  }
  _Context.setLocale(lang);
  if (_Context.messages[lang]) {
    _Context.mergeLocaleMessage(lang, message);
  } else {
    _Context.setLocaleMessage(lang, message);
  }

  if (process.client) {
    _updateLanguageInBackground(lang, brand, appMode, message);
  }
}

function getNuxtI18n() {
  return _Context;
}

async function _loadLocales(url) {
  try {
    // webpack doesn't interpolate "@/" if all url string in variable
    // https://webpack.js.org/api/module-methods/#dynamic-expressions-in-import
    const module = await import(
      /* webpackChunkName: "lang-[request]" */ `./locales/${url}`
    );
    return module?.default ?? module;
  } catch (error) {
    // logger.warn(error);
    return {};
  }
}

/**
 *
 * @param {String} key from locales files
 */
function localize(label, values) {
  return _Context ? _Context.tc(label, null, values) : label;
}

/**
 *  Function that wraps localization keys for vue-i18n-extract lib
 * @param {*} val
 * @returns
 */
function $t(val) {
  return val;
}

export { $t, localize };

export default {
  localize,
  setContext,
  getDefaultSettings,
  changeLanguage,
  getNuxtI18n
};
