import Annotation from '@/services/utils/Annotation';
import BookUtils from '@shared/publication/book-utils';
import Locator from '@shared/publication/locator';
import MaterialTypes from '@/enums/MaterialTypes';
import TextUtils from '@shared/publication/dom-utils/text-utils';
import MarkerUtils from '@shared/publication/dom-utils/marker-utils.js';
import MaterialsFactory from '@/classes/factories/Materials/MaterialsFactory';

import AnnotationDomUtils from '@shared/publication/annotation/AnnotationDomUtils';
import MaterialsUtils from '@/services/utils/MaterialsUtils';
import MaterialsRequestService from '@/services/MaterialsRequestService';

import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('AnnotationsStore.js');

// old Android System Webview's don't support Array.prototype.flat()
function flatten(source) {
  if (!source.length) {
    return [];
  }
  return [].concat.apply([], source);
}

const DEFAULT_EDITING_DATA = {
  note: '',
  categoryId: '',
  category: '',
  highlightedQuote: {}
};

const initState = () => ({
  playingAnnId: '',
  annotations: {},
  activeChipsData: { activeAnnIds: [], openFromExtras: false },
  editingAnnotationData: DEFAULT_EDITING_DATA,
  editingAnnotationShowIcon: false
});

function _getPublicationAnnotationFromState(
  state,
  publicationId,
  showDeleted = false
) {
  const annotations = state.annotations[publicationId] || {};
  if (showDeleted) {
    return annotations;
  }
  const filteredAnnotations = {};
  for (const [paraId, paraAnnotations] of Object.entries(annotations)) {
    filteredAnnotations[paraId] = paraAnnotations.filter(a => !a.isDeleted);
  }
  return filteredAnnotations;
}

const storeGetters = {
  getPublicationAnnotation: state => publicationId => {
    return _getPublicationAnnotationFromState(state, publicationId);
  },
  getAllPublicationAnnotation: state => publicationId => {
    return _getPublicationAnnotationFromState(state, publicationId, true);
  },
  getFlattenedAnnListViews: (state, getters) => bookId => {
    const annotations = getters.getPublicationAnnotation(bookId);
    let allAnnotations = flatten([...Object.values(annotations)]);
    allAnnotations = allAnnotations.sort((a, b) => a.start.compareTo(b.start));
    return allAnnotations.map(annotation =>
      annotation.createAnnotationView(bookId)
    );
  },
  getAnnotationsCount: (state, getters) => bookId => {
    const annotations = getters.getPublicationAnnotation(bookId);
    const annotationsCount = Object.keys(annotations).length;
    return annotationsCount;
  },
  getPlayingAnnId: state => () => {
    return state.playingAnnId;
  },
  getAnnsByBlockId: (state, getters) => (locator, bookId) => {
    const annotations = getters.getPublicationAnnotation(bookId);
    if (Object.keys(annotations).length === 0 || !locator) {
      return [];
    }

    const paragraphId = locator.startLocator.prefixedParagraphId;
    const blockId = BookUtils.findBlockIdByParaId(
      locator.startLocator.prefixedParagraphId
    );
    const notesByBlockId = annotations[blockId] || annotations[paragraphId];

    if (!notesByBlockId || !notesByBlockId.length) {
      return [];
    }

    return notesByBlockId;
  },
  getAnnotationFullSelectedByLocator: (state, getters) => (locator, bookId) => {
    const annotations = getters.getAnnsByBlockId(locator, bookId);
    if (!annotations.length) {
      return null;
    }

    const locatorStartCharOffset = locator.startLocator.logicalCharOffset;
    const locatorEndCharOffset = locator.endLocator.logicalCharOffset;
    const annotationsFullSelectedByLocator = annotations.filter(annotation => {
      return (
        annotation.start.logicalCharOffset === locatorStartCharOffset &&
        annotation.end.logicalCharOffset === locatorEndCharOffset
      );
    });

    return annotationsFullSelectedByLocator.length === 1
      ? annotationsFullSelectedByLocator[0]
      : null;
  },
  annotationsIdsByLocator: (state, getters) => (locator, bookId) => {
    if (!locator) {
      return [];
    }
    locator = Locator.deserialize(locator);
    const annotations = getters.getAnnsByBlockId(locator, bookId);
    if (!annotations.length) {
      return [];
    }

    const annotationsIds = annotations.reduce((annIds, annotation) => {
      if (_isLocatorInsideAnnotation(locator, annotation)) {
        annIds.push(annotation.id);
      }
      return annIds;
    }, []);

    return annotationsIds;
  },
  getHighlightedQuote: (state, getter) => (ann, paraText) => {
    const { text, annClass, chunk } = getter.getHighlightedQuoteData(
      ann,
      paraText
    );
    const result = MaterialsUtils.wrapTextIntoClass(text, annClass);
    return result + chunk;
  },
  getHighlightedQuoteData: state => (materialItem, paraText, dir) => {
    const annClass = materialItem.annClass;
    const annColor = materialItem.categoryColor;
    let start = 0;
    let end = state.defaultChapterLength;
    const materialStart =
      materialItem.start || materialItem?.locator?.startLocator;
    if (materialStart) {
      start = TextUtils.recoverRealOffset(
        materialStart.logicalCharOffset,
        paraText
      );
    }
    const materialEnd = materialItem.end || materialItem?.locator?.endLocator;
    if (materialEnd) {
      end = TextUtils.recoverRealOffset(
        materialEnd.logicalCharOffset,
        paraText
      );
    }
    let text = '',
      chunk = '';

    text += paraText.slice(start, end);
    if (text.length < state.defaultChapterLength) {
      chunk = paraText.slice(
        end,
        end + (state.defaultChapterLength - text.length)
      );
    }
    return { text, annClass, annColor, chunk, dir };
  },
  getAnnotationViewsByBlockId: (state, getters) => ({
    bookId,
    blockId,
    paraId
  }) => {
    const annotations = getters.getPublicationAnnotation(bookId);
    const paraAnnotations = annotations[blockId] || annotations[paraId];
    if (!paraAnnotations || !paraAnnotations.length) {
      return [];
    }
    return paraAnnotations.map(annotation =>
      annotation.createAnnotationView(bookId)
    );
  },
  getAnnotationViewById: (state, getters) => ({ bookId, blockId, annId }) => {
    const annotations = getters.getPublicationAnnotation(bookId);
    const paraAnnotations = annotations[blockId];
    if (!paraAnnotations) {
      return {};
    }
    const ann = paraAnnotations.find(annotation => annotation.id === annId);
    return ann.createAnnotationView(bookId);
  },
  getActiveChipsData: state => {
    return state.activeChipsData;
  },
  getEditingAnnotationData: state => {
    return state.editingAnnotationData;
  },
  getEditingAnnotationShowIcon: state => {
    return state.editingAnnotationShowIcon;
  }
};

function _isLocatorInsideAnnotation(locator, annotation) {
  return locator.startLocator.compareTo(annotation.start) === 0;
}

function _buildAnnotation({
  annotation = {},
  locator,
  categoryId,
  note,
  isDeleted = false
}) {
  const annBuilder = MaterialsFactory.createBuilderByType(
    MaterialTypes.ANNOTATION
  );

  const blockId =
    annotation.blockId ||
    BookUtils.findBlockIdByParaId(locator?.startLocator?.prefixedParagraphId);

  if (!blockId) {
    throw new Error(
      `No blockId. Empty annotation blockId: ${annotation.blockId}, or not locator: ${locator}`
    );
  }

  const ann = annBuilder
    .setId(annotation.id || null)
    .setStart(locator?.startLocator || annotation.start)
    .setEnd(locator?.endLocator || annotation.end)
    .setBlockId(blockId)
    .setCreatedAt(annotation.createdAt || null)
    .setStudyGuide(annotation.studyGuide || false)
    .setCategory(categoryId || annotation.categoryId)
    .setNote(note)
    .setIsDeleted(isDeleted)
    .build();

  return ann;
}

const actions = {
  initAnnotations({ commit, dispatch }, { materials, bookId }) {
    dispatch('clearAnnotations');
    let { annotations, categories } = materials;
    annotations = annotations.filter(
      annotation => !!annotation.category || typeof annotation.tag === 'number'
    );
    const existingCategoriesMap = categories
      .filter(c => !c.isDeleted)
      .map(category => category.id);
    annotations = annotations.map(annotation => {
      if (
        !existingCategoriesMap.includes(annotation.category || annotation.tag)
      ) {
        delete annotation.category;
        delete annotation.tag;
      }
      return annotation;
    });
    const annotationsByLastModified = annotations
      .slice()
      .sort((a, b) => b.modifiedAt - a.modifiedAt);
    const categoryOrder = annotationsByLastModified.reduce(
      (order, annotation) => {
        if (!order.includes(annotation.category)) {
          order.push(annotation.category);
        }
        return order;
      },
      []
    );
    dispatch(
      'CategoriesStore/initCategories',
      { categories, bookId, categoryOrder },
      { root: true }
    );
    const annotationsByParaId = Annotation.initMaterialsByBlockId(
      annotations || []
    );
    Object.entries(annotationsByParaId).forEach(
      ([blockId, blockAnnotations]) => {
        commit('setAnnotations', { blockId, blockAnnotations, bookId });
      }
    );
  },
  clearAnnotations({ commit, dispatch }) {
    commit('clearStore');
    dispatch('CategoriesStore/clearCategory', null, { root: true });
  },
  async createAnnotationTtsLink({ dispatch, rootGetters }, { annotation }) {
    try {
      const isPollyEnabled = rootGetters['ContextStore/isPollyEnabled'];
      if (!isPollyEnabled) {
        return;
      }

      const reqParams = rootGetters['MaterialsStore/getReqParams'];
      const highlightedQuoteData = await dispatch(
        'MaterialsStore/getHighlightedQuote',
        {
          bookId: reqParams.bookId,
          materialItem: annotation
        },
        { root: true }
      );
      const quote = highlightedQuoteData.text;
      const isTtsCase = rootGetters['PlaybackStore/getIsTtsCase'](
        annotation?.paragraphId,
        quote
      );
      if (isTtsCase) {
        const lang = rootGetters['BookStore/getParaLanguage'](
          annotation.paragraphId
        );
        return dispatch('sendRequestToCreateTts', {
          text: quote,
          lang
        });
      }
    } catch (error) {
      logger.error(`Get error on create annotation tts link error: ${error}`);
    }
  },
  sendRequestToCreateTts({ rootGetters }, { text, lang }) {
    const isPollyEnabled = rootGetters['ContextStore/isPollyEnabled'];
    if (!isPollyEnabled) {
      return;
    }
    return MaterialsRequestService.sendRequestToCreateTts({
      text,
      lang
    });
  },
  injectAnnotationStyles({ rootGetters }, payload) {
    const { styleContainer } = payload;
    const categoriesClassGetter = id =>
      rootGetters['CategoriesStore/getAnnClassByCategoryId'](id);
    const categories = rootGetters['CategoriesStore/getCategories'];
    const styles = Annotation.getCategoryStyles(
      categories,
      categoriesClassGetter
    );
    AnnotationDomUtils.clearCategoryStyles({ styleContainer, NS: 'nota' });
    AnnotationDomUtils.injectCategoryStyles({
      styleContainer,
      styles,
      NS: 'nota'
    });
  },
  deleteAnnotationsByCategory({ commit, getters }, { reqParams, categoryId }) {
    const bookId = reqParams.bookId;
    const annotations = getters.getAllPublicationAnnotation(bookId);

    const flattenedAnns = MaterialsUtils.fromMapToArray(annotations);
    flattenedAnns.forEach(annotation => {
      if (annotation.category === categoryId) {
        commit('deleteAnnotationProcess', { annotation, bookId });
      }
    });
    MaterialsRequestService.processAnnotationRequest({
      annotations,
      reqParams
    });
  },
  buildAnnotation(_, { locator, categoryId, note }) {
    return _buildAnnotation({
      locator,
      categoryId,
      note
    });
  },
  async processAddAnnotation({ dispatch }, { locator, categoryId, note }) {
    const annotation = _buildAnnotation({
      locator,
      categoryId,
      note
    });

    await dispatch('addAnnotation', {
      annotation
    });
  },
  changeCategoryToDefault({ getters, rootGetters, commit }, { categoryId }) {
    const reqParams = rootGetters['MaterialsStore/getReqParams'];
    const bookId = reqParams.bookId;
    const defaultCategory = rootGetters['CategoriesStore/getDefaultCategory'];
    const annotations = getters.getAllPublicationAnnotation(bookId);
    Object.keys(annotations).forEach(paraKey => {
      annotations[paraKey].forEach((annotation, annIndex) => {
        if (annotation.categoryId !== categoryId) {
          return;
        }

        const updatedAnnotation = _buildAnnotation({
          annotation,
          categoryId: defaultCategory.id,
          isDeleted: annotation.isDeleted
        });

        commit('changeAnnotation', {
          annotation: updatedAnnotation,
          bookId,
          annIndex
        });
      });
    });

    const updatedAnnotations = getters.getAllPublicationAnnotation(bookId);

    MaterialsRequestService.processAnnotationRequest({
      annotations: updatedAnnotations,
      reqParams
    });
  },
  processChangeAnnotation(
    { dispatch },
    { annotation, locator, categoryId, note }
  ) {
    if (!annotation) {
      logger.error(`No annotation when process change annotation`);
      return;
    }

    const ann = _buildAnnotation({
      annotation,
      locator,
      categoryId,
      note
    });

    dispatch('changeAnnotation', {
      annotation: ann
    });
  },
  playTtsAnnotation({ commit }, { playingAnnId }) {
    commit('setPlayingAnnId', playingAnnId);
  },
  playAnnotation({ commit }, { playingAnnId }) {
    commit('setPlayingAnnId', playingAnnId);
  },
  stopPlayingAnnotation({ commit }) {
    commit('setPlayingAnnId', '');
  },
  processAnnotationRequest({ getters, rootGetters }, { bookId }) {
    const reqParams = rootGetters['MaterialsStore/getReqParams'];
    const annotations = getters.getAllPublicationAnnotation(bookId);

    return MaterialsRequestService.processAnnotationRequest({
      annotations,
      reqParams
    });
  },
  async addAnnotations({ commit, dispatch, rootGetters }, { annotations }) {
    const reqParams = rootGetters['MaterialsStore/getReqParams'];
    const bookId = reqParams.bookId;
    annotations.forEach(annotation => {
      commit('addAnnotation', { annotation, bookId });
      commit(
        'CategoriesStore/moveCategoryToBeginning',
        {
          bookId,
          categoryId: annotation.category
        },
        { root: true }
      );
    });

    await dispatch('processAnnotationRequest', {
      bookId
    });
  },
  async addAnnotation({ commit, dispatch, rootGetters }, { annotation }) {
    const reqParams = rootGetters['MaterialsStore/getReqParams'];
    const bookId = reqParams.bookId;
    commit('addAnnotation', { annotation, bookId });
    commit(
      'CategoriesStore/moveCategoryToBeginning',
      {
        bookId,
        categoryId: annotation.category
      },
      { root: true }
    );
    await dispatch('processAnnotationRequest', {
      bookId
    });
    await dispatch('createAnnotationTtsLink', { annotation });
  },
  changeAnnotation({ commit, dispatch, rootGetters }, payload) {
    const reqParams = rootGetters['MaterialsStore/getReqParams'];
    const bookId = reqParams.bookId;
    commit('changeAnnotation', { ...payload, bookId });
    commit(
      'CategoriesStore/moveCategoryToBeginning',
      {
        bookId,
        categoryId: payload.annotation.category
      },
      { root: true }
    );
    dispatch('processAnnotationRequest', {
      bookId
    });
  },
  deleteAnnotation({ commit, dispatch, rootGetters }, payload) {
    const reqParams = rootGetters['MaterialsStore/getReqParams'];
    const bookId = reqParams.bookId;
    commit('deleteAnnotationProcess', { ...payload, bookId });
    dispatch('processAnnotationRequest', {
      bookId,
      payload
    });
  },
  deleteAllAnnotationFromPara(
    { commit, dispatch, rootGetters },
    { paragraphId }
  ) {
    const paraNum = MarkerUtils.getParaNum(paragraphId);
    const blockId = MarkerUtils.getBlockIdByParaId(`${paraNum}`);
    if (!blockId) {
      return;
    }
    const reqParams = rootGetters['MaterialsStore/getReqParams'];
    const bookId = reqParams.bookId;
    commit('deleteAllAnnotationProcess', { blockId, bookId, paragraphId });
    dispatch('processAnnotationRequest', {
      bookId
    });
  },
  setActiveAnnIds({ commit }, chipData) {
    commit('setActiveAnnIds', chipData);
  },
  changeEditingAnnotationData({ commit }, annotationData) {
    commit('changeEditingAnnotationData', annotationData);
  },
  clearEditingAnnotationData: ({ commit, dispatch }) => {
    dispatch('changeEditingAnnotationShowIcon', false);
    commit('clearEditingAnnotationData');
  },
  changeEditingAnnotationShowIcon({ commit }, showIcon) {
    commit('changeEditingAnnotationShowIcon', showIcon);
  }
};

function _updateBlockNotes(state, bookId, blockId, blockAnnotations) {
  if (!state.annotations.hasOwnProperty(bookId)) {
    state.annotations = { ...state.annotations, [bookId]: {} };
  }
  const bookAnnotations = {
    ...state.annotations[bookId],
    [blockId]: [...blockAnnotations]
  };
  state.annotations[bookId] = {
    ...state.annotations[bookId],
    ...bookAnnotations
  };
}

const mutations = {
  selectActiveAnnotation() {}, // payload {targetParaId, annId}
  setPlayingAnnId(state, annId) {
    state.playingAnnId = annId;
  },
  setAnnotations(state, { blockId, blockAnnotations, bookId }) {
    _updateBlockNotes(state, bookId, blockId, blockAnnotations);
  },
  clearStore(state) {
    const newState = initState();
    Object.keys(newState).forEach(key => {
      const val = newState[key];
      state[key] = val;
    });
  },
  addAnnotation(state, { annotation, bookId }) {
    const blockId = annotation.blockId;
    const annotations = _getPublicationAnnotationFromState(state, bookId, true);
    if (!annotations[blockId]) {
      annotations[blockId] = [];
    }
    const blockAnnotations = annotations[blockId];
    const indexToInsert = Annotation.indexToAddAnnotation(
      annotation,
      blockAnnotations
    );
    annotations[blockId].splice(indexToInsert, 0, annotation);
    _updateBlockNotes(state, bookId, blockId, annotations[blockId]);
  },
  changeAnnotation(state, { annotation, bookId, annIndex }) {
    const blockId = annotation.blockId;
    const annotations = _getPublicationAnnotationFromState(state, bookId, true);

    const blockAnnotations = annotations[blockId];
    const index =
      annIndex || blockAnnotations.findIndex(note => note.id === annotation.id);
    annotations[blockId].splice(index, 1, annotation);
    _updateBlockNotes(state, bookId, blockId, annotations[blockId]);
  },
  deleteAllAnnotationProcess(state, { blockId, bookId }) {
    _updateBlockNotes(state, bookId, blockId, []);
  },
  deleteAnnotationProcess(state, { annotation, bookId }) {
    const blockId = annotation.blockId;
    const annotations = _getPublicationAnnotationFromState(state, bookId, true);

    const blockAnnotations = annotations[blockId];
    const indexToDelete = blockAnnotations.findIndex(
      note => note.id === annotation.id
    );
    if (indexToDelete === -1) {
      logger.warn(
        `Trying to delete not existing annotation. Id: ${annotation.id}`
      );
    }
    blockAnnotations[indexToDelete]
      .changeModificationTime(Date.now())
      .markAsDeleted();
    _updateBlockNotes(state, bookId, blockId, blockAnnotations);
  },
  setActiveAnnIds(state, chipData) {
    state.activeChipsData = chipData;
  },
  changeEditingAnnotationData(state, annotationData) {
    state.editingAnnotationData = annotationData;
  },
  clearEditingAnnotationData(state) {
    state.editingAnnotationData = DEFAULT_EDITING_DATA;
  },
  changeEditingAnnotationShowIcon(state, showIcon) {
    state.editingAnnotationShowIcon = showIcon;
  }
};

export default {
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
