'use strict';
const ParagraphLocator = require('./paragraph-locator');
/**
 * Locator for a specific block position before or after the specific paragraph of Publication
 *
 * @constructor
 * @extends {ParagraphLocator}
 * @param {string} paragraphId
 * @param {number} index
 *    Negative, if locator points above the paragraph
 *    Positive, if locator points below the paragraph
 */
class ParagraphIndexedLocator extends ParagraphLocator {
  constructor(paragraphId, index) {
    if (!(index < 0 || index > 0)) {
      throw new Error(
        'Paragraph index (' +
          JSON.stringify(index) +
          ') should be numeric, either less than or greater than zero'
      );
    }
    super(paragraphId);

    this.index = +index;
  }

  /**
   *
   * @param {ParagraphIndexedLocator} locator
   * @returns {number}
   */
  compareTo(locator) {
    return (
      this.compareBasisTo(locator) ||
      (locator.constructor === ParagraphIndexedLocator
        ? this.index - locator.index
        : this.index)
    );
  }
}
module.exports = ParagraphIndexedLocator;
