// import Vue from 'vue';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import ApplicationNames from '@/enums/ApplicationNamesEnum';
import AppStates from '@/enums/AppStateEnum';
// import BrandsEnum from '@/enums/BrandsEnum';
import FsErrorsEnum from '@/enums/FsErrorsEnum';
import AssetStrategyEnum from '@/enums/AssetStrategyEnum';
import ClientNodeContext from '@/context/ClientNodeContext';
import ManagePublicationsStates from '@/enums/ManagePublicationsStatesEnum';
import OpenAppStrategyEnum from '@/enums/OpenAppStrategyEnum';
import LoginActionsEnum from '@/enums/LoginActionsEnum';
import PublicationsTypesEnum from '@shared/enums/PublicationsTypesEnum';

import { downloadDefaultBooks } from '@/services/utils/BookDownloader';
import AgentService from '@/services/Agent/AgentService';
import AppUtils from '@/services/AppUtils';
import AssetsManager from '@/services/AssetsManager/AssetsManager';
import ContextService from '@/services/ContextService';
// do not remove, maybe return in the future
// import ElectronMenuService from '@/services/Electron/MenuService';
import FeatureDetectorService from '@/services/FeatureDetector/FeatureDetectorService';
import GoogleAnalyticsUtils from '@/services/utils/GoogleAnalyticsUtils';
import Http from '@/services/utils/Http';
import LibraryStoreService from '@/services/LibraryStoreService';
import MessageBuffer from '@/services/utils/MessageBuffer';
import OfflineModeUtils from '@/services/utils/OfflineModeUtils';
import OpenPublicationService from '@/services/OpenPublicationService';
import PublicationNavigateLogicService from '@/services/PublicationLogic/PublicationNavigateLogicService';
import ApplicationCacheService from '@/services/AssetsManager/ApplicationCacheService';
import RequestService from '@/services/RequestService';
import RestPaymentsService from '@/services/RestPaymentsService';
import ShortenerService from '@/services/ShortenerService';
import RestService from '@/services/RestService';
import MenuItemBuilderService from '@/services/MenuItemBuilderService';
import UnifiedSettingsService from '@/services/UnifiedSettingsService';
import UserService from '@/services/UserService';
import Utils from '@/services/utils/Utils';
import LinksHandlerService from '@/services/LinksHandlerService';
import QueryParamService from '@/services/QueryParamService';
import LocalStorageService from '@/services/LocalStorageService';
import PublicationLogicService from '@/services/PublicationLogic/PublicationLogicService';

// import SentryUtils from '@/services/utils/SentryUtils';

import LoggerFactory from '@/services/utils/LoggerFactory';

const logger = LoggerFactory.getLogger('AppService.js');

let clientContext;
let clientContextPromise;

async function init(store, router, i18n, vuetify) {
  if (process.client && !ClientNodeContext.iFrameDetection) {
    _appGlobalSubscribe(router);
  }
  if (process.client && !ClientNodeContext.iFrameDetection) {
    const initProcessing = LocalStorageService.get('AppServiceInitProcessing');

    if (initProcessing) {
      return;
    }
    LocalStorageService.set('AppServiceInitProcessing', true);
  }

  if (clientContextPromise && process.client) {
    LocalStorageService.set('AppServiceInitProcessing', false);
    return;
  }
  if (process.client) {
    initZoom(store);
    if (!process.env.IS_EDITOR) {
      store.dispatch('ContextStore/subscribeOffline');
    }
  }
  let initTimer;
  const MAX_INTI_TIME = 60 * 1000;
  initTimer = setTimeout(() => {
    logger.error(
      `Initialize application services take a too long time:${MAX_INTI_TIME}ms`
    );
  }, MAX_INTI_TIME);
  try {
    clientContextPromise = appInit(
      store,
      router,
      i18n,
      router.currentRoute.fullPath
    );
    clientContext = await clientContextPromise;
    // TODO [nuxt] check sentry for electron
    // const options = {
    //   isElectron: store.getters['ContextStore/isElectron'],
    //   clientConfig: clientContext.parameters,
    //   isEditor: false
    // };
    // await SentryUtils.init(Vue, options);
    if (process.client && !ClientNodeContext.iFrameDetection) {
      await LinksHandlerService.handle(store, router, router.currentRoute);
      await LinksHandlerService.processUid(store, router);
    }

    if (process.env.IS_EDITOR) {
      await initEditorPage(store, router, clientContext);
    } else if (process.env.IS_BLOG) {
      await initBlogPage(store, router, clientContext);
    } else {
      await initPage(store, router, clientContext, vuetify);
    }
    if (process.client) {
      showOneTapLoginIfNeeded(store, router);
      store.dispatch('LoginStore/tryShowSegmentationPopup');
    }
    store.commit('ContextStore/setStoresInit', process.client);

    clearTimeout(initTimer);

    if (process.client) {
      LocalStorageService.set('AppServiceInitProcessing', false);
    }
    return clientContext;
  } catch (error) {
    if (process.client) {
      LocalStorageService.set('AppServiceInitProcessing', false);
    }
    clearTimeout(initTimer);
    logger.fatal(error);
    throw error;
  }
}

async function appInit(store, router, i18n, url) {
  store.commit('ContextStore/updateClientNodeContext');
  const locale = store.getters['ContextStore/getSystemLanguage'];
  const commonInitContext = AppUtils.createCommonAppInitContext(locale);
  const {
    appName,
    runId,
    mode,
    openAppStrategyType,
    systemLanguage,
    assetStretagy
  } = Object.assign(commonInitContext, {
    appName: process.env.IS_EDITOR
      ? ApplicationNames.APP_EDITOR
      : ApplicationNames.APP_READER,
    assetStretagy: process.env.IS_EDITOR
      ? AssetStrategyEnum.REMOTE_SOURCE
      : AssetStrategyEnum.OFFLINE_SOURCE,
    mode: process.env.APP_MODE,
    openAppStrategyType: getOpenAppStrategy(url),
    systemLanguage: locale
  });
  store.commit('ContextStore/setOpenStrategy', openAppStrategyType);

  const serverUserSession = process.env.NUXT_USER_SESSION;
  if (process.server && serverUserSession) {
    store.dispatch('UserStore/fillServerUser', serverUserSession);
  } else if (process.client) {
    store.dispatch('UserStore/clearServerUser', serverUserSession);
    store.dispatch('UserStore/fillUserFromDb');
    store.commit('PaymentsStore/fillPromoCode');
  }
  logger.info(`Current url ${url}`);
  const Context = await ContextService.init(appName);

  Context.mode = mode;
  Context.openAppStrategyType = openAppStrategyType;
  Context.systemLanguage = systemLanguage;
  Context.assetStretagy = assetStretagy;
  store.dispatch('ContextStore/setContext', Context);
  await store.dispatch('ContextStore/setDefaultSystemLanguage');

  if (process.env.IS_EDITOR) {
    await initEditorService(Context, runId, store, router);
  } else if (process.env.IS_BLOG) {
    await initBlogService(Context, runId, store);
  } else {
    await initService(Context, runId, store, router);
  }
  return Context;
}

async function initBlogService(Context, runId, store) {
  if (process.client) {
    ShortenerService.init(Context);
    initGoogleAnalitics(Context);
    MessageBuffer.init(Context, runId);
  }
  const respAssetsManager = await AssetsManager.init(Context);
  Context.initErrors = [...respAssetsManager.errors];
  RestService.init(Context);

  if (process.client) {
    UnifiedSettingsService.init(Context);
  }
  Http.init({ store });

  return Context;
}

async function initService(Context, runId, store, router) {
  runId = runId || '';
  const respAssetsManager = await AssetsManager.init(Context);
  await FeatureDetectorService.init();

  if (process.client) {
    // ElectronMenuService.init(Context, store);
    ShortenerService.init(Context);
    if (!ClientNodeContext.iFrameDetection) {
      AgentService.init(Context);
      AgentService.on('init_data_filled', data =>
        fillInitPouchUserData(store, data)
      );
    }
    await ApplicationCacheService.init();
    initGoogleAnalitics(Context);
    MessageBuffer.init(Context, runId);
  }
  await initUserService(store);
  Context.initErrors = [...respAssetsManager.errors];
  RestService.init(Context);
  RestPaymentsService.init(Context);
  RequestService.init(Context);

  if (process.client) {
    UnifiedSettingsService.init(Context);
  }
  if (router) {
    OpenPublicationService.init(Context, router);
  }
  await LibraryStoreService.init(Context);
  Http.init({ store });

  return Context;
}

function getOpenAppStrategy(url) {
  const query = Utils.parseUrlQuery(
    process.client ? window.location.href : url
  );
  const isOpenedOnDeviceOs = checkIsOpenedOnDeviceOs();
  const linkFromDevice = query.isDevice === 'true';

  if (isOpenedOnDeviceOs && !ClientNodeContext.isDevice && linkFromDevice) {
    return OpenAppStrategyEnum.LOGIN_PAGE;
  }
  return (ClientNodeContext.isDevice &&
    process.env.CLIENT_CONFIG.isPurchaseAvailable) ||
    process.env.IS_EDITOR
    ? OpenAppStrategyEnum.LOGIN_PAGE
    : OpenAppStrategyEnum.LIBRARY_PAGE;
}

function checkIsOpenedOnDeviceOs() {
  return ClientNodeContext.os === 'Android' || ClientNodeContext.os === 'iOS';
}

function initGoogleAnalitics(Context) {
  if (ClientNodeContext.isDevice || ClientNodeContext.isElectron) {
    GoogleAnalyticsUtils.init(Context);
    return;
  }
}
//! AppBlog init
async function initBlogPage(store, router, Context) {
  LoggerFactory.initParams({
    version: Context.parameters.version,
    buildNumber: Context.parameters.build_number
  });
  if (process.server) {
    store.dispatch('ContextStore/loadArtifactUrlConfig');
  }

  if (process.client) {
    initPlatformClasses(store);
    addPlatformClassOnBody(store);
    addPlatformClassOnHtml(store);
  }
}
//! AppReader init
async function initPage(store, router, Context) {
  const route = router.currentRoute;
  _appSubscribe(store, router, route);

  await store.dispatch('UserStore/guestAutoLoginIfNeed');
  await actualizeCurrentUser(store);
  LoggerFactory.initParams({
    version: Context.parameters.version,
    buildNumber: Context.parameters.build_number
  });
  if (process.client) {
    MenuItemBuilderService.init({ router, store });
    OfflineModeUtils.startListen();
    OfflineModeUtils.forceUpdateOnlineStatus();
    actualizeOfflineModeData(store);

    await store.dispatch(
      'ReadingSettingsStore/setFonts',
      Context.parameters.fonts
    );
    store.dispatch('LibraryStore/initCompilationCoversList');
  } else {
    store.dispatch('ContextStore/loadArtifactUrlConfig');
  }
  await openNewBook(store, router);
  await initOpenedPublication(store, route);
  if (store.getters['ContextStore/isOnline']) {
    AssetsManager.resumeDownloadIfNeeded();
  } else {
    store.dispatch('PaymentsStore/startWatcher');
  }
  const isDevice = store.getters['ContextStore/isDevice'];
  if (isDevice) {
    initOnDeviceReady(store, router);
    _addSubscribeHeadset(store);
  }
  if (process.client) {
    initPlatformClasses(store);
    addAppleSignInScript(store);
    await addOneTapScript(store, router);
    addPlatformClassOnBody(store);
    addPlatformClassOnHtml(store);

    // ElectronMenuService.initMenu(store);
  }
  handlerInitErrors(store);
  if (process.client && isDevice) {
    navigator.splashscreen?.hide();
  }
}

async function openNewBook(store, router) {
  const slug = getSlugFromUrl(store, router);
  if (!slug) {
    return;
  }
  await initLibraryState([ManagePublicationsStates.COMPILATIONS], store);
  const pub = store.getters[
    'LibraryStore/getPublicationBySlugWithFallbackById'
  ](slug);
  if (!pub) {
    return;
  }
  if (pub.type === PublicationsTypesEnum.COLLECTION) {
    return openCollection(slug, router);
  }
  openPublication(slug, router, store);
}

async function openPublication(slug, router, store) {
  await PublicationNavigateLogicService.openPublication(store, router, {
    slug,
    staticRedirectFromAbout: true
  });
}

function openCollection(slug, router) {
  router.push({ name: AppStates.COLLECTION, params: { id: slug } });
}

function getSlugFromUrl(store, router) {
  const query = QueryParamService.getAllParams(router);
  const uri = query.uri || '';
  if (uri) {
    QueryParamService.removeParams(router, ['uri', 'q']);
  }
  return parseSlugFromUri(uri, store);
}

function parseSlugFromUri(uri, store) {
  const branchName = store.getters['ContextStore/getBranchName'];
  const uriParts = uri
    .replace(branchName, '')
    .replace(/collection/, '')
    .split('/')
    .filter(part => part);
  return uriParts[0];
}

async function initTranslatorLanguages(store) {
  try {
    await store.dispatch('WikiStore/initTranslatorLanguages');
  } catch (error) {
    logger.error(`Get error on get translatorLanguages error:${error}`);
  }
}
async function initExternalBlogSettings(store) {
  try {
    await store.dispatch('ExternalBlogStore/initBlogSettings');
  } catch (error) {
    logger.info(`Init Blog Settings failed with ${error}`);
  }
}
function handlerInitErrors(store) {
  store.getters['ContextStore/initErrors'].forEach(error => {
    if (error.code === FsErrorsEnum.QUOTA_EXCEEDED_ERR) {
      const message = 'Not enough space. Please free 150+ Mb and restart app.';
      store.dispatch('ManagePopupStore/openToaster', {
        text: message,
        duration: 5000
      });
    }
    logger.fatal(`Error while initializing App services. Error: ${error}`);
  });
}

async function handleBackButton(store, router) {
  const hasOpenedPopups =
    store.getters['ManagePopupStore/getOpenedPopups']?.length > 0 || false;
  if (hasOpenedPopups) {
    await store.dispatch('ManagePopupStore/closeLastPopup');
    return;
  }

  router.back();
}

function initOnDeviceReady(store, router) {
  document.addEventListener('deviceready', () => {
    window.open = window.cordova.InAppBrowser.open;
    document.addEventListener('pause', () => {
      store.commit('ContextStore/setBackgroundMode', true);
    });
    document.addEventListener('resume', () => {
      store.commit('ContextStore/setBackgroundMode', false);

      const isDefaultLayoutInitiated =
        store.getters['ContextStore/getIsDefaultLayoutInitiated'];
      if (!isDefaultLayoutInitiated) {
        logger.warn(
          'Resume init on device ready before default layout initiated'
        );
        window?.location.reload();
      }
    });

    document.addEventListener('backbutton', () =>
      handleBackButton(store, router)
    );

    if (
      process.client &&
      window.Keyboard &&
      typeof window.Keyboard.disableScroll === 'function'
    ) {
      window.Keyboard.disableScroll(false);
    }
  });
}

function addAppleSignInScript(store) {
  if (store.getters['ContextStore/isDevice']) {
    return;
  }
  const scriptElement = document.createElement('script');
  const randomState = Utils.randomCryptoString(30);
  const clientId = store.getters['ContextStore/getAppleWebClientId'];
  const redirectURI = store.getters['ContextStore/getClientReaderUrl'];
  store.commit('UserStore/setAppleSignInState', randomState);
  scriptElement.type = 'text/javascript';
  scriptElement.src =
    'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
  scriptElement.onload = () => {
    const options = {
      clientId,
      scope: 'name email',
      redirectURI,
      state: randomState,
      usePopup: true
    };
    try {
      window.AppleID.auth.init(options);
    } catch (error) {
      logger.error(error);
    }
  };
  document.body.appendChild(scriptElement);
}

async function addOneTapScript(store, router) {
  const isDevice = store.getters['ContextStore/isDevice'];
  const isElectron = store.getters['ContextStore/isElectron'];
  const isChrome = store.getters['ContextStore/isChrome'];
  const isIosBrowser = store.getters['ContextStore/isIosBrowser'];
  if (isDevice || isElectron || isIosBrowser || !isChrome) {
    return;
  }
  const client_id = clientContext.parameters.oneTapSignIn?.clientId || '';
  const isOneTapEnabled =
    clientContext.parameters.oneTapSignIn?.enabled || false;

  if (!isOneTapEnabled) {
    return;
  }

  const scriptElement = document.createElement('script');
  scriptElement.src = 'https://accounts.google.com/gsi/client';
  scriptElement.defer = true;
  scriptElement.async = true;

  await new Promise(resolve => {
    scriptElement.onload = resolve;
    document.head.appendChild(scriptElement);
  });

  if (!window.google) {
    logger.warn('OneTap script is not added in DOM');
    return;
  }

  window.google.accounts.id.initialize({
    client_id,
    cancel_on_tap_outside: false,
    callback: async response => {
      const oneTapCallbackResult = await $_oneTapCallback(response, store);
      await handleOneTapAuthResult(oneTapCallbackResult, store, router);
    }
  });
}

async function handleOneTapAuthResult(authResult, store) {
  const status = authResult.status;
  if (status === LoginActionsEnum.LOGGED_IN) {
    store.dispatch('LoginStore/routeAfterLogin');
    store.dispatch('LoginStore/showCongratulationPopupIfNeed', {
      confirmResult: null,
      authResult
    });
  }
}

function showOneTapLoginIfNeeded(store, router) {
  const query = QueryParamService.getAllParams(router);
  const route = router.currentRoute;
  const taskConfirmationHashCode = query.taskConfirmationHashCode;
  const confirmationCode = route.hash && route.hash.includes('access_token');
  const isLogged = store.getters['UserStore/isLoggedIn'];
  const needShowOpenOneTap =
    !isLogged && !taskConfirmationHashCode && !confirmationCode;

  const shouldShowOneTap =
    needShowOpenOneTap &&
    !ClientNodeContext.isDevice &&
    ClientNodeContext.isChrome &&
    !ClientNodeContext.iFrameDetection;

  if (!shouldShowOneTap) {
    return;
  }

  if (!window.google) {
    logger.warn('OneTap script is not presented in DOM');
    return;
  }

  window.google.accounts.id.prompt();
}

async function $_oneTapCallback(response, store) {
  const loginInfo = {
    state: 'googleOneTap',
    identityToken: response.credential,
    isWeb: true
  };
  try {
    const result = await store.dispatch('UserStore/performOauthLogin', {
      loginInfo
    });
    return result;
  } catch (e) {
    const isOnline = OfflineModeUtils.checkIsOnline();
    logger.error(
      `Could not sign in with OneTap with parameters isOnline: ${isOnline}, isChrome: ${
        ClientNodeContext.isChrome
      },
      isDevice: ${
        ClientNodeContext.isDevice
      }, userAgentParams: ${JSON.stringify(
        ClientNodeContext.userAgentParams
      )} error: ${JSON.stringify(e)}`
    );
  }
}

function _appGlobalSubscribe() {
  window.addEventListener('beforeunload', () => {
    LocalStorageService.set('AppServiceInitProcessing', false);
  });
}

function _appSubscribe(store, router, route) {
  if (process.server) {
    return;
  }

  store.subscribeAction({
    after: async ({ type, payload }) => {
      switch (type) {
        case 'CompilationsStore/create':
        case 'CompilationsStore/remove':
        case 'CompilationsStore/copy':
          _destroyCompilationState(store);
          break;
        case 'UserStore/skipLogin':
        case 'UserStore/loginByEmail':
        case 'UserStore/performOauthLogin':
        case 'UserStore/loginByHashcode':
          if (
            AppStates.ASSESSMENT !== store.getters['AppStateStore/getAppState']
          ) {
            await store.dispatch('LibraryStore/reinitState', {
              managePublicationsState: ManagePublicationsStates.LIBRARY,
              forceRemote: true
            });
          }
          await store.dispatch(
            'FavouritePublicationsStore/initFavouritePublications'
          );
          downloadDefaultBooks(store);
          await store.dispatch('UserStore/startActualizeUser');
          await initExternalBlogSettings(store);
          await _initAssessmentHistory(store);
          break;
        case 'UserStore/logout': {
          // _closePopup();
          const query = QueryParamService.getAllParams(router);
          const isLoginByHashcode = !!query.taskConfirmationHashCode;
          if (isLoginByHashcode) {
            return;
          }
          const haveUser = get(payload, 'haveUser', true);
          const hasParms = !isEmpty(query) || !isEmpty(route.params);
          const isExternalOpenApp = !haveUser && hasParms;
          if (isExternalOpenApp) {
            router.push({
              name: route.name,
              query: query,
              hash: route.hash,
              params: route.params
            });
            return;
          }
          if (get(route, 'query.showSubscriptionsPlans', false)) {
            return;
          }
          const defaultRoute = store.getters['ContextStore/getDefaultRoute'];
          const currentRouteName = store.getters['ContextStore/appState'];
          if (currentRouteName !== defaultRoute) {
            router.push({
              name: defaultRoute
            });
          }
          store.dispatch('UserStore/stopActualizeUser');
          await initExternalBlogSettings(store);
          break;
        }
        case 'LibraryStore/actualizeStores':
          downloadDefaultBooks(store);
          break;
        case 'ManagePopupStore/closePopup':
          // if (payload.name === PopupNamesEnum.PAYMENT_OFFLINE_POPUP) {
          //   _closeCurrentPopup();
          // }
          break;
      }
    }
  });

  AgentService.on('on_complete_data', () => _onSynchronizationEnd(store));
  AgentService.on('update_received', updateData =>
    _onSynchronizationEnd(store, updateData)
  );
  AgentService.on('start_sync_finished', () => {
    const updateData = {};
    const isFinished = true;
    _onSynchronizationEnd(store, updateData, isFinished);
  });
}

async function fillInitPouchUserData(store, docs = []) {
  if (!docs.length) {
    return;
  }
  const recentBooks = docs.find(doc => doc.type === 'RecentBook');
  try {
    await store.dispatch('CompilationsStore/initCompilations');
    await Promise.all([
      _fillUserSettings(store, docs),
      _fillFavouritePublications(store, docs),
      _fillUserActivity(store, docs),
      _fillRecentBookStore(store, recentBooks),
      _fillLibraryProgress(store, docs)
    ]);
  } catch (error) {
    logger.error(`Unable to fill user data from Couch: ${error.stack}`);
  }
}

async function _onSynchronizationEnd(
  store,
  updateData = {},
  isFinished = false
) {
  try {
    const docs = updateData?.docs || [];
    const recentBooks = docs.find(doc => doc.type === 'RecentBook');

    await _loadUserMaterials(store, docs);
    await _fillUserSettings(store, docs);
    await store.dispatch('CompilationsStore/initCompilations');
    await _fillFavouritePublications(store, docs);
    await _fillUserActivity(store, docs);

    if (recentBooks || isFinished) {
      await _fillRecentBookStore(store, recentBooks, isFinished);
    }
    await _fillLibraryProgress(store, docs);
    await _fillPrestPublication(store);
    await store.dispatch('ProgressStore/retrieveSuggestionsProgress');
  } catch (err) {
    logger.error(
      `Unable to reinit compilations after reinit state: ${err.stack}`
    );
  }
}

async function _fillRecentBookStore(store, recentBooks, isFinished) {
  await store.dispatch('RecentBookStore/fillRecentItemStore', {
    appMode: store.getters['ContextStore/appModeGetter'],
    recentBooks
  });

  if (!isFinished) {
    return;
  }

  const appState = store.getters['ContextStore/appState'];
  if (appState !== AppStates.PRESENT_PUBLICATION) {
    return;
  }

  const publicationId = store.getters['OpenParameterStore/getPublicationId'];
  const publicationType =
    store.getters['OpenParameterStore/getPublicationType'];
  if (publicationId && publicationType === PublicationsTypesEnum.BOOK) {
    const appMode = store.getters['ContextStore/appModeGetter'];

    return store.dispatch('RecentBookStore/addRecentItem', {
      publicationId,
      appMode
    });
  }
}

function _fillLibraryProgress(store, docs) {
  const libraryProgress = docs.find(doc => doc.type === 'libraryProgress');
  return store.dispatch('ProgressStore/fillLibraryProgress', libraryProgress);
}

function _fillUserActivity(store, docs) {
  const userActivity = docs.find(doc => doc.type === 'userActivity');
  return store.dispatch('ActivityStore/fillUserActivity', userActivity);
}

function _fillUserSettings(store, updateDataDocs) {
  const userSettingsUpdateData = (updateDataDocs || []).find(
    doc => doc.type === 'userSettings'
  );
  if (userSettingsUpdateData) {
    return store.dispatch(
      'ReadingSettingsStore/updateUserSettingsStorage',
      userSettingsUpdateData
    );
  }
}

function _loadUserMaterials(store, docs) {
  const hasNotes = docs.some(
    doc =>
      doc.type === 'usernotes' ||
      doc.type === 'tags' ||
      doc.type === 'bookmarks'
  );
  const appState = store.getters['ContextStore/appState'];
  if (hasNotes && appState === AppStates.PRESENT_PUBLICATION) {
    return PublicationLogicService.loadUserMaterials(store);
  }
}
function _fillPrestPublication(store) {
  const appState = store.getters['ContextStore/appState'];
  if (appState !== AppStates.PRESENT_PUBLICATION) {
    return;
  }
  const publicationId = store.getters['OpenParameterStore/getPublicationId'];
  const publicationType =
    store.getters['OpenParameterStore/getPublicationType'];

  if (publicationId && publicationType) {
    return store.dispatch('ProgressStore/fillPublicationProgressById', {
      publicationId,
      type: publicationType
    });
  }
}

function _fillFavouritePublications(store, docs) {
  const favourites = docs.find(doc => doc.type === 'FavouritePublications');
  return store.dispatch(
    'FavouritePublicationsStore/initFavouritePublications',
    favourites
  );
}

function _destroyCompilationState(store) {
  _destroyState([ManagePublicationsStates.COMPILATIONS], store);
}
function _destroyState(stateNames, store) {
  store.dispatch('LibraryStore/destroyStates', stateNames);
}
function _addSubscribeHeadset(store) {
  window.HeadsetDetection.registerRemoteEvents(status =>
    headsetHandler(status, store)
  );
}

function headsetHandler(status, store) {
  switch (status) {
    case 'headsetAdded':
      break;
    case 'headsetRemoved':
      store.dispatch('PlaybackStore/pause');
      break;
  }
}
async function _initAssessmentHistory(store) {
  const isLogged = store.getters['UserStore/isLoggedIn'];
  if (store.getters['ContextStore/isOnline'] && isLogged) {
    await store.dispatch('AssessmentStore/startActualizeAssessmentHistory');
  }
}
async function actualizeCurrentUser(store) {
  const isOnline = OfflineModeUtils.checkIsOnline();
  try {
    const haveUser = Boolean(store.getters['UserStore/getUserId']);

    const isOfflineUnauthorized = !isOnline && !haveUser;
    if (isOfflineUnauthorized) {
      store.dispatch('ManagePopupStore/openErrorToaster', {
        text: 'App.Login.logoutNetwork.error'
      });
      return;
    }
    if (!isOnline) {
      return;
    }

    const sessionResp = await store.dispatch('UserStore/validateUserSession');
    const valid = get(sessionResp, 'data.valid', false);
    if (!valid) {
      await store.dispatch('UserStore/logout', { haveUser });
    }
  } catch (error) {
    logger.error(`Got error on session validation: ${error}`);
    if (isOnline) {
      store.dispatch('ManagePopupStore/openErrorToaster', {
        text: 'Server unavailable'
      });
    }
  }
}

function actualizeOfflineModeData(store) {
  const isOnline = store.getters['ContextStore/isOnline'];
  if (isOnline) {
    return;
  }
  store.dispatch('LibraryStore/reinitState', {
    managePublicationsState: ManagePublicationsStates.LIBRARY,
    forceRemote: false
  });
}

function initPlatformClasses(store) {
  return store.dispatch('ContextStore/initPlatformClasses');
}

//! appCommonMixin
function addPlatformClassOnBody(store) {
  const platformBodyClassList =
    store.getters['ContextStore/getPlatformBodyClassList'];
  document.body.classList.add(...platformBodyClassList);
}

function addPlatformClassOnHtml(store) {
  const platformHtmlClassList =
    store.getters['ContextStore/getPlatformHtmlClassList'];
  const container = window.document.querySelector('html');
  container.classList.add(...platformHtmlClassList);
}

function initZoom(store) {
  return store.dispatch('ZoomStore/init');
}
function initOpenedPublication(store, route) {
  const publicationType =
    store.getters['OpenParameterStore/getPublicationType'];
  if (!_shouldInitPublication(route) || !publicationType) {
    return;
  }
  return store.dispatch('LibraryStore/initStateByPublicationTypes', {
    publicationsTypes: [publicationType],
    forceRemote: false
  });
}

function _shouldInitPublication(route) {
  return route.name === AppStates.PRESENT_PUBLICATION;
}

function initLibraryState(states = [], store, { forceRemote } = {}) {
  if (!_shouldInitLibraryState(store) || !states.length) {
    return;
  }

  const promises = states.map(state =>
    store.dispatch('LibraryStore/reinitState', {
      managePublicationsState: state,
      forceRemote: forceRemote || false
    })
  );
  return Promise.all(promises);
}
function _shouldInitLibraryState(store) {
  const appState = store.getters['ContextStore/appState'];
  return [
    AppStates.MANAGE_ABOUT,
    AppStates.MANAGE_PUBLICATION,
    AppStates.PRESENT_PUBLICATION,
    AppStates.DEVELOP_LIBRARY_SET,
    AppStates.STUDY_CLASS
  ].includes(appState);
}

//! Editor
// TODO think about class implementation
async function initEditorService(Context, runId, store, router) {
  runId = runId || '';
  await FeatureDetectorService.init();
  const respAssetsManager = await AssetsManager.init(Context);
  if (process.client) {
    UnifiedSettingsService.init(Context);
    ShortenerService.init(Context);
    MessageBuffer.init(Context, runId);
  }
  await initUserService(store);
  Context.initErrors = [...respAssetsManager.errors];
  RestService.init(Context);
  RestPaymentsService.init(Context);
  OpenPublicationService.init(Context, router);
  await LibraryStoreService.init(Context);
  return Context;
}

async function initEditorPage(store, router, Context) {
  if (process.client) {
    addAppleSignInScript(store);
    _editorAppSubscribe(store, router);
    OfflineModeUtils.startListen();
    MenuItemBuilderService.init({ router, store });
    store.dispatch('ContextStore/subscribeOffline');
    await store.dispatch(
      'ReadingSettingsStore/setFonts',
      Context.parameters.fonts
    );
    store.dispatch('LibraryStore/startActualizeStores');
    store.dispatch('UserStore/startActualizeUser');
  }
  await initLibraryState([ManagePublicationsStates.LIBRARY_EDITOR], store);
  await initOpenedPublication(store, router.currentRoute);
  store.commit('ContextStore/setServiceClientInit', process.client);
}

function _editorAppSubscribe(store, router) {
  store.subscribeAction({
    after({ type }) {
      switch (type) {
        case 'UserStore/logout':
          router.push({
            name: store.getters['ContextStore/getDefaultRoute']
          });
          break;
      }
    }
  });
}

//! Electron
bindOpeningDevtoolsForElectron();

function bindOpeningDevtoolsForElectron() {
  if (process.server || typeof window.require === 'undefined') {
    return;
  }
  const electron = window.require('electron');
  document.addEventListener(
    'keyup',
    ({ key, ctrlKey, shiftKey, metaKey, altKey }) => {
      if (
        key === 'F12' ||
        (ctrlKey && shiftKey && key === 'I') ||
        (metaKey && altKey && key === 'i')
      ) {
        electron.ipcRenderer.send('toggle-dev-tools');
      }
    }
  );
}

let initedAfterStart = false;
async function initAppReaderAfterStart(store) {
  if (initedAfterStart) {
    return;
  }
  initedAfterStart = true;
  await UserService.initAgentUser();

  const promises = [];
  if (process.client) {
    await _fillRecentBookStore(store);
    promises.push(
      initTranslatorLanguages(store),
      initExternalBlogSettings(store)
    );
  }
  await Promise.all(promises);
  if (store.getters['ContextStore/isOnline']) {
    await store.dispatch('LibraryStore/getNewBookIds');
  }

  const actualizePromises = [];
  if (process.client) {
    actualizePromises.push(
      store.dispatch('LibraryStore/startActualizeStores'),
      store.dispatch('UserStore/startActualizeUser')
    );
  }
  await Promise.all(actualizePromises);

  await _initAssessmentHistory(store);
  store.dispatch('PaymentsStore/logoutFreeUserOnDevice');
  store.commit('ContextStore/setServiceClientInit', process.client);
}

function initUserService(store) {
  const Context = store.getters['ContextStore/contextGetter'];
  const userServiceContext = {};
  userServiceContext.parameters = Context.parameters;
  userServiceContext.serverUrl = Context.serverUrl;
  return UserService.init(userServiceContext);
}

export default {
  init,
  initAppReaderAfterStart
};
