   'use strict';

   var farsiWordsUtils = require('./farsiWordsUtils.js');
   var farsiStopWords = require('./farsiStopWords');

   var exp = {}; 
   [farsiWordsUtils, farsiStopWords].forEach(function(module) {
      Object.keys(module).forEach(function(methodName) {
         exp[methodName] = module[methodName];
      });
   });

   module.exports = exp;
