import Vue from 'vue';
import MaterialTypes from '@/enums/MaterialTypes';
import RequestService from '@/services/RequestService';
import Utils from '@/services/utils/Utils';
import RestService from '@/services/RestService';
import MaterialPositions from '@/enums/MaterialPositions';
import ParagraphMaterialFactory from '@/classes/factories/Materials/ParagraphMaterialFactory';
import MaterialReqParamsFactory from '@/classes/factories/Materials/MaterialReqParamsFactory';
import MaterialsFactory from '@/classes/factories/Materials/MaterialsFactory';
import RawMaterialFactory from '@/classes/factories/Materials/RawMaterialFactory';
import MaterialsUtils from '@/services/utils/MaterialsUtils';
import MaterialsRequestService from '@/services/MaterialsRequestService';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('MaterialsStore.js');

import get from 'lodash/get';
import range from 'lodash/range';
import omit from 'lodash/omit';
import mapValues from 'lodash/mapValues';
import merge from 'lodash/merge';
import partition from 'lodash/partition';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';

class Materials {
  constructor(materials) {
    this.paraSize = materials.paraSize || '';
    this.paragraphNotes = MaterialsUtils.setMaterialsByParaId(
      materials.comments || []
    );
    this.essayTask = MaterialsUtils.setMaterialsByParaId(
      materials.essayTask || []
    );
    this.quizes = MaterialsUtils.setMaterialsByParaId(
      materials.test
        ? materials.test.filter(test => test.testType === MaterialTypes.QUIZ)
        : []
    );
    this.flashcards = MaterialsUtils.setMaterialsByParaId(
      materials.test
        ? materials.test.filter(
            test => test.testType === MaterialTypes.FLASHCARD
          )
        : []
    );
  }
}

// old Android System Webview's don't support Array.prototype.flat()
function flatten(source) {
  if (!source.length) {
    return [];
  }
  return source.reduce((acc, arr) => acc.concat(arr));
}

const initState = () => ({
  vocabularyQuestions: [],
  materials: {},
  beforeParaMaterials: {},
  afterParaMaterials: {},
  materialHeightsByType: MaterialsUtils.getHeightByTypeMap(),
  selectedLocator: {},
  materialsLoaded: false
});

const storeGetters = {
  getMaterialsLoaded: state => () => {
    return state.materialsLoaded;
  },
  beforeParaMaterialsByParaId: state => paraId => {
    return state.beforeParaMaterials[paraId];
  },
  afterParaMaterialsByParaId: state => paraId => {
    return state.afterParaMaterials[paraId];
  },
  getMaterialsByBookId: state => bookId => {
    return state.materials[bookId];
  },
  getSelectedLocator: state => state.selectedLocator,
  getVocabularyQuestions: state => state.vocabularyQuestions,
  getEssayTaskById: state => (bookId, id) => {
    const materials = state.materials[bookId];
    const flattenedEssayTasks = MaterialsUtils.fromMapToArray(
      materials.essayTask
    );
    const essayTask = flattenedEssayTasks.find(task => task.id === id);
    return essayTask
      ? ParagraphMaterialFactory.createParagraphMaterial(
          MaterialTypes.ESSAY_TASK,
          essayTask
        )
      : null;
  },
  getReqParams(state, getters, rootState, rootGetters) {
    const currentMode = rootGetters['ContextStore/appModeGetter'];
    const openParams =
      rootGetters['OpenParameterStore/getPublicationOpenParameters'];

    const reqParamsBuilder = MaterialReqParamsFactory.createBuilder();
    const reqParams = reqParamsBuilder
      .setAppMode(currentMode)
      .setBookId(openParams?.publicationId)
      .build();
    return reqParams;
  },
  getFlattenedExersisesListViews: state => (bookId, appMode) => {
    const materials = state.materials[bookId];
    if (!materials) {
      return [];
    }
    const allExercises = {
      ...materials.essayTask,
      ...materials.quizes,
      ...materials.flashcards
    };
    let exercises = flatten(Object.values(allExercises));
    return exercises.map(exercise => exercise.createExerciseListView(appMode));
  },
  getAnnotationsCount: state => bookId => {
    const materials = state.materials[bookId];
    if (!materials) {
      logger.warn(`No materials by bookId: ${bookId}`);
      return 0;
    }
    const annotationsCount = Object.keys(materials.annotations).length;
    const paraNotesCount = Object.keys(materials.paragraphNotes).length;
    return annotationsCount + paraNotesCount;
  },
  getFlattenedExercises: state => bookId => {
    const materialsByBookId = state.materials[bookId];
    const allExercises = [
      ...Object.values(materialsByBookId.quizes),
      ...Object.values(materialsByBookId.flashcards)
    ];
    return allExercises.flat();
  },
  getMaterialsByParaId: state => (bookId, paraId) => {
    const notInclude = ['annotations', 'categories', 'essays'];
    let omittedMaterials = omit(state.materials[bookId], notInclude);

    let materials = mapValues(
      omittedMaterials,
      material => material[paraId] || []
    );
    Object.keys(materials).forEach(key => {
      materials[key] = [...materials[key]].map(material =>
        MaterialsUtils.getMaterialView(material)
      );
    });

    return materials;
  },
  getParaNotesViewsByParaId: state => (bookId, paraId) => {
    let paragraphNotes = state.materials[bookId].paragraphNotes[paraId];
    if (!paragraphNotes) {
      return [];
    }
    return paragraphNotes.map(note =>
      ParagraphMaterialFactory.createParagraphMaterial(
        MaterialTypes.PARA_NOTE,
        note
      )
    );
  },
  getEssayTaskViewsByParaId: state => (bookId, paraId) => {
    let essays = state.materials[bookId].essayTask[paraId];
    if (!essays) {
      return [];
    }
    return essays.map(essay =>
      ParagraphMaterialFactory.createParagraphMaterial(
        MaterialTypes.ESSAY_TASK,
        essay
      )
    );
  },
  getTestViewByTestId: state => (bookId, testId) => {
    const materials = state.materials[bookId];
    const allTests = merge(materials.quizes, materials.flashcards);
    const test = Object.values(allTests)
      .flat()
      .find(mat => mat.id === testId);
    return MaterialsFactory.createTestEditorView(test, bookId);
  }
};

const actions = {
  async initMaterials({ commit, dispatch }, { paraNum, reqParams, appMode }) {
    const params = reqParams.getContentProviderParams();
    const bookId = reqParams.bookId;
    let res;
    try {
      res = await RequestService.request(
        'get',
        'ContentProvider',
        'init',
        params
      );
    } catch (err) {
      res = { data: null };
      logger.warn('Error in init content: ' + err);
    }
    const materials = get(res, 'data.materials', {
      annotations: [],
      categories: [],
      bookmarks: []
    });

    _updateCustomCategoriesStyles(materials.categories);

    commit('addAllMaterials', { materials, bookId, appMode });
    dispatch('changeMaterialsLoaded', false);
    dispatch(
      'BookmarkStore/initBookmarks',
      { materials, bookId },
      { root: true }
    );
    dispatch(
      'AnnotationsStore/initAnnotations',
      { materials, bookId },
      { root: true }
    );
    dispatch('changeMaterialsLoaded', true);
    dispatch('setParagraphMaterials', { paraNum, bookId });

    if (get(res, 'data.details.studyGuide', false)) {
      const studyGuide = res.data.details.studyGuide;
      commit(
        'BookStore/setMetaById',
        {
          bookId: studyGuide.id,
          meta: studyGuide
        },
        { root: true }
      );
    }
  },
  clearMaterials({ dispatch }) {
    dispatch('AnnotationsStore/clearAnnotations', null, { root: true });
  },
  setParagraphMaterials({ commit, getters }, { paraNum, bookId }) {
    const allBeforeParasMaterials = {},
      allAfterParasMaterials = {};
    range(1, paraNum + 1).forEach(num => {
      const paraPreffix = 'para_';
      const paraId = paraPreffix + num;

      const materialsByParaId = getters.getMaterialsByParaId(bookId, paraId);
      const [beforeParaMaterials, afterParaMaterials] = partition(
        materialsByParaId.paragraphNotes,
        paraNote => paraNote.position === MaterialPositions.BEFORE_PARAGRAPH
      );

      afterParaMaterials.push(...materialsByParaId.essayTask);
      afterParaMaterials.push(...materialsByParaId.flashcards);
      afterParaMaterials.push(...materialsByParaId.quizes);

      allBeforeParasMaterials[paraId] = beforeParaMaterials;
      allAfterParasMaterials[paraId] = afterParaMaterials;
    });

    commit('initBeforeParaMaterials', allBeforeParasMaterials);
    commit('initAfterParaMaterials', allAfterParasMaterials);
  },
  async initTest({ commit, getters }, { testId, bookId }) {
    const exercises = getters.getFlattenedExercises(bookId);
    const test = exercises.find(exercise => exercise.id === testId);
    if (test.isTestInfoComplete()) {
      return;
    }
    let res = await RestService.restRequest('get', 'ManageTests', 'getTest', {
      id: testId
    });
    const questions = res.data.testQuestions;
    commit('setTestQuestions', { test, questions });
  },
  async initVocabularyQuestions({ commit }) {
    try {
      let { data: questions } = await RestService.restRequest(
        'get',
        'ManageTests',
        'bncTests'
      );
      if (questions.length) {
        const serverClass = MaterialsFactory.getBNCTestQuestionServerClass();
        questions = questions.map(question => new serverClass(question));
      }
      commit('setVocabularyQuestionsList', { questions });
    } catch (err) {
      logger.error(err);
    }
  },
  async removeVocabularyQuestion({ commit }, questionId) {
    try {
      commit('removeVocabularyQuestion', questionId);
      await RestService.restRequest('delete', 'ManageTests', 'bncTest', {
        _id: questionId
      });
    } catch (err) {
      logger.error(err);
    }
  },
  async persistVocabulary({ commit }, payload) {
    const data = {
      bookId: payload.bookId,
      type: payload.vocabulary.type,
      vocabularyName: payload.vocabulary.name
    };
    const result = await RestService.restRequest(
      'get',
      'WordList',
      'createWordList',
      data
    );
    if (Array.isArray(result.data)) {
      result.data.forEach(item => {
        const rawAnnotation = RawMaterialFactory.createRawAnnotationBuilder()
          .setStartLocator(item.start)
          .setEndLocator(item.end)
          .setCategory(item.category)
          .setNote(item.note)
          .build();
        commit('addAnnotation', { ann: rawAnnotation });
      });
    }
  },
  persistParaSummary({ commit }, payload) {
    commit('persistParaSummary', payload);
  },
  addParaNote({ commit }, payload) {
    commit('addParaNote', payload);
  },
  changeParaNote({ commit }, payload) {
    commit('changeParaNote', payload);
  },
  deleteParaNote({ commit }, payload) {
    commit('deleteParaNote', payload);
  },
  deleteQuiz({ commit }, payload) {
    commit('deleteQuiz', payload);
  },
  deleteFlashcard({ commit }, payload) {
    commit('deleteFlashcard', payload);
  },
  addEssayTask({ commit }, payload) {
    commit('addEssayTask', payload);
  },
  changeEssayTask({ commit }, payload) {
    commit('changeEssayTask', payload);
  },
  deleteEssayTask({ commit }, payload) {
    commit('deleteEssayTask', payload);
  },
  changeEssayText({ commit }, payload) {
    commit('changeEssayText', payload);
  },
  completeEssay({ commit }, payload) {
    commit('changeEssayText', payload);
    commit('setEssayIsCompleted', payload);
  },
  deleteQuestion({ commit }, payload) {
    commit('deleteQuestion', payload);
  },
  async saveTest({ state, commit }, payload) {
    const { test } = payload;
    const paraId = test.locator;
    const bookId = test.bookId;
    let changedTest, existingQuestions;
    switch (payload.test.testType) {
      case MaterialTypes.QUIZ: {
        commit('changeQuiz', payload);
        changedTest = state.materials[bookId].quizes[paraId].find(
          paraTest => paraTest.id === test.id
        );
        break;
      }
      case MaterialTypes.FLASHCARD: {
        commit('changeFlashcard', payload);
        changedTest = state.materials[bookId].flashcards[paraId].find(
          paraTest => paraTest.id === test.id
        );
        break;
      }
      case MaterialTypes.BNC: {
        let {
          clientFormatQuestions: newQuestions,
          serverFormatQuestions
        } = _splitQuestionsByFormat(test.testQuestions);
        existingQuestions = serverFormatQuestions;
        newQuestions = newQuestions.map(question => {
          question = MaterialsFactory.createBNCTestQuestionServer(question);
          return question;
        });

        const testQuestions = [...existingQuestions, ...newQuestions];

        test.setQuestions(testQuestions);
        changedTest = test;
        if (!testQuestions.length) {
          return;
        }
        break;
      }
    }

    const res = await MaterialsRequestService.processTestChangeRequest({
      test: changedTest
    });
    if (payload.test.testType !== MaterialTypes.BNC) {
      return;
    }
    const newServerFormatQuestions = res.data;
    existingQuestions = [...existingQuestions, ...newServerFormatQuestions];
    commit('changeVocabularyQuestions', existingQuestions);
  },
  addQuiz({ commit }, payload) {
    payload.testId = Utils.uuid();
    commit('addQuiz', payload);
    return payload.testId;
  },
  addFlashcard({ commit }, payload) {
    payload.testId = Utils.uuid();
    commit('addFlashcard', payload);
    return payload.testId;
  },
  setSelectedLocator({ commit }, { locator }) {
    commit('setSelectedLocator', locator);
  },
  changeMaterialsLoaded({ commit }, isLoaded) {
    commit('changeMaterialsLoaded', isLoaded);
  },
  async getHighlightedQuote(
    { dispatch, rootGetters },
    { bookId, materialItem }
  ) {
    const paraId = materialItem.paragraphId || materialItem.blockId;
    await dispatch(
      'BookStore/getContentByParagraphId',
      {
        bookId,
        paraId
      },
      { root: true }
    );
    const dir = rootGetters['BookStore/getParaDirection'](paraId);
    const paraText = rootGetters['BookStore/getParaTextByParaId'](
      bookId,
      paraId
    );
    const params = await rootGetters[
      'AnnotationsStore/getHighlightedQuoteData'
    ](materialItem, paraText, dir);
    return params;
  }
};

const mutations = {
  changeMaterialsLoaded(state, isLoaded) {
    state.materialsLoaded = isLoaded;
  },
  initBeforeParaMaterials(state, before) {
    state.beforeParaMaterials = { ...before };
  },
  initAfterParaMaterials(state, after) {
    state.afterParaMaterials = { ...after };
  },
  setSelectedLocator(state, locator) {
    state.selectedLocator = locator;
  },
  addBeforeParaMaterial(state, { material, paraId }) {
    if (state.beforeParaMaterials[paraId]) {
      state.beforeParaMaterials[paraId].push(material);
    } else {
      state.beforeParaMaterials[paraId] = [material];
    }
  },
  addAfterParaMaterial(state, { material, paraId }) {
    if (state.afterParaMaterials[paraId]) {
      state.afterParaMaterials[paraId].push(material);
    } else {
      state.afterParaMaterials[paraId] = [material];
    }
  },
  deleteBeforeParaMaterialById(state, { material, paraId }) {
    const id = material.id;
    const materialIndex = state.beforeParaMaterials[paraId].findIndex(
      mat => mat.id === id
    );
    state.beforeParaMaterials[paraId].splice(materialIndex, 1);
  },
  deleteAfterParaMaterialById(state, { material, paraId }) {
    const id = material.id;
    const materialIndex = state.afterParaMaterials[paraId].findIndex(
      mat => mat.id === id
    );
    state.afterParaMaterials[paraId].splice(materialIndex, 1);
  },
  addAllMaterials(state, { materials, bookId, appMode }) {
    const _materials = new Materials(materials, appMode);
    state.materials[bookId] = _materials;
  },
  addParaNote(state, { paraNote, reqParams }) {
    const bookId = reqParams.bookId;
    const paraId = paraNote.paragraphId;

    let paraNotes = state.materials[bookId].paragraphNotes[paraId];
    if (!paraNotes) {
      state.materials[bookId].paragraphNotes[paraId] = [paraNote];
    } else {
      state.materials[bookId].paragraphNotes[paraId] = [...paraNotes, paraNote];
    }

    const paraMaterials = // rename to position
      paraNote.position === MaterialPositions.BEFORE_PARAGRAPH
        ? 'beforeParaMaterials'
        : 'afterParaMaterials';

    const newParaMaterials = { ...state[paraMaterials] };
    const paragraphNote = ParagraphMaterialFactory.createParagraphMaterial(
      MaterialTypes.PARA_NOTE,
      paraNote
    );
    newParaMaterials[paraId].push(paragraphNote);
    Vue.set(state, paraMaterials, newParaMaterials);
    MaterialsRequestService.processParaNoteRequest({
      materials: state.materials[bookId],
      reqParams
    });
  },
  changeParaNote(state, { editedParaNote, reqParams }) {
    const bookId = reqParams.bookId;
    const paraId = editedParaNote.paragraphId;
    const paraNotes = state.materials[bookId].paragraphNotes[paraId];
    const paraNoteIndex = paraNotes.findIndex(item => {
      return item.id === editedParaNote.id;
    });
    const isPositionChanged =
      paraNotes[paraNoteIndex].position !== editedParaNote.position;

    if (isPositionChanged) {
      const positionBefore = MaterialsUtils.getStateMaterialPositionByPosition(
        paraNotes[paraNoteIndex].position
      );
      const positionAfter = MaterialsUtils.getStateMaterialPositionByPosition(
        editedParaNote.position
      );

      const materialsBefore = { ...state[positionBefore] };
      const materialsAfter = { ...state[positionAfter] };

      materialsBefore[paraId] = materialsBefore[paraId].filter(
        mats => mats.id !== editedParaNote.id
      );
      Vue.set(state, positionBefore, materialsBefore);

      const paragraphNote = ParagraphMaterialFactory.createParagraphMaterial(
        MaterialTypes.PARA_NOTE,
        editedParaNote
      );
      materialsAfter[paraId].push(paragraphNote);
      Vue.set(state, positionAfter, materialsAfter);
    } else {
      const position =
        editedParaNote.position === MaterialPositions.BEFORE_PARAGRAPH
          ? 'beforeParaMaterials'
          : 'afterParaMaterials';
      const newParaMaterials = { ...state[position] };
      const noteParaMaterialsIndex = newParaMaterials[paraId].findIndex(
        mat => mat.id === editedParaNote.id
      );

      const paragraphNote = ParagraphMaterialFactory.createParagraphMaterial(
        MaterialTypes.PARA_NOTE,
        editedParaNote
      );
      newParaMaterials[paraId].splice(noteParaMaterialsIndex, 1, paragraphNote);

      Vue.set(state, position, newParaMaterials);
    }

    state.materials[bookId].paragraphNotes[paraId][
      paraNoteIndex
    ] = editedParaNote;

    MaterialsRequestService.processParaNoteRequest({
      materials: state.materials[bookId],
      reqParams
    });
  },
  deleteParaNote(state, { paraNoteToDelete, reqParams }) {
    const bookId = reqParams.bookId;
    const paraId = paraNoteToDelete.paragraphId;
    let paraNotesByParaId = state.materials[bookId].paragraphNotes[paraId];
    state.materials[bookId].paragraphNotes[paraId] = paraNotesByParaId.filter(
      note => note.id !== paraNoteToDelete.id
    );

    const paraMaterials = // rename to position
      paraNoteToDelete.position === MaterialPositions.BEFORE_PARAGRAPH
        ? 'beforeParaMaterials'
        : 'afterParaMaterials';

    const newParaMaterials = { ...state[paraMaterials] };
    const noteParaMaterialsIndex = newParaMaterials[paraId].findIndex(
      mat => mat.id === paraNoteToDelete.id
    );
    newParaMaterials[paraId].splice(noteParaMaterialsIndex, 1);
    Vue.set(state, paraMaterials, newParaMaterials);

    MaterialsRequestService.processParaNoteRequest({
      materials: state.materials[bookId],
      reqParams
    });
  },
  deleteQuiz(state, { quizToDelete }) {
    const paraId = quizToDelete.paragraphId;
    const bookId = quizToDelete.publicationId;
    let quizesByParaId = state.materials[bookId].quizes[paraId];
    state.materials[bookId].quizes[paraId] = quizesByParaId.filter(
      quiz => quiz.id !== quizToDelete.id
    );

    const newParaMaterials = { ...state.afterParaMaterials };
    const quizParaMaterialsIndex = newParaMaterials[paraId].findIndex(
      mat => mat.id === quizToDelete.id
    );
    newParaMaterials[paraId].splice(quizParaMaterialsIndex, 1);
    Vue.set(state, 'afterParaMaterials', newParaMaterials);

    RestService.restRequest('post', 'ManageTests', 'removeTests', {
      id: quizToDelete.id
    });
  },
  deleteFlashcard(state, { flashcardToDelete }) {
    const paraId = flashcardToDelete.paragraphId;
    const bookId = flashcardToDelete.publicationId;
    let flashcardsByParaId = state.materials[bookId].flashcards[paraId];
    state.materials[bookId].flashcards[paraId] = flashcardsByParaId.filter(
      flashcard => flashcard.id !== flashcardToDelete.id
    );

    const newParaMaterials = { ...state.afterParaMaterials };
    const flashcardParaMaterialsIndex = newParaMaterials[paraId].findIndex(
      mat => mat.id === flashcardToDelete.id
    );
    newParaMaterials[paraId].splice(flashcardParaMaterialsIndex, 1);
    Vue.set(state, 'afterParaMaterials', newParaMaterials);

    RestService.restRequest('post', 'ManageTests', 'removeTests', {
      id: flashcardToDelete.id
    });
  },
  addQuiz(state, { bookId, paraId, testId }) {
    const quizBuilder = MaterialsFactory.createBuilderByType(
      MaterialTypes.QUIZ
    );
    quizBuilder
      .setId(testId)
      .setPublicationId(bookId)
      .setParagraphId(paraId);
    const quiz = quizBuilder.build();
    if (state.materials[bookId].quizes[paraId]) {
      state.materials[bookId].quizes[paraId].push(quiz);
    } else {
      state.materials[bookId].quizes[paraId] = [quiz];
    }

    const newParaMaterials = { ...state.afterParaMaterials };
    const paragraphQuiz = ParagraphMaterialFactory.createParagraphMaterial(
      MaterialTypes.QUIZ,
      quiz
    );
    newParaMaterials[paraId].push(paragraphQuiz);
    Vue.set(state, 'afterParaMaterials', newParaMaterials);
  },
  addFlashcard(state, { bookId, paraId, testId }) {
    const flashcardBuilder = MaterialsFactory.createBuilderByType(
      MaterialTypes.FLASHCARD
    );
    flashcardBuilder
      .setId(testId)
      .setPublicationId(bookId)
      .setParagraphId(paraId);
    const flashcard = flashcardBuilder.build();
    if (state.materials[bookId].flashcards[paraId]) {
      state.materials[bookId].flashcards[paraId].push(flashcard);
    } else {
      state.materials[bookId].flashcards[paraId] = [flashcard];
    }

    const newParaMaterials = { ...state.afterParaMaterials };
    const paragraphFlashcard = ParagraphMaterialFactory.createParagraphMaterial(
      MaterialTypes.FLASHCARD,
      flashcard
    );
    newParaMaterials[paraId].push(paragraphFlashcard);
    Vue.set(state, 'afterParaMaterials', newParaMaterials);
  },
  //jshint ignore:line
  setTestQuestions(state, { test, questions }) {
    test.changeTestQuestions(questions);
  },
  setVocabularyQuestionsList(state, { questions }) {
    state.vocabularyQuestions = questions;
  },
  removeVocabularyQuestion(state, questionId) {
    const index = state.vocabularyQuestions.findIndex(
      question => question._id === questionId
    );
    state.vocabularyQuestions.splice(index, 1);
  },
  addEssayTask(state, { essay }) {
    const publicationId = essay.publicationId;
    const paraId = essay.paragraphId;
    let paraEssays = state.materials[publicationId].essayTask[paraId];
    if (!paraEssays) {
      state.materials[publicationId].essayTask[paraId] = [essay];
    } else {
      state.materials[publicationId].essayTask[paraId] = [...paraEssays, essay];
    }

    const newParaMaterials = { ...state.afterParaMaterials };
    const paragraphEssay = ParagraphMaterialFactory.createParagraphMaterial(
      MaterialTypes.ESSAY_TASK,
      essay
    );
    newParaMaterials[paraId].push(paragraphEssay);
    Vue.set(state, 'afterParaMaterials', newParaMaterials);

    MaterialsRequestService.processEssayTaskRequest({ essay });
  },
  changeEssayTask(state, { essay }) {
    const pubId = essay.publicationId;
    const paraId = essay.paragraphId;
    let essayToChange = state.materials[pubId].essayTask[paraId].find(
      ess => ess.id === essay.id
    );
    const changableProps = ['comment', 'topic', 'wordsLimit'];
    changableProps.forEach(key => {
      essayToChange[key] = essay[key];
    });
    const newParaMaterials = { ...state.afterParaMaterials };
    const paragraphEssay = ParagraphMaterialFactory.createParagraphMaterial(
      MaterialTypes.ESSAY_TASK,
      essay
    );

    const essayParaMaterialsIndex = newParaMaterials[paraId].findIndex(
      mat => mat.id === essay.id
    );
    newParaMaterials[paraId].splice(essayParaMaterialsIndex, 1, paragraphEssay);
    Vue.set(state, 'afterParaMaterials', newParaMaterials);

    MaterialsRequestService.processEssayTaskRequest({ essay });
  },
  deleteEssayTask(state, { essay }) {
    const bookId = essay.publicationId;
    const paraId = essay.paragraphId;
    let essays = state.materials[bookId].essayTask[paraId];
    state.materials[bookId].essayTask[paraId] = essays.filter(
      ess => ess.id !== essay.id
    );

    const newParaMaterials = { ...state.afterParaMaterials };
    const essayParaMaterialsIndex = newParaMaterials[paraId].findIndex(
      mat => mat.id === essay.id
    );
    newParaMaterials[paraId].splice(essayParaMaterialsIndex, 1);
    Vue.set(state, 'afterParaMaterials', newParaMaterials);

    RestService.restRequest('get', 'ManageEssayTask', 'removeEssayTask', {
      id: essay.id
    });
  },
  persistParaSummary(state, { paraSummary, reqParams }) {
    const bookId = reqParams.bookId;
    state.materials[bookId].paraSize = paraSummary;

    const materials = { paraSize: paraSummary };
    const query = MaterialsRequestService.queryBuilder(materials, reqParams);
    RestService.restRequest('post', 'Materials', 'update', query);
  },
  // MOVE TO USER STUDY PROGRESS STORE
  // changeEssayText(state, { essay }) {
  //   const bookId = essay.publicationId;
  //   let essayById = state.materials[bookId].essays[essay.id];
  //   if (!essayById) {
  //     logger.warn('Can not find essay by id: ' + essay.id);
  //     return;
  //   }

  //   state.materials[bookId].essays[essay.id].text = essay.text;
  // },
  // setEssayIsCompleted(state, { essay }) {
  //   const bookId = essay.publicationId;
  //   let essayById = state.materials[bookId].essays[essay.id];
  //   if (!essayById) {
  //     logger.warn('Can not find essay by id: ' + essay.id);
  //     return;
  //   }
  //   state.materials[bookId].essays[essay.id].isCompleted = true;
  // },
  async changeQuiz(state, { test }) {
    const bookId = test.bookId;
    const materials = state.materials[bookId];
    const paraId = test.locator;
    const preparedQuiz = MaterialsUtils.prepareTest(test, bookId);
    let currentTest = materials.quizes[paraId].find(
      paraTest => paraTest.id === test.id
    );

    const newParaMaterials = { ...state.afterParaMaterials };
    const paragraphQuiz = ParagraphMaterialFactory.createParagraphMaterial(
      MaterialTypes.QUIZ,
      preparedQuiz
    );

    const quizParaMaterialsIndex = newParaMaterials[paraId].findIndex(
      mat => mat.id === test.id
    );
    newParaMaterials[paraId].splice(quizParaMaterialsIndex, 1, paragraphQuiz);
    Vue.set(state, 'afterParaMaterials', newParaMaterials);

    Object.keys(preparedQuiz).forEach(key => {
      currentTest[key] = preparedQuiz[key];
    });
  },
  async changeFlashcard(state, { test }) {
    const bookId = test.bookId;
    const materials = state.materials[bookId];
    const paraId = test.locator;
    const preparedFlashcard = MaterialsUtils.prepareTest(test, bookId);
    let currentTest = materials.flashcards[paraId].find(
      paraTest => paraTest.id === test.id
    );

    const newParaMaterials = { ...state.afterParaMaterials };
    const paragraphFlashcard = ParagraphMaterialFactory.createParagraphMaterial(
      MaterialTypes.FLASHCARD,
      preparedFlashcard
    );

    const flashcardParaMaterialsIndex = newParaMaterials[paraId].findIndex(
      mat => mat.id === test.id
    );
    newParaMaterials[paraId].splice(
      flashcardParaMaterialsIndex,
      1,
      paragraphFlashcard
    );
    Vue.set(state, 'afterParaMaterials', newParaMaterials);

    Object.keys(preparedFlashcard).forEach(key => {
      currentTest[key] = preparedFlashcard[key];
    });
  },
  async changeVocabularyQuestions(state, testQuestions) {
    for (const question of testQuestions) {
      const existingQuestionIndex = state.vocabularyQuestions.findIndex(
        q => q._id === question._id
      );
      if (existingQuestionIndex === -1) {
        state.vocabularyQuestions.push(question);
        continue;
      }
      state.vocabularyQuestions.splice(existingQuestionIndex, 1, question);
    }
  }
};

function _splitQuestionsByFormat(questions) {
  const clientFormatQuestions = [];
  const serverFormatQuestions = [];

  questions.forEach(question => {
    if (question instanceof MaterialsFactory.getBNCTestQuestionServerClass()) {
      serverFormatQuestions.push(question);
      return;
    }
    clientFormatQuestions.push(question);
  });

  return { clientFormatQuestions, serverFormatQuestions };
}

//TODO: remove this method when all custom categories will have proper nightColor value
function _updateCustomCategoriesStyles(categories) {
  categories.forEach(category => {
    switch (category.name) {
      case AppConstantsUtil.DEFINITION_CATEGORY_NAME:
        category.nightColor = '#9a3741';
        break;
      case AppConstantsUtil.TRANSLATOR_CATEGORY_NAME:
        category.nightColor = '#3d4c80';
        break;
      default:
        break;
    }
  });
}

export default {
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
