'use strict';

let XRegExp = require('xregexp');
const RegEx = XRegExp.default || XRegExp;
var arabicWordsUtils = require('./arabicWordsUtils.js');
var arabicStopWords = require('./arabicStopWords.js');

var arWordre = RegEx('^[\\p{Arabic}]+$');

function isArabicWord(word) {
  return arWordre.test(word);
}

function isArabicWords(words) {
  return words.every(isArabicWord);
}
var exp = {
  isArabicWords: isArabicWords
};

[arabicWordsUtils, arabicStopWords].forEach(function(module) {
  Object.keys(module).forEach(function(methodName) {
    exp[methodName] = module[methodName];
  });
});

module.exports = exp;
