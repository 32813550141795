export default {
  EMPTY_FILE_SOURCE: 'emptyFileSource',
  CLEAR_QUEUE: 'clearQueue',
  UNSELECTABLE_ELEMENTS: 'unselectableElements',
  UNSUPPORTED_TYPE: 'unsupportedType',
  UNSUPPORTED_REST_REQUEST: 'unsupportedRestRequest',
  NO_PLUGIN: 'noPluginError',
  PARSE_ERROR: 'parseError',
  NETWORK_ERROR: 'networkError',
  ANNOTATION_RANGE_ERROR: 'annRangeError',
  EMPTY_DATA: 'emptyData',
  CANCEL_ACTION: 'cancelAction',
  MP_SCROLL_ERROR: 'managePublicationsScrollError',
  INVALID_ASSET_VERSION_FILE: 'invalidAssetVersionFile',
  CANCEL_REQUEST: 'cancelRequest',
  FS_UNAVAILABLE: 'fsUnavailable',
  READ_FILE_MAP_ERROR: 'readFileMapError',
  CANCEL_CALC_READING_AREA: 'cancelCalculateReadingAre'
};
