import UserNotesController from '@/services/Agent/dao/UserNotesController';
import CategoriesController from '@/services/Agent/dao/CategoriesController';
import BookmarksController from '@/services/Agent/controller/BookmarksController';
import converter from '@/services/Agent/converter/publication';
import PublicationPresentationFactory from '@/classes/factories/PublicationPresentationFactory';

// const NOTES_PUB_TYPE = 'notes';

/**
 * Prepare all required publication data
 * @param {Object} req params
 * @returns {Promise} publication
 */
async function init(req) {
  const classId = req.classId;
  const pubId = req.publicationId;
  // todo: change when added studyClasses with studyGuides
  const publicationObj = { result: {} };
  // const publication = await Publication.getById(publicationId)
  // const pubId = publication.pubType === NOTES_PUB_TYPE ? publicationId : publication.content.bookId;
  const tagsSelectId = classId || pubId;
  return Promise.all([
    publicationObj,
    UserNotesController.get(pubId, classId),
    CategoriesController.get(tagsSelectId),
    BookmarksController.get(pubId)
  ]).then(([publication, notesData, categoriesData, bookmarksData]) => {
    const publicationData = PublicationPresentationFactory.create();
    const pub = converter.common(publication);
    const annotations = (notesData || []).concat(pub.notes || []);
    const categories = (categoriesData || []).concat(pub.tags || []);
    const bookmarks = (bookmarksData || []).concat(pub.bookmarks || []);
    publicationData.setDetails(pub);
    const materials = {
      annotations,
      categories,
      bookmarks,
      comments: pub.comments || []
    };
    if (classId) {
      materials.paraSize = pub.paraSize || '';
      materials.essayTask = pub.essayTask || [];
      materials.test = pub.test || [];
    }
    publicationData.setMaterials(materials);
    return publicationData;
  });
}

export default {
  POST: {},
  GET: { init }
};
