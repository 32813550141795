import Utils from '@/services/utils/Utils';
import MaterialTypes from '@/enums/MaterialTypes';
import MaterialPositions from '@/enums/MaterialPositions';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('MaterialsBuilder.vue');

import ParagraphNote from '@/components/views/ParagraphNote/ParagraphNote';
import ParagraphSummary from '@/components/views/ParagraphSummary/ParagraphSummary';
import Essay from '@/components/views/Essay/Essay';
import EssayTask from '@/components/views/EssayTask/EssayTask';
import QuizTask from '@/components/views/QuizTask/QuizTask';
import FlashcardTask from '@/components/views/FlashcardTask/FlashcardTask';

class ParagraphMaterial {
  constructor(component, type, id) {
    this.component = component;
    this.type = type;
    this.id = id;
  }
}

class ParagraphNoteView extends ParagraphMaterial {
  constructor(paragraphNote) {
    super(ParagraphNote, MaterialTypes.PARA_NOTE, paragraphNote.id);
    this.createdAt = paragraphNote.createdAt;
    this.modifiedAt = paragraphNote.modifiedAt;
    this.note = paragraphNote.note;
    this.paragraphId = paragraphNote.paragraphId;
    this.position = this._getCorrectPosition(paragraphNote.position);
  }

  changeNote(note) {
    this.note = note;
  }

  changePosition(position) {
    this.position = this._getCorrectPosition(position);
  }

  _getCorrectPosition(position) {
    return position === 'A'
      ? MaterialPositions.BEFORE_PARAGRAPH
      : MaterialPositions.AFTER_PARAGRAPH;
  }
}

class EssayView extends ParagraphMaterial {
  constructor(essay, component) {
    super(component, essay.type, essay.id);
    this.createdAt = essay.createdAt;
    this.paragraphId = essay.paragraphId;
    this.publicationId = essay.publicationId;
    this.topic = essay.topic;
    this.wordsLimit = essay.wordsLimit;
    this.comment = essay.comment;
  }

  changeTopic(topic) {
    this.topic = topic;
  }

  changeWordsLimit(limit) {
    this.wordsLimit = limit;
  }

  changeComment(comment) {
    this.comment = comment;
  }
}

class EssayTaskView extends EssayView {
  constructor(essayTask) {
    super(essayTask, EssayTask);
  }
}

class EssayStudentView extends EssayView {
  constructor(essay) {
    super(essay, Essay);
    this.isCompleted = essay.isCompleted || false;
    this.completedAt = essay.completedAt;
    this.text = essay.text || '';
  }

  complete(completedAt) {
    this.isCompleted = true;
    this.completedAt = completedAt;
  }

  changeText(text) {
    this.text = text;
  }
}

class TestView extends ParagraphMaterial {
  constructor(testInfo, component) {
    super(component, testInfo.type, testInfo.id);
    this.inProgress = testInfo.testQuestionsCount === 0;
    this.name = testInfo.name;
    this.description = testInfo.description;
    this.createdAt = testInfo.createdAt;
    this.publicationId = testInfo.publicationId;
    this.testQuestionsCount = testInfo.testQuestionsCount;
    this.paragraphId = testInfo.paragraphId;
  }

  changeName(name) {
    this.name = name;
    this.inProgress = false;
  }

  setQuestionsCount(count) {
    this.testQuestionsCount = count;
    this.inProgress = false;
  }

  increaseQuestionsCount() {
    this.testQuestionsCount++;
    this.inProgress = false;
  }

  decreaseQuestionsCount() {
    this.testQuestionsCount--;
    this.inProgress = false;
  }
}

class QuizView extends TestView {
  constructor(quiz) {
    super(quiz, QuizTask);
  }
}

class FlashcardView extends TestView {
  constructor(flashcard) {
    super(flashcard, FlashcardTask);
  }
}

class ParaSummaryView extends ParagraphMaterial {
  constructor(paraSummary) {
    super(ParagraphSummary, MaterialTypes.PARA_SUMMARY, Utils.uuid());
    this.paragraphId = paraSummary.locator.paragraphId;
    this.text = paraSummary.text;
  }

  changeText(text) {
    this.text = text;
  }
}

function createParagraphMaterial(type, props) {
  switch (type) {
    case MaterialTypes.QUIZ: {
      return new QuizView(props);
    }
    case MaterialTypes.FLASHCARD: {
      return new FlashcardView(props);
    }
    case MaterialTypes.PARA_SUMMARY: {
      return new ParaSummaryView(props);
    }
    case MaterialTypes.PARA_NOTE: {
      return new ParagraphNoteView(props);
    }
    case MaterialTypes.ESSAY_TASK: {
      return new EssayTaskView(props);
    }
    case MaterialTypes.ESSAY: {
      return new EssayStudentView(props);
    }
    default: {
      logger.warn('Unsupported materials type: ' + type);
      return null;
    }
  }
}

export default {
  createParagraphMaterial,
  ParagraphNoteView,
  EssayView,
  EssayTaskView,
  EssayStudentView,
  QuizView,
  FlashcardView,
  ParaSummaryView
};
