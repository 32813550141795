import timeUtils from '@shared/utils/timeUtils';
import Locator from '@shared/publication/locator.js';

class ProgressRange {
  constructor(startLocator, endLocator, type) {
    const newStartLocator = new Locator.InTextLocator(
      startLocator.prefixedParagraphId,
      startLocator.logicalCharOffset
    );
    const newEndLocator = new Locator.InTextLocator(
      endLocator.prefixedParagraphId,
      endLocator.logicalCharOffset
    );
    this.range = new Locator.InTextRangeLocator(newStartLocator, newEndLocator);
    this.type = type;
    this.date = timeUtils.getUtcTime();
    this.wordsCount = 0;
  }
}

function createProgressRange(startLocator, endLocator, type) {
  return new ProgressRange(startLocator, endLocator, type);
}

export default {
  createProgressRange
};
