import BrandsEnum from '@/enums/BrandsEnum';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';

import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('plugins/vuetify');

const colors = {
  [BrandsEnum.FFA]: '#3c55ae',
  [BrandsEnum.OCEAN]: '#0e664a'
};
const colorsDark = {
  [BrandsEnum.FFA]: '#4180f6',
  [BrandsEnum.OCEAN]: '#168060'
};
/*
To add an icon need to find the icon vuetify name from the list which is available by link
 https://vuetifyjs.com/en/features/icon-fonts/#using-custom-icons
in place
const MY_ICONS = {
*/
const opts = brand => ({
  theme: {
    themes: {
      light: {
        primary: colors[brand], // #3c55ae
        secondary: '#b3b4b7', // #b3b4b7
        accent: '#9556cd', // #9556cd
        error: '#f22b41', //#f22b41,
        text: 'grey'
      },
      dark: {
        primary: colorsDark[brand] // #3c55ae
      }
    }
  },
  icons: {
    values: {
      checkboxOn: {
        component: BaseSpriteIcon,
        props: {
          iconName: 'mdi-checkbox-marked'
        }
      },
      checkboxOff: {
        component: BaseSpriteIcon,
        props: {
          iconName: 'mdi-checkbox-blank-outline'
        }
      },
      radioOn: {
        component: BaseSpriteIcon,
        props: {
          iconName: 'mdi-radiobox-marked'
        }
      },
      radioOff: {
        component: BaseSpriteIcon,
        props: {
          iconName: 'mdi-radiobox-blank'
        }
      },
      clockOutline: {
        component: BaseSpriteIcon,
        props: {
          iconName: 'mdi-clock-time-nine-outline'
        }
      },
      expand: {
        component: BaseSpriteIcon,
        props: {
          iconName: 'ico-arrow-chevron'
        }
      },
      dropdown: {
        component: BaseSpriteIcon,
        props: {
          iconName: 'ico-arrow-chevron'
        }
      },
      prev: {
        component: BaseSpriteIcon,
        props: {
          iconName: 'ico-arrow-chevron'
        }
      },
      next: {
        component: BaseSpriteIcon,
        props: {
          iconName: 'ico-arrow-chevron'
        }
      },
      search: {
        component: BaseSpriteIcon,
        props: {
          iconName: 'ico-search'
        }
      },
      showPasswordIcon: {
        component: BaseSpriteIcon,
        props: {
          iconName: 'ico-auth-password'
        }
      },
      hidePasswordIcon: {
        component: BaseSpriteIcon,
        props: {
          iconName: 'ico-auth-text'
        }
      },
      searchIcon: {
        component: BaseSpriteIcon,
        props: {
          iconName: 'ico-search'
        }
      },
      crossIcon: {
        component: BaseSpriteIcon,
        props: {
          iconName: 'ico-close'
        }
      },
      copyIcon: {
        component: BaseSpriteIcon,
        props: {
          iconName: 'ico-file-copy-line'
        }
      },
      insertLinkIcon: {
        component: BaseSpriteIcon,
        props: {
          iconName: 'ico-insert-link'
        }
      },
      icoDiscount: {
        component: BaseSpriteIcon,
        props: {
          iconName: 'ico-tag'
        }
      },
      icoPromoCode: {
        component: BaseSpriteIcon,
        props: {
          iconName: 'ico-tag2'
        }
      },
      icoEmail: {
        component: BaseSpriteIcon,
        props: {
          iconName: 'ico-email'
        }
      },
      lockedIcon: {
        component: BaseSpriteIcon,
        props: {
          iconName: 'ico-lock-locked'
        }
      },
      addCircleIcon: {
        component: BaseSpriteIcon,
        props: {
          iconName: 'ico-add-circle-outline'
        }
      },
      icoEnvelop: {
        component: BaseSpriteIcon,
        props: {
          iconName: 'ico-app-mail'
        }
      },
      icoEnvelopOpen: {
        component: BaseSpriteIcon,
        props: {
          iconName: 'ico-mail-open-line'
        }
      },
      icoLocked: {
        component: BaseSpriteIcon,
        props: {
          iconName: 'ico-lock-locked'
        }
      },
      icoProfile: {
        component: BaseSpriteIcon,
        props: {
          iconName: 'ico-profile'
        }
      },
      icoPlus: {
        component: BaseSpriteIcon,
        props: {
          iconName: 'ico-plus'
        }
      },
      icoCalendar: {
        component: BaseSpriteIcon,
        props: {
          iconName: 'ico-calendar'
        }
      }
    }
  },
  breakpoint: {
    thresholds: {
      xs: 480,
      sm: 768,
      md: 1280,
      lg: 1366
    },
    scrollBarWidth: 5
  }
});

export default ctx => {
  const brand = ctx.env?.CLIENT_CONFIG?.brand;
  if (!brand || !colors[brand]) {
    logger.fatal('"brand" not specified in the env config');
    if (process.server) {
      process.exit(1);
    }
    return;
  }
  return opts(brand);
};
