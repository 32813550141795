import TextCopyContextFactory from '@/classes/factories/TextCopyContextFactory';
import TextUtils from '@shared/publication/dom-utils/text-utils.js';
import MarkerUtils from '@shared/publication/dom-utils/marker-utils';
import publicationUtils from '@/services/utils/publicationUtils';
import Utils from '@/services/utils/Utils';
import CopyService from '@/services/CopyService';
import ShortenerService from '@/services/ShortenerService';
import Locator from '@shared/publication/locator';

import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('TextCopyStore.js');

const initState = () => ({
  currentTextCopyContext: null
});

const storeGetters = {
  getSelectedText: (state, getters, rootState, rootGetters) => ({
    locator,
    bookId
  }) => {
    const paraTextGetter = paraId =>
      rootGetters['BookStore/getParaTextByParaId'](bookId, paraId);
    return _prepareQuote(locator, paraTextGetter);
  },
  getSelectedTextBlock: (state, getters, rootState, rootGetters) => ({
    locator,
    bookId
  }) => {
    const paraTextGetter = paraId =>
      rootGetters['BookStore/getParaTextByParaId'](bookId, paraId);
    return _getSelectedTextStructure(locator, paraTextGetter);
  },
  getSharedLink(state) {
    return state.currentTextCopyContext.shareLink;
  },
  getCopyPresentation(state) {
    const copyPresentation = state.currentTextCopyContext.getCopyRepresentation();
    return copyPresentation.text;
  }
};

const actions = {
  async getShortenLink({ rootGetters, commit }, { locator, bookId }) {
    const selectedTextMeta = rootGetters[
      'PublicationStore/getSelectedTextMeta'
    ](locator, bookId);
    const meta = rootGetters['PublicationStore/getMeta'](bookId);
    if (!meta) {
      return;
    }
    const textCopyContextBuilder = TextCopyContextFactory.createBuilder();
    const textCopyContext = textCopyContextBuilder
      .setIsAuthorInTitle(publicationUtils.isAuthorInTitle(meta))
      .setAuthor(selectedTextMeta.publicationAuthor)
      .setParaNum(selectedTextMeta.paragraphNumber)
      .setBookName(selectedTextMeta.publicationTitle)
      .build();
    commit('setCurrentTextCopyContext', textCopyContext);

    const quote = selectedTextMeta.text;
    commit('setQuote', quote);
    let shareLink = selectedTextMeta.textLink;
    commit('setShareLink', shareLink);

    const isOffline = !rootGetters['ContextStore/isOnline'];
    const isSafari = rootGetters['ContextStore/isSafari'];
    const canCreateShortLink = !isSafari && !isOffline;
    if (canCreateShortLink) {
      shareLink = await getShareUrl(selectedTextMeta.textLink);
      commit('setShareLink', shareLink);
    }
  },
  async getShortBookLink({ rootGetters, commit }, { bookId }) {
    const clientUrl = rootGetters['ContextStore/getClientReaderUrl'];
    const userId = rootGetters['UserStore/getUserId'];
    const identifier =
      rootGetters['LibraryStore/getPublicationSlugById'](bookId) || bookId;
    const brand = rootGetters['ContextStore/brand'];
    const publicationLink = await Utils.getPublicationLink({
      clientUrl,
      identifier,
      userId,
      brand
    });
    const textCopyContextBuilder = TextCopyContextFactory.createBuilder();
    const textCopyContext = textCopyContextBuilder.build();
    commit('setCurrentTextCopyContext', textCopyContext);
    commit('setShareLink', publicationLink);

    const isOffline = !rootGetters['ContextStore/isOnline'];
    const isSafari = rootGetters['ContextStore/isSafari'];
    const canCreateShortLink = !isSafari && !isOffline;
    if (canCreateShortLink) {
      const shareLink = await getShareUrl(publicationLink);
      commit('setShareLink', shareLink);
    }
  },
  async copySelectedText({ state }, { event }) {
    try {
      await CopyService.copyToClipboardWithHtml(
        event,
        state.currentTextCopyContext?.getCopyRepresentation()
      );
    } catch (err) {
      logger.error(`Error while copying: ${err}`);
    }
  },
  async copyBlogQuote({ getters, rootGetters, dispatch }, { locator, bookId }) {
    try {
      const selectedTextBlock = getters.getSelectedTextBlock({
        locator,
        bookId
      });
      const paraIds = MarkerUtils.getParagraphIdsInRange(locator);
      const voicedParaIds = paraIds.filter(paraId => {
        const isVoicedPara = rootGetters['PublicationStore/isElementVoiced'](
          bookId,
          paraId
        );
        return isVoicedPara;
      });
      if (voicedParaIds.length) {
        await dispatch(
          'PlaybackStore/preloadAlignment',
          {
            bookId,
            paraId: voicedParaIds[0],
            preloadCount: voicedParaIds.length
          },
          { root: true }
        );
      }

      const promises = selectedTextBlock.map(async textBlock => {
        const textLocator = textBlock.locator;
        const selectionText = textBlock.text;
        const selectedTextMeta = rootGetters[
          'PublicationStore/getSelectedTextMetaBySelectionText'
        ](textLocator, bookId, selectionText);
        selectedTextMeta.textLink = await getShareUrl(
          selectedTextMeta.textLink
        );
        selectedTextMeta.text = await dispatch(
          'PublicationStore/getSelectedText',
          textLocator,
          { root: true }
        );
        const paraId = textLocator.startLocator.prefixedParagraphId;
        const isVoicedPara = rootGetters['PublicationStore/isElementVoiced'](
          bookId,
          paraId
        );

        const meta = rootGetters['PublicationStore/getMeta'](bookId);
        const dir = Utils.getDirection(meta.language);
        const alignment = rootGetters[
          'PublicationStore/getAlignmentPartFromLocator'
        ]({ locator: textLocator, bookId });

        let audioOffsets = '';
        let locators = '';
        let audioLink = '';
        if (isVoicedPara) {
          audioOffsets = alignment[0].reduce((str, audioOffset) => {
            str += `${audioOffset.join('-')},`;
            return str;
          }, '');
          locators = alignment[1].join(',');
          audioLink = rootGetters['BookStore/getAudioLink'](bookId, paraId);
        }
        const htmlPart = getBlogQuoteHtmlTag({
          selectedTextMeta,
          meta,
          dir,
          locators,
          audioOffsets,
          audioLink,
          isVoicedPara
        });
        return htmlPart;
      });

      const html = await Promise.all(promises);

      await CopyService.copyToClipboard(html.join('\n'));
    } catch (err) {
      logger.error(
        `Error while copying to blog: ${err?.message} ${err?.stack}`
      );
    }
  },
  async copyArticleContent({ rootGetters }, { event, articleMeta = {} }) {
    if (!event) {
      logger.warn(`Copy article content impossible without providing event`);
      return;
    }
    const selectedText = await CopyService.readFromClipboard(event);
    const blogUrl = rootGetters['ContextStore/getClientBlogUrl'];
    const brand = rootGetters['ContextStore/brand'];
    let articleLink = Utils.buildPublicationLink({
      brand,
      clientUrl: blogUrl,
      identifier: articleMeta.slug
    });

    const textCopyContextBuilder = TextCopyContextFactory.createBuilder();
    const textCopyContext = textCopyContextBuilder
      .setAuthor(articleMeta.author)
      .setBookName(articleMeta.title)
      .build();

    textCopyContext.setQuote(selectedText);

    const isOffline = !rootGetters['ContextStore/isOnline'];
    const isSafari = rootGetters['ContextStore/isSafari'];
    const canCreateShortLink = !isSafari && !isOffline;
    if (canCreateShortLink) {
      articleLink = await getShareUrl(articleLink);
    }

    textCopyContext.setShareLink(articleLink);
    const copyRepresentation = textCopyContext.getCopyRepresentation();
    await CopyService.copyToClipboard(copyRepresentation?.text);
  }
};

const mutations = {
  setCurrentTextCopyContext(state, currentTextCopyContext) {
    state.currentTextCopyContext = currentTextCopyContext;
  },
  setQuote(state, quote) {
    state.currentTextCopyContext.setQuote(quote);
  },
  setShareLink(state, link) {
    state.currentTextCopyContext.setShareLink(link);
  }
};

function _getSelectedTextStructure(locator, paraTextGetter) {
  const { startLocator, endLocator } = locator;
  if (startLocator.paragraphId === endLocator.paragraphId) {
    const paraText = TextUtils.extractContentByRangeLocator(locator);
    return [
      {
        text: paraText,
        locator
      }
    ];
  } else {
    const paraIdsRange = MarkerUtils.getParagraphIdsInRange(locator);
    const paragraphs = paraIdsRange.map(paraId => paraTextGetter(paraId));
    const paraCount = paragraphs.length;
    const lastParagraph = paragraphs[paraCount - 1];
    const [firstParagraph, ...middleParagraphs] = paragraphs.slice(
      0,
      paraCount - 1
    );
    const start = TextUtils.recoverRealOffset(
      startLocator.logicalCharOffset,
      firstParagraph
    );
    let end = TextUtils.recoverRealOffset(
      endLocator.logicalCharOffset,
      lastParagraph
    );
    if (!end) {
      end = TextUtils.recoverRealOffset(
        endLocator.logicalCharOffset,
        lastParagraph,
        true
      );
    }

    if (start == null || end == null) {
      throw new Error('Not recovered real offsets');
    }
    const selectedParagraphs = [];
    const middleParaIds = [...paraIdsRange];
    middleParaIds.pop();
    middleParaIds.shift();
    middleParagraphs.forEach((paraText, index) => {
      const isValidText =
        paraText && paraText.trim && paraText.trim().length !== 0;
      if (!isValidText) {
        return;
      }
      const paraId = middleParaIds[index];
      const startMiddleLocator = new Locator.InTextLocator(paraId, 0);
      const wordsStableOffsets = TextUtils.collectWordsStableOffsets(paraText);
      const logicalCharOffset =
        wordsStableOffsets[wordsStableOffsets.length - 1][1];
      const endMiddleLocator = new Locator.InTextLocator(
        paraId,
        logicalCharOffset
      );

      const middleLocator = new Locator.InTextRangeLocator(
        startMiddleLocator,
        endMiddleLocator
      );
      selectedParagraphs.push({
        text: paraText,
        locator: middleLocator
      });
    });
    const firstParaphText = firstParagraph.slice(start);
    const wordsStableOffsets = TextUtils.collectWordsStableOffsets(
      firstParagraph
    );
    const firstLogicalCharOffset =
      wordsStableOffsets[wordsStableOffsets.length - 1][1];

    const endFirstLocator = new Locator.InTextLocator(
      startLocator.paragraphId,
      firstLogicalCharOffset
    );
    const firstLocator = new Locator.InTextRangeLocator(
      startLocator,
      endFirstLocator
    );

    selectedParagraphs.unshift({
      text: firstParaphText,
      locator: firstLocator
    });

    const startLastLocator = new Locator.InTextLocator(
      endLocator.paragraphId,
      0
    );
    const lastLocator = new Locator.InTextRangeLocator(
      startLastLocator,
      endLocator
    );
    selectedParagraphs.push({
      text: lastParagraph.slice(0, end),
      locator: lastLocator
    });
    return selectedParagraphs;
  }
}

function _prepareQuote(locator, paraTextGetter) {
  const paragraphs = _getSelectedTextStructure(locator, paraTextGetter);
  const quote = paragraphs.map(item => item.text).join('\n\n');

  return quote;
}

async function getShareUrl(url) {
  const shortenQueryParamsOnly = true;
  try {
    const shortUrl = await ShortenerService.getShortUrl(
      url,
      shortenQueryParamsOnly
    );
    return shortUrl;
  } catch (err) {
    logger.error(err);
    return url;
  }
}

function getBlogQuoteHtmlTag({
  dir,
  meta,
  selectedTextMeta,
  locators,
  audioOffsets,
  audioLink,
  isVoicedPara
}) {
  const playButtonHtml = `<div class="play-button"
    ><svg
        xmlns="http://www.w3.org/2000/svg"
        class="ico-play"
        viewBox="0 0 24 24"
      ><path d="M10.405 5.35197C9.20999 4.58197 8.23999 5.11597 8.23999 6.54297V17.457C8.23999 18.884 9.21099 19.416 10.405 18.649L18.585 13.391C19.778 12.623 19.778 11.376 18.584 10.609L10.405 5.35197Z" /></svg><svg
        xmlns="http://www.w3.org/2000/svg"
        class="ico-stop"
        viewBox="0 0 24 24"
      ><path d="M5.25 4A.75.75 0 016 3.25h4a.75.75 0 01.75.75v16a.75.75 0 01-.75.75H6a.75.75 0 01-.75-.75V4zm1.5.75v14.5h2.5V4.75h-2.5zm6.5-.75a.75.75 0 01.75-.75h4a.75.75 0 01.75.75v16a.75.75 0 01-.75.75h-4a.75.75 0 01-.75-.75V4zm1.5.75v14.5h2.5V4.75h-2.5z"/></svg></div>`;
  const spacerBlock = `<div class="spacer-block"></div>`;
  const playButtonTemplate = isVoicedPara ? playButtonHtml : spacerBlock;
  return `<div
    dir="${dir}"
    class="play-quote reverse"
    data-language="${meta.language}"
    data-slug="${meta.slug}"
    data-locators="${locators}"
    data-audio-offsets="${audioOffsets}"
    data-audio="${audioLink}"
    ><div class="quote-text" data-para-number="${selectedTextMeta.paragraphNumber}">
      <div>
        ${selectedTextMeta.text}
      </div>
    </div>
    <div class="quote-meta">${playButtonTemplate}<a class="floating-link" href="${selectedTextMeta.textLink}"
    ><span><svg viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg"
      ><path d="M1.25 3A.75.75 0 012 2.25h6A4.75 4.75 0 0112.75 7v14a.75.75 0 01-1.5 0A2.25 2.25 0 009 18.75H2a.75.75 0 01-.75-.75V3zm10 15V7A3.25 3.25 0 008 3.75H2.75v13.5H9a3.75 3.75 0 012.25.75z"/><path d="M12.641 3.641A4.75 4.75 0 0116 2.25h6a.75.75 0 01.75.75v15a.75.75 0 01-.75.75h-7A2.25 2.25 0 0012.75 21a.75.75 0 01-1.5 0V7c0-1.26.5-2.468 1.391-3.359zM12.75 18a3.75 3.75 0 012.25-.75h6.25V3.75H16A3.25 3.25 0 0012.75 7v11z"/></svg>${selectedTextMeta.publicationTitle}, ${selectedTextMeta.publicationAuthor}</span><span
      >Open book <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11.003 22.108h1.994V5.718l4.66 4.66 1.414-1.415L12 1.892 4.929 8.963l1.414 1.414 4.66-4.66z"/></svg></span></a></div>
  </div>
  `;
}

export default {
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
