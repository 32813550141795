<template>
  <div :class="paraNoteClass" class="paragraph-note" @click="clickHandler">
    <BaseSpriteIcon
      custom-class="paragraph-note-icon"
      icon-name="pic-paragraph-note"
      viewBox="0 0 32 32"
    />
    <span class="paragraph-note-text">{{ materialView.note }}</span>
    <div class="paragraph-note-icons">
      <span>
        <BaseSpriteIcon icon-name="ico-edit" />
      </span>
    </div>
  </div>
</template>

<script>
import MaterialPositions from '@/enums/MaterialPositions';
import MaterialTypes from '@/enums/MaterialTypes';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';

import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('ParagraphNote.vue');

export default {
  name: 'ParagraphNote',
  components: { BaseSpriteIcon },
  props: {
    materialView: Object
  },
  data() {
    const NOTE_AFTER_PARA_CLASS = 'note-after-para';
    const NOTE_BEFORE_PARA_CLASS = 'note-before-para';
    return {
      noteAfterParaClass: NOTE_AFTER_PARA_CLASS,
      noteBeforeParaClass: NOTE_BEFORE_PARA_CLASS
    };
  },
  computed: {
    paraNoteClass() {
      switch (this.materialView.position) {
        case MaterialPositions.AFTER_PARAGRAPH: {
          return this.noteAfterParaClass;
        }
        case MaterialPositions.BEFORE_PARAGRAPH: {
          return this.noteBeforeParaClass;
        }
        default: {
          logger.warn(
            `Unsupported paragraph note position type: ${this.paragraphNote.position}`
          );
          return '';
        }
      }
    }
  },
  methods: {
    clickHandler(event) {
      this.$emit('materialEvent', {
        materialType: MaterialTypes.PARA_NOTE,
        materialData: this.materialView,
        eventData: event
      });
    }
  }
};
</script>

<style scoped lang="less">
@import 'ParagraphNote.less';
</style>
