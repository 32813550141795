<template>
  <div class="quiz">
    <div class="quiz-icon">
      <BaseSpriteIcon icon-name="pic-flashcard" view-box="0 0 32 32" />
    </div>
    <div class="quiz-text">
      <div v-if="materialView.inProgress">
        Quiz in progress...
      </div>
      <div v-if="!materialView.inProgress">
        <div class="quiz-title">
          {{ materialView.name }}
        </div>
        <div class="text-description">
          {{ materialView.description }}
        </div>
        <div class="footer-icons">
          <div class="quiz-progress-wrapp">
            {{ testProgressLabel }}
          </div>
          <div class="icons-block">
            <span @click="editFlashcardTask()">
              <BaseSpriteIcon icon-name="ico-edit" />
            </span>
            <span @click="deleteFlashcardTask()">
              <BaseSpriteIcon icon-name="ico-delete" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialTypes from '@/enums/MaterialTypes';
import MenuTasks from '@/enums/MenuTasks';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';

export default {
  name: 'FlashcardTask',
  components: { BaseSpriteIcon },
  props: {
    materialView: Object
  },
  data() {
    const testType = MaterialTypes.FLASHCARD;
    return {
      testType
    };
  },
  computed: {
    testProgressLabel() {
      return `${this.$t('ManageTest.flashcards.label') +
        this.materialView.testQuestionsCount}`;
    }
  },
  methods: {
    editFlashcardTask() {
      this.$store.dispatch('ManagePopupStore/openPopup', {
        name: PopupNamesEnum.TEST_EDITOR,
        popupContext: { id: this.materialView.id }
      });
    },
    deleteFlashcardTask() {
      this.$emit('materialEvent', {
        materialType: MaterialTypes.FLASHCARD,
        materialData: this.materialView,
        task: MenuTasks.DELETE
      });
    }
  }
};
</script>

<style scoped lang="less">
@import 'FlashcardTask.less';
</style>
