import i18n from '@/i18n';
import get from 'lodash/get';

import UnifiedSettingsService from '@/services/UnifiedSettingsService';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';

import PresentPublicationFactory from '@/classes/factories/PresentPublicationFactory';
import Locator from '@shared/publication/locator.js';

import OpenPublicationsFactory from '@/classes/factories/OpenPublicationsFactory';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('OpenParameterStore.js');

const OPEN_PARAM_CONFIG = 'OpenParameters';
const OPEN_CONFIG = 'PublicationOpenParameters';

function _setOpenParams(openParameters) {
  _setLocalConfig(OPEN_CONFIG, openParameters);
}

function _getOpenParams() {
  const localOpenParams = _getLocalConfig(OPEN_CONFIG) || {};
  const openParams = _createOpenParams(localOpenParams);
  return { openParams };
}

function _setLocalConfig(configName, config) {
  UnifiedSettingsService.setSetting(OPEN_PARAM_CONFIG, configName, config);
}

function _getLocalConfig(configName) {
  return UnifiedSettingsService.getSetting(OPEN_PARAM_CONFIG, configName);
}

function _createOpenParams(localOpenParams) {
  const openParamsBuilder = OpenPublicationsFactory.createPublicationOpenParamsBuilder();
  return openParamsBuilder
    .setSlug(localOpenParams.slug || '')
    .setPublicationId(localOpenParams.publicationId || '')
    .setPublicationType(localOpenParams.publicationType || '')
    .setMaterialId(localOpenParams.materialId || '')
    .setParaId(localOpenParams.paraId || '')
    .setHighlight(localOpenParams.highlight || null)
    .setShowMetaBlock(localOpenParams.showMetaBlock || false)
    .setOpenOffset(localOpenParams.openOffset || 0)
    .setReload(localOpenParams.reload || false)
    .setRenderedParaNum(localOpenParams.renderedParaNum)
    .setStartRenderedParaCount(localOpenParams.startRenderedParaCount)
    .setParagraphRange(localOpenParams.paraRange)
    .setPreviewMode(localOpenParams.previewMode || false)
    .setSearchNavigation(localOpenParams.searchNavigation || false)
    .build();
}

const initState = _getOpenParams;

const storeGetters = {
  getParagraphRange(state) {
    return get(state, 'openParams.paraRange', null);
  },
  getParaId: state => {
    return get(state, 'openParams.paraId', null);
  },
  getOpenOffset: state => {
    return get(state, 'openParams.openOffset', 0);
  },
  getRenderedParagraphNumber(state) {
    return get(
      state,
      'openParams.renderedParaNum',
      AppConstantsUtil.RENDERED_PARA_NUM
    );
  },
  getStartRenderCount(state) {
    return get(
      state,
      'openParams.startRenderedParaCount',
      AppConstantsUtil.START_RENDERED_PARA_COUNT
    );
  },
  getRequiredLocator: state => {
    return get(state, 'openParams.requiredLocator', null);
  },
  getPublicationOpenParameters: state => {
    return state.openParams;
  },
  getSlug: state => {
    return get(state, 'openParams.slug', null);
  },
  isSearchNavigationOpen: state => {
    return get(state, 'openParams.searchNavigation', false);
  },
  getPublicationId: state => {
    return get(state, 'openParams.publicationId', null);
  },
  getStudyGuideId: state => {
    return get(state, 'openParams.studyGuideId', null);
  },
  getPublicationType(state) {
    return get(state, 'openParams.publicationType', null);
  },
  isOpenFromLink(state) {
    return get(state, 'openParams.isOpenFromLink', false);
  },
  getTextHighlight(state) {
    return get(state, 'openParams.highlight', null);
  },
  getEditMode(state) {
    return get(state, 'openParams.editMode', false);
  },
  isPreviewMode(state) {
    return get(state, 'openParams.previewMode', false);
  },
  hasSearchNavigation(state) {
    return get(state, 'openParams.searchNavigation', null);
  },
  isSamePublication: (state, getters, rootState, rootGetters) => route => {
    const previewMode = getters.isPreviewMode;
    let params = [];
    if (previewMode) {
      const previewConfig = rootGetters['ContextStore/getPreviewConfig'];
      params = previewConfig.slug ? [previewConfig.slug] : [];
    } else {
      params = Object.values(route.params);
    }
    const slug = getters.getSlug;
    const publicationId = getters.getPublicationId;
    const isSamePublication =
      params.includes(publicationId) || params.includes(slug);
    return isSamePublication;
  }
};

const actions = {
  destroyOpenParams({ commit }) {
    const localOpenParams = {};
    const emptyParams = _createOpenParams(localOpenParams);
    commit('setOpenParameters', emptyParams);
  },
  clearHighlight({ commit }) {
    commit('clearHighlight');
  },
  transformToClientData({ getters, commit }) {
    const stateHighlight = getters.getPublicationOpenParameters.highlight;

    const highlight = PresentPublicationFactory.transformHighlightToClientClass(
      stateHighlight
    );

    commit('setOpenParameters', { highlight });
  },
  async buildOpenParameters(
    { commit, getters, rootGetters, dispatch },
    payload = {}
  ) {
    logger.info('Start build open parameters');
    const editMode = payload.editMode;
    const previewMode = payload.previewMode;

    const identifier =
      payload.slug || payload.publicationId || getters.getPublicationId;
    const publication = rootGetters[
      'LibraryStore/getPublicationBySlugWithFallbackById'
    ](identifier);

    if (!publication) {
      const text = i18n.localize('Compilation.noBookFound.message');
      if (!previewMode) {
        dispatch('ManagePopupStore/openErrorToaster', { text }, { root: true });
      }
      logger.warn(
        `Didn't find publication by identifier: ${identifier}, return empty open parameters`
      );
      return;
    }
    dispatch('PublicationStore/setStoreNameByPubType', publication.type, {
      root: true
    });
    const isCompilation = rootGetters['PublicationStore/isCompilationOpen'];
    const paraRange = payload.paraRange || getters.getParagraphRange;
    let openParaId = payload.paragraphId || paraRange?.start || '';
    let openRequiredLocator = payload.requiredLocator || '';
    if (!openParaId && !isCompilation && process.client) {
      const { openLocator, requiredLocator, readingArea } = await dispatch(
        'ProgressStore/loadPreviousReadingPosition',
        { publicationId: publication.id, publicationType: publication.type },
        { root: true }
      );
      const progressSummaryItem = await dispatch(
        'ProgressStore/fetchProgressSummaryByPublicationId',
        { publicationId: publication.id },
        { root: true }
      );
      const progressRanges = rootGetters[
        'ProgressStore/getCurrentProgressRanges'
      ]({ publicationId: publication.id, type: publication.type });
      const lastRangeElement = progressRanges[progressRanges?.length - 1];
      const lastRangeLocatorObj = lastRangeElement?.range
        ? Locator.deserialize(lastRangeElement.range)
        : null;
      const isLastRangeApproach =
        lastRangeLocatorObj?.endLocator?._paragraphNumber <=
        readingArea?.endLocator?._paragraphNumber;

      openParaId = openLocator;

      if (progressSummaryItem?.readPercent >= 98 && isLastRangeApproach) {
        openParaId = rootGetters['PublicationStore/getFirstContentParagraph'](
          publication.id
        );
      }
      openRequiredLocator = requiredLocator;
    }

    let stateHighlight = getters.getPublicationOpenParameters.highlight;
    let highlight =
      stateHighlight || PresentPublicationFactory.buildEmptyHighlight();

    if (payload.highlight) {
      highlight = PresentPublicationFactory.buildHighlightFromRawData(
        payload.highlight
      );
    }
    commit('PublicationStore/setIsViewModeToScrollConstants', previewMode, {
      root: true
    });

    const isDevice = rootGetters['ContextStore/isDevice'];
    const isDeviceBrowser = rootGetters['ContextStore/isDeviceBrowser'];
    const isDeviceContext = isDevice || isDeviceBrowser;
    const defaultRenderedParaNum = isDeviceContext
      ? AppConstantsUtil.DEVICE_RENDERED_PARA_NUM
      : AppConstantsUtil.RENDERED_PARA_NUM;

    const renderedParaNum = payload.renderedParaNum
      ? payload.renderedParaNum
      : defaultRenderedParaNum;

    const defaultStartRenderedParaCount = isDeviceContext
      ? AppConstantsUtil.DEVICE_START_RENDERED_PARA_COUNT
      : AppConstantsUtil.START_RENDERED_PARA_COUNT;
    const startRenderedParaCount = payload.startRenderedParaCount
      ? payload.startRenderedParaCount
      : defaultStartRenderedParaCount;

    const openParamsBuilder = OpenPublicationsFactory.createPublicationOpenParamsBuilder();
    openParamsBuilder
      .setSlug(publication.slug || publication.id)
      .setPublicationId(publication.id)
      .setPublicationType(publication.type)
      .setParaId(openParaId)
      .setRequiredLocator(openRequiredLocator)
      .setShowMetaBlock(payload.showMetaBlock)
      .setMaterialId(null)
      .setHighlight(highlight)
      .setOpenOffset(payload.openOffset)
      .setReload(payload.reload)
      .setSelection(payload.selection)
      .setIsOpenFromLink(payload.isOpenFromLink)
      .setParagraphRange(paraRange)
      .setRenderedParaNum(renderedParaNum)
      .setStartRenderedParaCount(startRenderedParaCount)
      .setPreviewMode(previewMode)
      .setSearchNavigation(payload.searchNavigation || false)
      .setEditMode(editMode);

    const openParams = openParamsBuilder.build();
    commit('setOpenParameters', openParams);
    logger.info(
      `current open parameters ${JSON.stringify(openParams, null, 2)}`
    );
    return openParams;
  },

  changeSlug({ commit }, slug) {
    commit('changeSlug', slug);
  },
  changeParaId({ commit }, paraId) {
    commit('changeParaId', paraId);
  },
  changeEditMode({ commit }, editMode) {
    commit('changeEditMode', editMode);
  }
};

const mutations = {
  setOpenParameters(state, openParameters) {
    _setOpenParams(openParameters);
    Object.keys(state.openParams).forEach(key => {
      state.openParams[key] = openParameters.hasOwnProperty(key)
        ? openParameters[key]
        : state.openParams[key];
    });
  },
  changeParaId(state, paraId) {
    state.openParams.paraId = paraId;
    _setOpenParams(state);
  },
  changeIsOpenFromLink(state, val) {
    state.openParams.isOpenFromLink = val;
    _setOpenParams(state);
  },
  changePublicationId(state, publicationId) {
    state.openParams.publicationId = publicationId;
    _setOpenParams(state);
  },
  changeRequiredLocator(state, requiredLocator) {
    state.openParams.requiredLocator = requiredLocator;
    _setOpenParams(state);
  },
  changeSlug(state, slug) {
    state.openParams.slug = slug;
    _setOpenParams(state);
  },
  changeShowMetaBlock(state, showMetaBlock) {
    state.openParams.showMetaBlock = showMetaBlock;
    _setOpenParams(state);
  },
  changeEditMode(state, editMode) {
    state.openParams.editMode = editMode;
    _setOpenParams(state);
  },
  clearHighlight(state) {
    if (!state?.openParams?.highlight?.clearHighlight) {
      return;
    }

    state.openParams.highlight.clearHighlight();
    _setOpenParams(state);
  }
};

export default {
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
