import Locator from '@shared/publication/locator.js';
import BookUtils from '@shared/publication/book-utils';
import LayoutUtils from '@shared/publication/dom-utils/layout-utils';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';

export default {
  methods: {
    getContextMenuPosition({
      height,
      width,
      locator,
      shouldSaveLeftPosition = false
    }) {
      let top, left;
      const endLocator = locator.endLocator;
      const end = new Locator.InTextLocator(
        endLocator.paragraphId,
        endLocator.logicalCharOffset - 1
      );
      const paraId = endLocator.prefixedParagraphId;
      const paraElement = document.getElementById(paraId);
      const endWordRect = LayoutUtils.calcLocatorRectangle(end, paraElement);

      const startWord = BookUtils.getWordDomWrapper(
        locator,
        AppConstantsUtil.ANNOTATION_WRAPPER_CLASS
      );
      const startWordRect = startWord.getBoundingClientRect();

      const isVisibleEndWord = this.$_getIsVisibleEndWord(endWordRect);
      if (!isVisibleEndWord) {
        top = this.$_getMiddleTop(height);
        left = shouldSaveLeftPosition ? null : this.$_getMiddleLeft(width);
        return { top, left };
      }

      const shouldOpenUnderSelection = this.$_getShouldOpenUnderSelection(
        endWordRect,
        height
      );
      if (shouldOpenUnderSelection) {
        top = this.$_getTopUnderSelection(endWordRect);
        left = shouldSaveLeftPosition ? null : endWordRect.x;
        return { top, left };
      }

      const shouldOpenAboveSelection = this.$_getShouldOpenAboveSelection(
        startWordRect,
        height
      );
      if (shouldOpenAboveSelection) {
        top = this.$_getTopAboveWord(startWordRect, height);
        left = shouldSaveLeftPosition ? null : startWordRect.x;
        return { top, left };
      }

      top = this.$_getTopAboveWord(endWordRect, height);
      left = shouldSaveLeftPosition ? null : startWordRect.x;

      return { top, left };
    },
    $_getIsVisibleEndWord(endWordRect) {
      const totalHeight = window.innerHeight;
      const endWordY = endWordRect.y;
      return totalHeight > endWordY && endWordY > 0;
    },
    $_getShouldOpenUnderSelection(endWordRect, height) {
      const totalHeight = window.innerHeight;
      const freeSpaceUnderSelection =
        totalHeight - endWordRect.bottom - AppConstantsUtil.TOOLBAR_HEIGHT;
      return freeSpaceUnderSelection >= height;
    },
    $_getShouldOpenAboveSelection(startWordRect, height) {
      const toolbarHeight = AppConstantsUtil.TOOLBAR_HEIGHT;
      const freeSpaceAboveSelection = startWordRect.y - toolbarHeight;
      return freeSpaceAboveSelection >= height;
    },
    $_getMiddleTop(height) {
      return (window.innerHeight - height) / 2;
    },
    $_getMiddleLeft(width) {
      return (window.innerWidth - width) / 2;
    },
    $_getTopUnderSelection(endWordRect) {
      return endWordRect.y + endWordRect.height;
    },
    $_getTopAboveWord(word, height) {
      return word.y - height - word.height;
    }
  }
};
