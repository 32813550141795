'use strict';

var get = require('lodash/get');
var Locator = require('./../locator');

var META_MARKER = 'data-meta';
var ANNOTATION_ID_MARKER = 'data-annotation-id';
var BOOKMARK_ID_MARKER = 'data-bookmark-id';
//TODO: Move selectors to the config. https://isddesign.atlassian.net/browse/FFA-3205
var DATA_WARD_INDEX = '[data-wi]';
var NOTA_WRAPPER = '.nota-wrapper';
var HEADER_SELECTORS = ['.header', '.ilm-header', '.ilm-title'];
const LOCATOR_FROM_WIDGET_PROPERTY = 'logicalCharOffset';

var MarkerUtils = {
  /**
   * @param  {InTextRangeLocator} locator
   * @return {Element} wordDomWrapper - Converted DOM element of locator.
   * This method for frontend only!
   */
  getWordDomWrapper: function(locator) {
    //TODO: refactor. https://isddesign.atlassian.net/browse/FFA-3205
    var wordDomWrapper;
    var substrLength = 0;
    var paraElement = {};
    var wordsElements = [];

    if (locator instanceof Locator.PublicationStartLocator) {
      return null;
    }
    paraElement = window.document.querySelector(
      '#' + locator.endLocator.prefixedParagraphId
    );
    if (!paraElement) {
      return null;
    }
    wordsElements = paraElement.querySelectorAll(DATA_WARD_INDEX);
    if (!wordsElements) {
      return null;
    }
    for (var i = 0; i < wordsElements.length; i += 1) {
      if (substrLength === locator.startLocator.logicalCharOffset) {
        wordDomWrapper = wordsElements[i];
        break;
      }
      substrLength += wordsElements[i].textContent.length;
    }
    if (
      !wordDomWrapper &&
      substrLength === locator.startLocator.logicalCharOffset
    ) {
      wordDomWrapper = wordsElements[wordsElements.length - 1];
    }
    return wordDomWrapper;
  },

  getParaNumFromPara(paraElement) {
    const defVal = '';
    if (!paraElement) {
      return defVal;
    }
    const paraNum = paraElement.querySelector('.block-num');
    if (!paraNum) {
      return defVal;
    }
    return paraNum.getAttribute('data-id') || defVal;
  },

  getParaByRangeLocator(locator) {
    if (!locator || !locator.endLocator) {
      return null;
    }
    return window.document.querySelector(
      '#' + locator.endLocator.prefixedParagraphId
    );
  },

  prefixedParaIdToLocator(paraId, logicalOffset = 1) {
    const isPrefixedParaId = paraId.indexOf('para_') !== -1;
    if (isPrefixedParaId) {
      paraId = Locator.deserialize(
        this.getParaNum(paraId) + `.0.${logicalOffset}`
      ).toJSON();
    }
    return paraId;
  },

  locatorToParaId(serializedLocator) {
    const isLocator = serializedLocator.indexOf('para_') === -1;
    if (isLocator) {
      const locator = Locator.deserialize(serializedLocator);
      return get(locator, 'startLocator.prefixedParagraphId', '');
    }
    return serializedLocator;
  },

  getParaNum(paraId) {
    return parseInt(paraId.replace('para_', ''));
  },

  getLocatorByWordElement: function(wordElement) {
    const paraElement = wordElement.closest('[data-ww]');
    const paraId = paraElement.id.match(/\d+/)[0];
    let logicalCharOffset = 0;
    let wordLen = 0;

    Array.from(paraElement.querySelectorAll('[data-wi]')).every(function(node) {
      if (node === wordElement) {
        return false;
      }
      logicalCharOffset += node.textContent.length;
      return true;
    });

    wordLen = logicalCharOffset + wordElement.textContent.length;
    return `${paraId}.${logicalCharOffset}.${wordLen}`;
  },

  getCoordinatesFromEvent: function(event) {
    const preparedEvent = _prepareEvent(event);
    return {
      clientX: preparedEvent.clientX,
      clientY: preparedEvent.clientY
    };
  },

  getNotaWrapperRect: function() {
    return window.document.querySelector(NOTA_WRAPPER).getBoundingClientRect();
  },

  /**
   * @param  {InTextRangeLocator} locator
   * @return {Element} paraDomWrapper - Converted DOM element of locator.
   * This method for frontend only!
   */
  getParaDomWrapper: function(locator) {
    var paraId = MarkerUtils.getPrefixedParagraphId(locator);
    if (paraId === null) {
      return null;
    }
    return window.document.querySelector('#' + paraId);
  },

  /**
   * @returns {String}
   */
  getHeaderSelectors: function() {
    return HEADER_SELECTORS;
  },

  getAnnotationIdMarker: function() {
    return ANNOTATION_ID_MARKER;
  },

  getBookmarkIdMarker: function() {
    return BOOKMARK_ID_MARKER;
  },

  /**
   * get the top position for element
   * @param  {Element} currDomWrapper
   */
  getElTopPosition: function(currDomWrapper) {
    return (
      currDomWrapper.getBoundingClientRect().top - this.getNotaWrapperRect().top
    );
  },

  /**
   * get paragraphId from InTextLocator
   * @param  {InTextLocator} locator
   * @return {string} paragraphId (example: 'para_1')
   */
  getPrefixedParagraphId: function(locator) {
    return get(locator, 'startLocator.prefixedParagraphId', null);
  },

  /**
   *
   * @param {Element} paragraphContainer
   * @returns {Array.<Element>}
   */
  getParagraphElements: function getParagraphElements(paragraphContainer) {
    var paragraphsNodeList = paragraphContainer.querySelectorAll(
      MarkerUtils.getContentElementSelector()
    );
    return Array.prototype.slice.call(paragraphsNodeList);
  },

  /**
   *
   * @param {Element} paragraph
   * @returns {?Element}
   */
  getNextParagraph: function getNextParagraph(paragraph) {
    return _getParagraphByDirection(paragraph, false);
  },

  /**
   *
   * @param {Element} paragraph
   * @returns {?Element}
   */
  getPreviousParagraph: function getPreviousParagraph(paragraph) {
    return _getParagraphByDirection(paragraph, true);
  },

  /**
   *
   * @param {Element} paragraphContainer
   * @returns {Element}
   */
  getFirstLoadedParagraph: function getFirstLoadedParagraph(
    paragraphContainer
  ) {
    return paragraphContainer.querySelector(
      MarkerUtils.getContentElementSelector()
    );
  },

  getLastLoadedParagraph: function getLastLoadedParagraph(paragraphContainer) {
    var paragraphElements = paragraphContainer.querySelectorAll(
      MarkerUtils.getContentElementSelector()
    );
    return paragraphElements[paragraphElements.length - 1];
  },

  /**
   *
   * @param {Element} startParagraph
   * @param {Element} endParagraph
   */
  getParagraphElementsInRange: function getParagraphElementsInRange(
    startParagraph,
    endParagraph
  ) {
    var paragraphElements = [];
    var currentElement = startParagraph;
    // assertion block
    while (currentElement !== endParagraph) {
      if (this.isContent(currentElement)) {
        paragraphElements.push(currentElement);
      }
      currentElement = _getParagraphByDirection(currentElement, false);
      if (currentElement === null) {
        throw new Error(
          'Failed to collect paragraphs in range:' +
            ' startParagraph [' +
            startParagraph.id +
            '],' +
            ' endParagraph [' +
            endParagraph.id +
            ']'
        );
      }
    }
    paragraphElements.push(endParagraph);
    return paragraphElements;
  },

  /**
   * @param  {InTextLocator} locator
   */
  getParagraphIdsInRange: function getParagraphIdsInRange(locator) {
    const paragraphIds = [];
    let currentParagraphId = +locator.startLocator.paragraphId;
    const lastParagraphId = +locator.endLocator.paragraphId;
    while (currentParagraphId <= lastParagraphId) {
      paragraphIds.push('para_' + currentParagraphId);
      currentParagraphId = +currentParagraphId + 1;
    }
    return paragraphIds;
  },

  /**
   *
   * @param {Element} element
   * @returns {Array.<Element>}
   */
  getMetaElements: function getMetaElements(element) {
    var metaElementsNodeList = element.querySelectorAll(
      MarkerUtils.getMetaElementSelector()
    );
    return Array.prototype.slice.call(metaElementsNodeList);
  },

  /**
   *
   * @param {Element} element
   * @returns {boolean}
   */
  isContent: function isContent(element) {
    return _is(element, MarkerUtils.getContentElementSelector());
  },

  /**
   *
   * @param {Element} element
   * @returns {boolean}
   */
  isHeader: function(element) {
    return MarkerUtils.getHeaderSelectors().some(header =>
      _is(element, header)
    );
  },

  /**
   *
   * @param {Element} element
   * @returns {boolean}
   */
  isFirstParagraph: function isFirstParagraph(element) {
    return _is(element, MarkerUtils.getFirstParagraphSelector());
  },

  /**
   *
   * @param {Element} element
   */
  isLastParagraph: function isFirstParagraph(element) {
    return _is(element, MarkerUtils.getLastParagraphSelector());
  },

  /**
   *
   * @param {Element} element
   */
  isDisabledParagraph: function isDisabledParagraph(element) {
    return element.closest('div[disabled]');
  },

  /**
   *
   * @param {Element} element
   * @returns {boolean}
   */
  isMeta: function isMeta(element) {
    return _is(element, MarkerUtils.getMetaElementSelector());
  },

  /**
   *
   * @param {Element} element
   * @returns {boolean}
   */
  isParentMeta: function isParentMeta(element) {
    return !!element.closest(MarkerUtils.getMetaElementSelector());
  },

  /**
   *
   * @returns {string}
   */
  getContentElementSelector: function getContentElementSelector() {
    return '[data-before]';
  },

  /**
   * @returns {string}
   */
  getFirstParagraphSelector: function getFirstParagraphSelector() {
    return '.paragraph-first';
  },

  /**
   * @returns {string}
   */
  getLastParagraphSelector: function getLastParagraphSelector() {
    return '.paragraph-last';
  },

  /**
   *
   * @returns {string}
   */
  getPublicationHeaderSelector: function getPublicationHeaderSelector() {
    return '.book-info-box';
  },

  /**
   *
   * @param {Element} [publicationContainer]
   * @returns {Element}
   */
  getPublicationHeaderElement: function getPublicationHeaderElement(
    publicationContainer
  ) {
    publicationContainer =
      publicationContainer || window.document.documentElement;
    var publicationHeaderElement = publicationContainer.querySelector(
      this.getPublicationHeaderSelector()
    );
    return publicationHeaderElement;
  },

  /**
   *
   * @returns {string}
   */
  getMetaElementSelector: function getMetaElementSelector() {
    return '[' + META_MARKER + ']';
  },

  /**
   *
   * @returns {string}
   */
  getMetaMarker: function getMetaMarker() {
    return META_MARKER;
  },

  /**
   *
   * @param {Element} paragraphElement
   * @returns {string}
   */
  getParagraphId: function getParagraphId(paragraphElement) {
    return paragraphElement.id.slice('para_'.length);
  },

  /**
   *
   * @param {string} id
   * @param {Element} [paragraphContainer]
   * @returns {?Element}
   */
  getParagraphById: function getParagraphById(id, paragraphContainer) {
    if (paragraphContainer === undefined) {
      paragraphContainer = window.document.documentElement;
    }
    if (id.indexOf('para_') === 0) {
      throw new Error('Attempt to use prefixed id ' + id);
    }
    return paragraphContainer.querySelector('#para_' + id);
  },

  /**
   *
   * @param {ParagraphLocator|PublicationStartLocator} locator
   * @param {Element} [elementContainer]
   * @returns {?Element}
   */
  getElementByLocator: function getElementByLocator(locator, elementContainer) {
    if (locator instanceof Locator.ParagraphLocator) {
      return this.getParagraphById(locator.paragraphId, elementContainer);
    } else if (locator instanceof Locator.PublicationStartLocator) {
      return this.getPublicationHeaderElement();
    } else if (locator.hasOwnProperty(LOCATOR_FROM_WIDGET_PROPERTY)) {
      return null;
    }
    throw new Error(
      'Cannot fetch element by non-paragraph locator: [' +
        locator.toJSON() +
        ']'
    );
  },

  isElementInEventPath(identificator, event) {
    const elements =
      event.path || document.elementsFromPoint(event.pageX, event.pageY);
    return !!elements.find(
      el =>
        el.id === identificator ||
        (el.classList && el.classList.contains(identificator))
    );
  },

  checkClassByCoordinate(className, x, y) {
    const elements = document.elementsFromPoint(x, y);
    return Boolean(
      elements.find(el => el.classList && el.classList.contains(className))
    );
  },

  /**
   *
   *
   * @param {PublicationStartLocator|ParagraphLocator} locator
   * @returns {?string}
   * @private
   */
  getChapterIdByLocator: function getChapterIdByLocator(locator) {
    var chapterId = null;
    if (locator instanceof Locator.PublicationStartLocator) {
      return chapterId;
    }

    var paragraphElement = this.getElementByLocator(locator);
    /* jshint -W084 */
    do {
      chapterId = paragraphElement.getAttribute('data-chapter');
      if (chapterId !== null) {
        break;
      }
      // eslint-disable-next-line no-cond-assign
    } while ((paragraphElement = this.getPreviousParagraph(paragraphElement)));
    /* jshint +W084 */
    return chapterId;
  },
  getBlockIdByLocator: function getBlockIdByLocator(locator) {
    const para = this.getElementByLocator(locator);
    return this.getBlockIdFromPara(para);
  },
  getParaIdByBlockId: function getParaIdFromBlockId(blockId, container) {
    try {
      const para = container.querySelector(`[data-ilmid="${blockId}"]`);
      return para.getAttribute('id');
    } catch (error) {
      throw new Error(`Paragraph with blockId ${blockId} not found`);
    }
  },
  getBlockIdFromPara: function(para) {
    const blockId = para?.getAttribute('data-ilmid');
    return blockId || null;
  },
  getBlockIdByParaId: function name(paraId) {
    const para = this.getParagraphById(paraId);
    return this.getBlockIdFromPara(para);
  }
};

/**
 *
 * @param {Element} element
 * @param {string} selector
 * @returns {boolean}
 * @private
 */
var _is = function(element, selector) {
  _is =
    'matches' in element
      ? function(_element, _selector) {
          return _element.matches(_selector);
        }
      : function(_element, _selector) {
          return _element.msMatchesSelector(_selector);
        };
  return _is(element, selector);
};

/**
 * @param {Element} paragraph
 * @param {boolean} isDesc
 * @returns {Element|null}
 */
//function _getParagraphByDirection(paragraph, isDesc) {
//   var currentElement = paragraph.parentNode,
//         _sibling       = isDesc ? 'previousElementSibling' : 'nextElementSibling';
//
//   /* jshint -W084 */
//   while (currentElement = currentElement[_sibling]) {
//      if (MarkerUtils.isContent(currentElement ? currentElement.children[0] : currentElement)) {
//         break;
//      }
//   }
//   /* jshint +W084 */
//   return currentElement ? currentElement.children[0] : currentElement;
//}

function _prepareEvent(event) {
  if ('originalEvent' in event) {
    event = event.originalEvent;
  }

  if ('pointers' in event) {
    event = event.pointers[0];
  }

  if (
    event.targetTouches &&
    (event.targetTouches.length || event.changedTouches.length) >= 1
  ) {
    event = event.targetTouches[0] || event.changedTouches[0];
  }

  return event;
}

//TODO: temporary fix. Ask Max about opportunity to implement this functionality in ContentProvider
function _getParagraphByDirection(paragraph, isDesc) {
  var paragraphs = document.querySelectorAll('[id^=para_]');
  var i = Array.prototype.findIndex.call(paragraphs, function(a) {
    return a.id === paragraph.id;
  });

  return paragraphs[i + (isDesc ? -1 : 1)] || null; // if we on last para and try move forward or if we on first para and try move back
}

module.exports = MarkerUtils;
