import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('FilesHandlingLocalDb.js');
import AssetResourcesEnum from '@/enums/AssetResourcesEnum';
import FileAccessTypeEnum from '@/enums/FileAccessTypeEnum';
import { AssetsManagerConstans } from '@/services/AssetsManager/AssetsManagerConstans';
import FilesHandlingLocalDbService from './FilesHandlingLocalDbService';
import FeatureDetectorService from '@/services/FeatureDetector/FeatureDetectorService';
import responseParser from '@/services/AssetsManager/FileTransport/responseParser';
import { FilesHandlingInterface } from './FilesHandlingInterface';

class FilesHandlingLocalDb extends FilesHandlingInterface {
  constructor() {
    super(AssetResourcesEnum.LOCAL_DB);
  }
  init() {}
  async isAvailable() {
    const detections = await FeatureDetectorService.getDetections();
    return detections.indexDB;
  }
  createFileSourcePath() {
    throw new Error(
      'Need implement createFileSourcePath for FilesHandlingLocalDb'
    );
  }

  readFile(fileSourcePath, readOptions) {
    return Promise.resolve().then(() => {
      switch (readOptions.accessType) {
        case FileAccessTypeEnum.FULL:
          return this._readFullFile(fileSourcePath, readOptions);
        case FileAccessTypeEnum.CHUNKED:
          return this._readFileByChunk(fileSourcePath, readOptions);
        default:
          throw new Error(
            `Get unsupported accessType: ${readOptions.accessType} in readFile`
          );
      }
    });
  }

  async _readFullFile(fileSourcePath, readOptions) {
    const deserializedPath = this._deserializeFileSourcePath(fileSourcePath);
    let response;
    switch (deserializedPath.fileName) {
      case AssetsManagerConstans.fileMapName:
        response = await FilesHandlingLocalDbService.generatePublicationJSON(
          deserializedPath.compilationId
        );
        break;
      case AssetsManagerConstans.contentsTable:
        response = await FilesHandlingLocalDbService.generateLibraryManifestJSON(
          deserializedPath.compilationId
        );
        break;
      case AssetsManagerConstans.contentFileName:
        response = await FilesHandlingLocalDbService.readContent(
          deserializedPath.compilationId
        );
        break;
      case AssetsManagerConstans.bookMeta:
        response = await FilesHandlingLocalDbService.readMeta(
          deserializedPath.compilationId
        );
        break;
      case AssetsManagerConstans.alignmentFileName:
        response = await FilesHandlingLocalDbService.readAlignment(
          deserializedPath.compilationId
        );
        break;
      default:
        logger.error(`unsupported file ${fileSourcePath}`);
        break;
    }
    return responseParser.parse(response, readOptions);
  }
  // eslint-disable-next-line no-unused-vars
  _readFileByChunk(fileSourcePath, readOptions) {}

  _deserializeFileSourcePath(fileSourcePath) {
    let [compilationId, fileName] = fileSourcePath.split('?')[0].split('/');
    const isSerializedFileName = fileName.indexOf('__') !== -1;
    let hash;
    if (isSerializedFileName) {
      [hash, fileName] = fileName.split('__');
    }
    return {
      compilationId,
      fileName,
      hash
    };
  }

  writeFile() {
    throw new Error('Need implement writeFile for file handling');
  }
  removeFile() {
    throw new Error('Need implement removeFile for file handling');
  }
  getInternalMemory() {
    throw new Error('Need implement getInternalMemory for file handling');
  }
  removeAll() {
    throw new Error('Need implement removeAll for file handling');
  }
}

export { FilesHandlingLocalDb };
