import AssetTypeEnum from '@/enums/AssetTypeEnum';
import ManagePublicationsStates from '@/enums/ManagePublicationsStatesEnum';
import CompilationSelectionTypes from '@/enums/CompilationSelectionTypes';
import DataStateEnum from '@/enums/DataStateEnum';
import BrandsEnum from '@/enums/BrandsEnum';

import isEmpty from 'lodash/isEmpty';

import Http from '@/services/utils/Http';
import RestService from '@/services/RestService';
import RequestService from '@/services/RequestService';
import CompilationsService from '@/services/CompilationsService';
import CompilationsSharingService from '@/services/CompilationsSharingService';
import AssetsManager from '@/services/AssetsManager/AssetsManager';
import GoogleAnalyticsUtils from '@/services/utils/GoogleAnalyticsUtils';
import { AssetsManagerConstans } from '@/services/AssetsManager/AssetsManagerConstans';
import BookFactory from '@/classes/factories/BookFactory';
import CompilationInfoViewFactory from '@/classes/factories/ExtrasPopup/CompilationInfoViewFactory';
import CompilationFactory from '@/classes/factories/CompilationFactory';
import SearchPublicationsFactory from '@/classes/factories/SearchPublicationsFactory';
import LoggerFactory from '@/services/utils/LoggerFactory';
import cloneDeep from 'lodash/cloneDeep';
import images from '@/assets/images';

import Locator from '@shared/publication/locator';
import MarkerUtils from '@shared/publication/dom-utils/marker-utils';
import get from 'lodash/get';
import Utils from '@/services/utils/Utils';
import PopupNamesEnum from '@/enums/PopupNamesEnum';

const logger = LoggerFactory.getLogger('CompilationsStore.js');

function _convertToContentIndexFormat(rawItem, index) {
  return {
    id: rawItem.paraId,
    index,
    offset: 0,
    paraNum: null,
    start: 0,
    words: null
  };
}

function _isElementVoiced(alignmentIndexMap, paraId) {
  return alignmentIndexMap.hasOwnProperty(paraId);
}

function _createEditContext(data) {
  const editContext = {};
  editContext.selections = cloneDeep(data.selections);
  editContext.content = cloneDeep(data.content);
  editContext.rawAlignment = cloneDeep(data.rawAlignment);
  editContext.alignmentIndexMap = cloneDeep(data.alignmentIndexMap);
  editContext.meta = cloneDeep(data.meta);
  editContext.compilation = cloneDeep(data.compilations[editContext.meta.id]);
  return editContext;
}

function createCompilationState() {
  const initState = {
    dataState: DataStateEnum.NONE,
    alignmentIndexMap: {},
    rawAlignment: [],
    alignment: {},
    audioLinks: {},
    content: [],
    contentIndex: {},
    meta: {},
    compilations: {}
  };
  const initEditContext = _createEditContext(initState);
  initState.editContext = initEditContext;
  return initState;
}

function _rebuildContentOnAnction(selections) {
  const lastIndex = selections.length - 1;
  const content = [];
  const contentIndex = {};
  let wordsBefore = 0;
  let paragraphsNumber = 0;
  selections.forEach((selection, index) => {
    selection.locator =
      typeof selection.locator === 'string'
        ? Locator.deserialize(selection.locator)
        : selection.locator;
    const contentObj = CompilationsService.convertSelectionToBookItem(
      selection,
      index,
      lastIndex,
      wordsBefore
    );
    if (selection.type === CompilationSelectionTypes.SELECTION) {
      paragraphsNumber++;
      wordsBefore += contentObj.words;
    }
    content.push(contentObj);
    contentIndex[contentObj.id] = contentObj;
  });

  return { content, contentIndex, wordsBefore, paragraphsNumber };
}

async function persistNewSelections(selections, compilationId) {
  const itemsToPersist = [];
  for (const selection of selections) {
    if (selection.type !== CompilationSelectionTypes.SECTION) {
      itemsToPersist.push(selection._id);
      continue;
    }
    if (!selection._id) {
      selection.compilationId = compilationId;
      delete selection.clientSortId;
      try {
        await RequestService.request(
          'post',
          'Compilations',
          'appendSelection',
          { compilationId, selection }
        );
      } catch (err) {
        logger.error(err);
      }
    }
    if (selection.edited) {
      selection.compilationId = compilationId;
      delete selection.edited;
      const selectionData = { ...selection };
      try {
        await RequestService.request(
          'post',
          'Compilations',
          'persistSelection',
          { compilationId, selectionData }
        );
      } catch (err) {
        logger.error(err);
      }
    }
    itemsToPersist.push(selection._id);
  }
  return itemsToPersist;
}

function isSelectionExistInSection(state, selectionIndex, sectionId) {
  const selectionSet = state.editContext.selections;
  let isExist = false;
  for (let i = selectionIndex; i >= 0; i--) {
    if (selectionSet[i].type === 'Section') {
      if (selectionSet[i]._id === sectionId) {
        isExist = true;
      } else {
        isExist = false;
      }
      break;
    }
  }

  return isExist;
}

function _applyNewStateByKeys(state, newState, reinitKeys) {
  Object.keys(reinitKeys).forEach(key => {
    if (!newState[key]) {
      return;
    }
    state[key] = newState[key];
  });
}

export default {
  name: 'CompilationsStore',
  state: () => ({
    isEditMode: false,
    selections: [],
    sharingUrl: '',
    editContext: {},
    ...createCompilationState()
  }),
  getters: {
    isCompactData: state => {
      return state.dataState === DataStateEnum.COMPACT;
    },
    getEditMeta: state => () => {
      return state.editContext.meta;
    },
    getBookIdByParaId: state => paraId => {
      const paraNum = MarkerUtils.getParaNum(paraId);
      const selection = state.selections[paraNum - 1];
      return get(selection, 'publication.id', null);
    },
    getAudioParaId: state => paraId => {
      const paraNum = MarkerUtils.getParaNum(paraId);
      const selection = state.selections[paraNum - 1];
      return get(selection, 'locator.startLocator.prefixedParagraphId', '');
    },
    getMiddleParaIdNum: () => (startLocator, endLocator) => {
      const currentMiddleParaNumber = Math.floor(
        (startLocator._paragraphNumber + endLocator._paragraphNumber) / 2
      );
      return currentMiddleParaNumber;
    },
    getExcludeInitProps() {
      return [];
    },
    getIsEditMode(state) {
      return state.isEditMode;
    },
    getAlignment: state => {
      return state.alignment;
    },
    getAlignmentIndexMap: state => {
      return state.alignmentIndexMap;
    },
    getCompilations: state => () => {
      Object.entries(state.compilations).forEach(([key]) => {
        state.compilations[key] = CompilationInfoViewFactory.create(
          state.compilations[key]
        );
      });
      return state.compilations;
    },
    getAudioLinks: state => () => state.audioLinks,
    getParagraphAlignment: state => (compilationId, paraId) => {
      return state.alignment[paraId];
    },

    getNextVoicedParaId: (state, getters) => (bookId, paraId) => {
      if (getters['isElementVoiced'](bookId, paraId)) {
        return paraId;
      }
      const startIndex = MarkerUtils.getParaNum(paraId) + 1;
      for (let i = startIndex; i <= state.content.length; i++) {
        const paragraphId = `para_${i}`;
        if (getters['isElementVoiced'](bookId, paragraphId)) {
          return paragraphId;
        }
      }
      return null;
    },

    isElementVoiced: state => (compilationId, paraId) => {
      const alignmentIndexMap = state.isEditMode
        ? state.editContext.alignmentIndexMap
        : state.alignmentIndexMap;
      return _isElementVoiced(alignmentIndexMap, paraId);
    },

    getNextParaId: state => paraId => {
      const bookItem = state;
      const defaultVal = '';
      const contentIndex = bookItem.contentIndex[paraId];
      if (!contentIndex) {
        return defaultVal;
      }
      const nextIndex = contentIndex.index + 1;
      const contentItem = bookItem.content[nextIndex];
      if (!contentItem) {
        return defaultVal;
      }

      return contentItem.id;
    },

    getFirstAudioParagraph: state => () => {
      const paraId = Object.keys(state.alignmentIndexMap)[0];
      if (!paraId) {
        return '';
      }
      return paraId;
    },
    //todo: options = {asArray: false}
    getSelections: state => () => {
      return state.selections;
    },
    getPauseMsByParaId: () => () => {
      return 0;
    },
    hasPauseMap() {
      return false;
    },
    getSections: state => () => {
      const sections = [];
      const selections = state.isEditMode
        ? state.editContext.selections
        : state.selections;
      selections.forEach((selection, index) => {
        if (selection.type === CompilationSelectionTypes.SECTION) {
          sections.push({
            section: selection,
            index
          });
        }
      });

      return sections.map((section, index) => {
        return {
          ...section,
          selectionsNum:
            (sections[index + 1]
              ? sections[index + 1].index
              : selections.length) -
            section.index -
            1
        };
      });
    },
    getCompilationDuration: (state, getters) => compilationId => {
      //todo thre
      state.x = 0;
      const selections = getters.getSelections(compilationId);
      return CompilationsService.computeAudioDuration(selections);
    },
    getFirstContentParagraph: state => () => {
      if (!state.content.length) {
        return 'para_1';
      }
      return state.content[0].id;
    },
    getLastContentParagraph: state => () => {
      if (!state.content.length) {
        return null;
      }
      const lastIndex = state.content[state.content.length - 1];
      return lastIndex.id;
    },
    getParaIdByIndex: (state, getters) => (bookId, paraIndex) => {
      const contentItem = state.content[paraIndex];
      return contentItem
        ? contentItem.id
        : getters['getLastContentParagraph']();
    },
    getMeta: state => () => {
      const compilationItem = state;
      if (!compilationItem || isEmpty(compilationItem.meta)) {
        return null;
      }
      return compilationItem.meta;
    },
    getBookLanguage: state => {
      return state.meta && state.meta.language;
    },
    getPublicationStyleClassName: state => (compilationId, brand) => {
      const editModeClass = state.isEditMode ? 'compilation-edit' : '';
      let bookStyleClass = [
        `${brand}-book-styles`,
        'compilation-book',
        editModeClass
      ];
      return bookStyleClass;
    },
    getTotalParagraphItems: state => () => {
      return state.content.length;
    },
    getParagraphsSummary: state => () => {
      return state ? state.contentIndex : {};
    },
    getMetaView: state => () => {
      if (isEmpty(state.meta)) {
        return null;
      }
      return BookFactory.createCompilationMetaView(state.meta);
    },
    createCoverPath: (
      state,
      getters,
      rootState,
      rootGetters
    ) => compilationId => {
      const meta = rootGetters['LibraryStore/getPublicationById'](
        compilationId
      );
      const brand = rootGetters['ContextStore/brand'];
      const brandCover =
        brand === BrandsEnum.FFA
          ? images.compilationCoverFfa
          : images.compilationCoverOcean;

      if (!meta) {
        return brandCover;
      }

      if (meta.coverFileName) {
        return getters['getCompilationCoverUrl'](meta.coverFileName);
      }

      if (meta.isShared) {
        return images.compilationSharedCover73;
      }

      if (meta.default) {
        return images.compilationDefaultCover;
      }

      return brandCover;
    },
    getParagraphItems(state) {
      const source = state.isEditMode
        ? state.editContext.content
        : state.content;
      const contentObjectViews = source.map(contentObj => {
        return BookFactory.createContentObjectView(contentObj);
      });
      return contentObjectViews;
    },
    getCompilationView: () => compilation => {
      return CompilationInfoViewFactory.create(compilation);
    },
    getCompilationInfoView: state => compilationId => {
      const compilation = state.compilations[compilationId];
      return CompilationInfoViewFactory.create(
        compilation,
        state.selections,
        state.content
      );
    },
    getFullCompilationData: (state, getters) => compilationId => {
      const compilation = state.compilations[compilationId];
      const selections = getters.getSelections(compilationId);
      return { compilation, selections };
    },

    getSelectionByParaId: state => (compilationId, paraId) => {
      const selections = state.isEditMode
        ? state.editContext.selections
        : state.selections;
      const index = CompilationsService.convertParaIdToIndex(paraId);
      return selections[index];
    },

    getNextSelectionIndex: state => (compilationId, paraId) => {
      const selections = state.selections;
      const index = CompilationsService.convertParaIdToIndex(paraId);
      return Math.min(index + 1, selections.length - 1);
    },

    getCopySelectionQueryByParaId: (state, getters) => (
      compilationId,
      paraId
    ) => {
      const selection = getters.getSelectionByParaId(compilationId, paraId);
      const builder = CompilationFactory.getSelectionQueryBuilder();
      const serializedLocator = selection.location;
      return builder
        .setLocator(serializedLocator)
        .setAudio(selection.audio)
        .setType(CompilationSelectionTypes.SELECTION)
        .setContent(selection.content)
        .setWordsCount(selection.wordsCount)
        .setLanguage(selection.language)
        .setAlignment(selection.alignment)
        .setLocation(serializedLocator)
        .setPublication(selection.publication)
        .build();
    },

    getSelectionIdByParaId: (state, getters) => (compilationId, paraId) => {
      const selection = getters.getSelectionByParaId(compilationId, paraId);
      return selection._id;
    },

    getSectionTitleByParaId: (state, getters) => (compilationId, paraId) => {
      const selection = getters.getSelectionByParaId(compilationId, paraId);
      return selection.title;
    },

    isCurrentCompilationEmpty: state => {
      return !state.selections.length;
    },

    getBookItemPosition: () => () => {
      const defaultResp = BookFactory.createBookItemPosition();
      return defaultResp;
    },

    hasAudio: (state, getters) => compilationId => {
      const selections = getters.getSelections(compilationId, true);
      return selections.some(selection => !!selection.audio);
    },

    canBeEdited: (state, getters, rootState, rootGetters) => compilationId => {
      const compilation = state.compilations[compilationId];
      const userId = rootGetters['UserStore/getUserId'];
      const result =
        compilation &&
        (!compilation.isShared || compilation.authorId === userId);

      return result;
    },
    isParagraphDisabled: () => (/*paraId*/) => {
      logger.info('isParagraphDisabled() not implemented for compilations');
    },
    isContentReady: state => {
      return !!get(state, 'content', []).length;
    },
    isAudioReady: (state, getters) => () => {
      return getters.isContentReady;
    },
    getCompilationCoverUrl: (
      state,
      getters,
      rootState,
      rootGetters
    ) => coverFileName => {
      const compilationCoversList =
        rootGetters['LibraryStore/getCompilationCoversList'];
      const compilationCover = compilationCoversList.find(
        cover => cover.fileName === coverFileName
      );
      if (!compilationCover) {
        return '';
      }

      const { serverUrl } = rootGetters['ContextStore/contextGetter'];
      const url = new URL(serverUrl);
      url.pathname = Utils.pathJoin([
        url.pathname,
        compilationCover.relativePath
      ]);
      return url;
    },
    getRandomCoverFileName: (state, getters, rootState, rootGetters) => () => {
      const compilationCoversList =
        rootGetters['LibraryStore/getCompilationCoversList'];
      const randomIndex = Math.floor(
        Math.random() * compilationCoversList.length
      );
      const randomCover = compilationCoversList[randomIndex];
      return randomCover.fileName;
    },
    getBookInfoView: (state, getters) => () => {
      const compilationItem = state;
      if (!compilationItem) {
        return null;
      }
      return getters.getCompilationInfoView(compilationItem.meta.id);
    },
    getSelectionLinkToShare: (state, getters, rootState, rootGetters) => ({
      compilationId,
      paraId
    }) => {
      const selection = getters.getSelectionByParaId(compilationId, paraId);
      if (!selection) {
        return null;
      }

      const params = {
        clientUrl: rootGetters['ContextStore/getClientReaderUrl'],
        identifier: selection.publication?.slug || selection.publication?.id,
        locator: selection.locator,
        userId: rootGetters['UserStore/getUserId'],
        brand: rootGetters['ContextStore/brand']
      };
      return Utils.buildPublicationLink(params);
    },
    isPublicationHasRtl: state => {
      const selections = state.content;
      return selections.some(selection => selection.direction === 'rtl');
    },
    contentLength: state => {
      return state.content.length;
    }
  },
  mutations: {
    reinitCompilationItem(state, excludeInitProps = []) {
      const newSate = createCompilationState();
      Object.keys(state).forEach(key => {
        if (excludeInitProps.includes(key)) {
          return;
        }
        if (newSate.hasOwnProperty(key)) {
          state[key] = newSate[key];
        }
      });
    },
    cleanAudioState(state) {
      const newState = createCompilationState();
      const reinitKeys = [
        'rawAlignment',
        'alignmentIndexMap',
        'alignmentIndex',
        'alignment',
        'audioLinks'
      ];
      _applyNewStateByKeys(state, newState, reinitKeys);
    },
    cleanContentState(state) {
      const newState = createCompilationState();
      const reinitKeys = ['contentIndex', 'content'];
      _applyNewStateByKeys(state, newState, reinitKeys);
    },
    cleanMetaState(state) {
      const newState = createCompilationState();
      const reinitKeys = ['meta'];
      _applyNewStateByKeys(state, newState, reinitKeys);
    },
    updateMetaInfo(state, editableInfo) {
      state.editContext.meta.updateByEditableMeta(editableInfo);
    },
    updateMetaInfoCover(state, coverFileName) {
      state.editContext.meta.updateByEditableMetaCover(coverFileName);
    },
    setCompilation(state, compilation) {
      // TODO: get rid of '_id' everywhere in compilations
      state.compilations[compilation.id] = {
        ...compilation,
        _id: compilation.id
      };
    },
    addSection(state, section) {
      const selections = state.editContext.selections;
      selections.unshift(section);
      state.editContext.content = _rebuildContentOnAnction(selections).content;
    },
    addSelections(state, { selections }) {
      selections.forEach(selection => {
        if (selection.type === CompilationSelectionTypes.SELECTION) {
          state.selections.push(selection);
        }
      });
    },
    setSelection(state, { selections = [] }) {
      state.selections = selections;
    },
    addSelectionsByPosition(state, payload) {
      const { selections, position } = payload;
      selections.forEach(data => {
        if (data.type !== CompilationSelectionTypes.SELECTION) {
          return;
        }
        state.selections.splice(position, 0, data);
      });
    },
    applyEditContext(state) {
      state.meta = state.editContext.meta;
      state.selections = state.editContext.selections;
      state.rawAlignment = state.editContext.rawAlignment;
      state.alignmentIndexMap = state.editContext.alignmentIndexMap;
      state.content = _rebuildContentOnAnction(state.selections).content;
    },
    setContent(state, payload) {
      const { selections } = payload;
      const { content, contentIndex } = _rebuildContentOnAnction(selections);

      state.selections = selections;
      state.content = content;
      state.contentIndex = contentIndex;
    },
    setModifiedAt(state, modifiedAt) {
      state.meta.setModifiedAt(modifiedAt);
    },
    setUrlForSharing(state, sharingUrl) {
      state.sharingUrl = sharingUrl;
    },
    setMetaById(state, payload) {
      const { meta, compilationId } = payload;
      const compilationMeta = BookFactory.createCompilationMeta(meta);
      compilationMeta.setWordsCount(meta.wordsCount);
      compilationMeta.setParagraphsNumber(
        CompilationsService.getSelectionsCount(meta.items)
      );
      state.meta = compilationMeta;
      state.compilations[compilationId] = meta;
    },
    setAlignmentIndex(state, rawAlignment) {
      const alignment = {};
      const alignmentIndexMap = {};
      rawAlignment.forEach((rawItem, index) => {
        if (!rawItem.alignment.length) {
          return;
        }
        alignmentIndexMap[rawItem.paraId] = _convertToContentIndexFormat(
          rawItem,
          index
        );
        const paraIndex = MarkerUtils.getParaNum(rawItem.paraId);
        const updatedLocators = rawItem.alignment[1].map(locator => {
          return locator.replace(/^\d+/, paraIndex);
        });
        alignment[rawItem.paraId] = [rawItem.alignment[0], updatedLocators];
      });
      state.alignmentIndexMap = alignmentIndexMap;
      state.rawAlignment = rawAlignment;
      state.alignment = alignment;
    },
    setAudioLinks(state, audioLinks) {
      state.audioLinks = audioLinks;
    },
    updateSectionTitle(state, { sectionId, title }) {
      const index = state.editContext.selections.findIndex(
        selection => selection._id === sectionId
      );
      state.editContext.selections[index].title = title;
      state.editContext.selections[index].edited = true;
    },
    setIsEditMode(state, isEditMode) {
      state.editContext = _createEditContext(state);
      state.isEditMode = isEditMode;
    },
    removeCompilation(state, compilationId) {
      if (state.compilations.hasOwnProperty(compilationId)) {
        delete state.compilations[compilationId];
      }
    },
    addSelectionToIndex(state, { selection, index }) {
      state.editContext.selections.splice(index, 0, selection);
      state.editContext.content = _rebuildContentOnAnction(
        state.editContext.selections
      ).content;
    },
    removeSelection(state, payload) {
      const { selectionId, paraId } = payload;
      const { selections, rawAlignment } = state.editContext;
      const selectionIndex = selections.findIndex(
        selection => selection._id === selectionId
      );
      if (selectionIndex === -1) {
        return false;
      }
      const wordsCount =
        state.editContext.meta.wordsCount -
        selections[selectionIndex].wordsCount;
      state.editContext.meta.setWordsCount(wordsCount);
      selections.splice(selectionIndex, 1);
      state.editContext.content = _rebuildContentOnAnction(selections).content;

      const alignmentIndex = rawAlignment.findIndex(
        alignment => alignment.paraId === paraId
      );

      if (alignmentIndex === -1) {
        return false;
      }
      rawAlignment.splice(alignmentIndex, 1);
      state.editContext.meta.setAudio(selections);
      return true;
    },
    recomposeAlignment(state) {
      const { selections } = state.editContext;
      state.editContext.rawAlignment = CompilationsService.recomposeAlignment(
        selections
      );
    },
    moveSelectionUp(state, payload) {
      const { selectionId, clientSortId } = payload;
      let handled = CompilationsService.moveSelectionsUp(
        state.editContext,
        selectionId,
        clientSortId
      );
      state.editContext.content = _rebuildContentOnAnction(
        state.editContext.selections
      ).content;
      return handled;
    },
    moveSelectionDown(state, payload) {
      const { selectionId, clientSortId } = payload;
      let handled = CompilationsService.moveSelectionsDown(
        state.editContext,
        selectionId,
        clientSortId
      );
      state.editContext.content = _rebuildContentOnAnction(
        state.editContext.selections
      ).content;
      return handled;
    },
    setPublicationContentDisabled(/*state, payload*/) {
      logger.info(
        'setPublicationContentDisabled() for compilations not implemented'
      );
    },
    setPublicationContentEnabled(/*state*/) {
      logger.info(
        'setPublicationContentEnabled() for compilations not implemented'
      );
    },
    resetSelections(state) {
      state.selections = [];
    }
  },
  actions: {
    compactStore() {},
    async saveMetaData({ state, dispatch, commit }, { compilationId }) {
      const compilation = state.compilations[compilationId];
      const selections = state.editContext.selections;
      const persistIds = await persistNewSelections(selections, compilationId);
      compilation.items = persistIds;
      compilation.title = state.editContext.meta.name;
      compilation.description = state.editContext.meta.description;
      compilation.coverFileName = state.editContext.meta.coverFileName;
      compilation.wordsCount = state.editContext.meta.wordsCount;
      compilation.audio = state.editContext.meta.audio;
      commit('applyEditContext');
      await dispatch('create', { compilation });
      await dispatch('actualizeCompilation', {
        compilationId
      });
      await dispatch('updateCompilation', { compilationId });
      await dispatch('initAudioLinks', compilationId);
    },
    destroyPublicationState({ commit }, excludeInitProps) {
      commit('reinitCompilationItem', excludeInitProps);
    },
    async initPublicationContent({ commit, dispatch }, { publicationId }) {
      commit('cleanContentState');
      await AssetsManager.initBookAssets(publicationId);

      return dispatch('initCompilation', publicationId);
    },
    async initCompilation({ dispatch }, compilationId) {
      return dispatch('loadSelections', compilationId);
    },
    async initPublicationAudio({ commit, dispatch }, { publicationId }) {
      commit('cleanAudioState');
      await AssetsManager.initBookAssets(publicationId);

      await dispatch('initAlignmentIndex', publicationId);
      await dispatch('initAudioLinks', publicationId);
    },
    async loadById({ commit }, compilationId) {
      const { data } = await RequestService.request(
        'get',
        'Compilations',
        'getCompilationById',
        compilationId
      );
      commit('setCompilation', data);
    },
    async loadSelectionsItems({ commit, state }, compilationId) {
      const selectionsIds = state.compilations[compilationId].items;
      const response = await RequestService.request(
        'get',
        'Compilations',
        'getSelectionsByIds',
        { selectionsIds }
      );
      commit('resetSelections');
      commit('setSelection', { selections: response.data });
    },
    async changeDefaultCompilation(
      { commit, dispatch },
      { compilationId, isDefault }
    ) {
      await RequestService.request(
        'post',
        'Compilations',
        'changeDefaultCompilation',
        { compilationId, isDefault }
      );
      commit(
        'LibraryStore/updateCompilationIsDefault',
        { publicationId: compilationId, isDefault },
        { root: true }
      );
      await dispatch('updateCompilation', { compilationId });
    },
    async changeCompilationCover(
      { commit, dispatch, state },
      { compilationId, coverFileName }
    ) {
      if (state.isEditMode) {
        commit('updateMetaInfoCover', coverFileName);
        return;
      }

      await RequestService.request(
        'post',
        'Compilations',
        'changeCompilationCover',
        { compilationId, coverFileName }
      );
      commit(
        'LibraryStore/updateCompilationCover',
        { publicationId: compilationId, coverFileName },
        { root: true }
      );
      await dispatch('updateCompilation', { compilationId });
    },
    moveToSection({ state, commit, getters }, { paraId, sectionId }) {
      const selectionIndex = CompilationsService.convertParaIdToIndex(paraId);
      if (isSelectionExistInSection(state, selectionIndex, sectionId)) {
        return;
      }
      const selection = state.editContext.selections[selectionIndex];
      const sections = getters.getSections();
      const sectionIndex = sections.findIndex(
        section => section.section._id === sectionId
      );
      const isLastSection = sectionIndex === sections.length - 1;
      const insertIndex = isLastSection
        ? state.editContext.selections.length - 1
        : sections[sectionIndex].index + 1;
      commit('removeSelection', { paraId, selectionId: selection._id });
      commit('addSelectionToIndex', { selection, index: insertIndex });
      commit('recomposeAlignment');
    },
    async loadFullCompilationData({ dispatch }, compilationId) {
      await dispatch('loadById', compilationId);
      await dispatch('loadSelectionsItems', compilationId);
    },
    async initCompilations({ commit, rootGetters, dispatch }) {
      await dispatch(
        'LibraryStore/reinitState',
        {
          managePublicationsState: ManagePublicationsStates.COMPILATIONS,
          forceRemote: false
        },
        { root: true }
      );

      const builder = SearchPublicationsFactory.getSearcherBuilder();
      const searcher = builder
        .setState(ManagePublicationsStates.COMPILATIONS)
        .build();
      const compilations = rootGetters['LibraryStore/searchPublications'](
        searcher
      );
      compilations.forEach(compilation =>
        commit('setCompilation', compilation)
      );
    },
    async readMeta({ commit }, compilationId) {
      commit('cleanMetaState');
      const meta = await AssetsManager.readBookMetaFile(compilationId);
      commit('setMetaById', {
        compilationId,
        meta
      });
      return meta;
    },
    async loadSelections({ commit }, compilationId) {
      let selections = await AssetsManager.readFile(
        compilationId,
        AssetsManagerConstans.contentFileName
      );
      selections = JSON.parse(selections);
      selections = _fixSelections(selections);

      commit('setContent', {
        compilationId,
        selections
      });
    },
    async initAlignmentIndex({ commit }, compilationId) {
      const rawAlignment = await AssetsManager.readFile(
        compilationId,
        AssetsManagerConstans.alignmentFileName
      );
      const alignment = JSON.parse(rawAlignment);
      commit('setAlignmentIndex', alignment);
    },
    async initAudioLinks({ state, commit }, compilationId) {
      const audioLinks = {};
      const promises = Object.keys(state.alignmentIndexMap).map(paraId =>
        AssetsManager.getAudioSourcePath(compilationId, paraId).then(
          link => (audioLinks[paraId] = link)
        )
      );
      await Promise.all(promises);
      commit('setAudioLinks', audioLinks);
    },

    getBookAlignmentByContentRequest() {
      return Promise.resolve();
    },

    create(_, payload) {
      GoogleAnalyticsUtils.trackEvent('Compilation', 'create');
      return RequestService.request(
        'post',
        'Compilations',
        'persistCompilation',
        payload
      ).then(({ data }) => data);
    },

    async appendSelectionHandler({ dispatch }, data) {
      await dispatch('appendSelection', data);
    },

    async appendSelection({ dispatch, commit }, data) {
      GoogleAnalyticsUtils.trackEvent('Compilation', 'addSelection');
      const response = await RequestService.request(
        'post',
        'Compilations',
        'appendSelection',
        data
      );
      const selections = response.data;

      if (data.position !== -1 && typeof data.position === 'number') {
        commit('addSelectionsByPosition', {
          selections,
          position: data.position
        });
      } else {
        commit('addSelections', {
          selections
        });
      }

      await dispatch('updateCompilation', {
        compilationId: data.compilationId,
        excludeAssetNames: data.excludeAssetNames
      });
      return selections;
    },
    duplicateSelection({ dispatch, getters }, data) {
      const { compilationId, paraId } = data;
      const selectionQuery = getters.getCopySelectionQueryByParaId(
        compilationId,
        paraId
      );
      const nextSelectionIndex = getters.getNextSelectionIndex(
        compilationId,
        paraId
      );

      return dispatch('appendSelection', {
        position: nextSelectionIndex,
        compilationId,
        selection: selectionQuery
      });
    },
    // eslint-disable-next-line
    async removeSelection({ commit, getters }, { compilationId, paraId }) {
      const selectionId = getters.getSelectionIdByParaId(compilationId, paraId);
      commit('removeSelection', { compilationId, selectionId, paraId });
      commit('recomposeAlignment');
    },
    async appendSection({ commit }, data) {
      data.position = 0;
      data.excludeAssetNames = ['audio'];
      commit('addSection', data.selection);
      commit('recomposeAlignment');
    },
    async moveSelectionUp(
      { commit, getters },
      { compilationId, paraId, clientSortId }
    ) {
      const selectionId = getters.getSelectionIdByParaId(compilationId, paraId);
      commit('moveSelectionUp', {
        selectionId,
        clientSortId
      });
      commit('recomposeAlignment');
    },

    async moveSelectionDown(
      { commit, getters },
      { compilationId, paraId, clientSortId }
    ) {
      const selectionId = getters.getSelectionIdByParaId(compilationId, paraId);
      commit('moveSelectionDown', {
        selectionId,
        clientSortId
      });
      commit('recomposeAlignment');
    },

    async actualizeCompilation(
      { state, commit, dispatch },
      { compilationId, deleteAssetFile }
    ) {
      await dispatch('readMeta', compilationId);
      commit('setModifiedAt', state.compilations[compilationId].modifiedAt);
      commit('setContent', {
        compilationId,
        selections: state.selections
      });
      commit('setAlignmentIndex', state.rawAlignment);
      if (deleteAssetFile) {
        await AssetsManager.deleteAssetFile(
          compilationId,
          deleteAssetFile,
          AssetTypeEnum.AUDIO
        );
      }
    },

    // eslint-disable-next-line no-unused-vars
    async updateCompilation(
      { dispatch },
      { compilationId, excludeAssetNames }
    ) {
      const trackingItemView = await AssetsManager.getPublicationTrackingItemView(
        compilationId
      );
      const isDownloaded = trackingItemView.hasAudio
        ? trackingItemView.isContentDownloaded &&
          trackingItemView.isAudioDownloaded
        : trackingItemView.isContentDownloaded;
      AssetsManager.destroyPublicationAssetState(compilationId);
      if (isDownloaded) {
        await AssetsManager.updatePublication(compilationId, excludeAssetNames);
        await dispatch(
          'PublicationStore/updatePublicationRelatedStores',
          compilationId,
          { root: true }
        );
      }
      await dispatch('PublicationStore/autoUpdateBook', compilationId, {
        root: true
      });
      await dispatch('initCompilations');
      await dispatch('persistForSharing', compilationId);
    },

    async setAudioLink({ dispatch }, { compilationId, source, target }) {
      await AssetsManager.replaceAssetInfo(
        compilationId,
        source,
        target,
        AssetTypeEnum.AUDIO
      );
      await dispatch('initAudioLinks', compilationId);
    },

    // eslint-disable-next-line
    remove({ commit }, compilationId) {
      commit('removeCompilation', compilationId);
      return RequestService.request(
        'post',
        'Compilations',
        'removeCompilation',
        compilationId
      );
    },

    // eslint-disable-next-line
    updateSectionTitle({ getters, commit }, { paraId, title, compilationId }) {
      const sectionId = getters.getSelectionIdByParaId(compilationId, paraId);
      commit('updateSectionTitle', { sectionId, title });
    },

    async copy({ dispatch, getters }, compilationId) {
      const coverFileName = getters['getRandomCoverFileName']();
      await RequestService.request('post', 'Compilations', 'copyCompilation', {
        compilationId,
        coverFileName
      });
      return dispatch('initCompilations');
    },
    getContentByContentRequest({ getters }, contentRequest) {
      const { bookId, directionRange } = contentRequest;
      let content = getters['getSelections'](bookId, true).slice(
        directionRange.from,
        directionRange.to
      );
      content = _rebuildContentOnAnction(content).content;
      return Promise.resolve(content);
    },
    async share({ dispatch, commit }, compilationId) {
      await dispatch('loadFullCompilationData', compilationId);
      const response = await dispatch('persistForSharing', compilationId);
      const shareUrl = response.data.url;
      commit('setUrlForSharing', shareUrl);
      return Promise.resolve(response.data.url);
    },
    async persistForSharing({ getters }, compilationId) {
      const { compilation, selections } = getters.getFullCompilationData(
        compilationId
      );
      const sharingCompilation = { ...compilation };
      sharingCompilation.items = selections;
      const response = await RestService.restRequest(
        'post',
        'CompilationsSharing',
        'persistForSharing',
        sharingCompilation
      );
      return response;
    },
    async uploadSharedCompilation({ dispatch, rootGetters }, shareId) {
      const { serverUrl, parameters } = rootGetters[
        'ContextStore/contextGetter'
      ];
      const dataUrl = CompilationsSharingService.getSharedCompilationDataUrl({
        serverUrl,
        urlTemplate: parameters.compilations.sharingUrlTemplate,
        branch: parameters.branch,
        brand: parameters.brand,
        shareId
      });

      const { data } = await Http.get(dataUrl);
      const userId = rootGetters['UserStore/getUserId'];
      const isOwner = data.authorId === userId;

      if (!isOwner) {
        const compilation = JSON.parse(JSON.stringify(data));
        const selections = data.items;
        delete compilation.items;
        const processedSections = selections.map(function(selection) {
          delete selection._id;
          delete selection._rev;
          return selection;
        });
        compilation.isShared = true;
        await dispatch('create', {
          compilation,
          selections: processedSections
        });
      }
      return data._id;
    },
    setPublicationContentDisabled: function({ commit }, payload) {
      commit('setPublicationContentDisabled', payload);
    },
    setPublicationContentEnabled: function({ commit }) {
      commit('setPublicationContentEnabled');
    },
    addSelectionToCompilation: async function({ dispatch }, payload) {
      const popupName = PopupNamesEnum.ADD_TO_COMPILATION_POPUP;

      dispatch(
        'ManagePopupStore/openPopup',
        {
          name: popupName,
          popupContext: { data: payload }
        },
        { root: true }
      );
    },
    async getAudioSourceLink({ rootGetters }, payload) {
      const { publicationId, paraId } = payload;
      const isIos15_4 = rootGetters['ContextStore/isIos15_4'];
      const isSafariVersion15_4 =
        rootGetters['ContextStore/isSafariVersion15_4'];
      let sourceLink = '';
      if (isIos15_4 || isSafariVersion15_4) {
        sourceLink = await AssetsManager.getAudioSourcePath(
          publicationId,
          paraId
        );
      } else {
        const blob = await AssetsManager.readAudioFile(publicationId, paraId);
        sourceLink = window.URL.createObjectURL(blob);
      }
      return sourceLink;
    },
    async getCompilationAudioLink({ rootGetters }, compilationId) {
      const severUrl = rootGetters['ContextStore/getServerUrl'];
      const generationLink =
        '/rest/compilationsSharing/generateAudio/' + compilationId;
      const joinURL = (base, path) => {
        return base.replace(/\/+$/, '') + '/' + path.replace(/^\/+/, '');
      };

      const fullURL = joinURL(severUrl, generationLink);

      const res = await Http.http({
        method: 'get',
        url: fullURL
      });

      return res.data.url;
    }
  }
};

function _fixSelections(selections) {
  selections = selections.map(selection => {
    try {
      if (selection.type !== CompilationSelectionTypes.SELECTION) {
        return selection;
      }

      const location = selection.location;
      if (typeof location === 'string') {
        if (!selection.locator) {
          logger.warn(
            `Selection broken: missed "locator" field for selection ${selection._id}`
          );
          selection.locator = Locator.deserialize(location);
        }
        return selection;
      }

      const startLocator = new Locator.InTextLocator(
        location.startLocator.paragraphId,
        location.startLocator.logicalCharOffset
      );
      const endLocator = new Locator.InTextLocator(
        location.endLocator.paragraphId,
        location.endLocator.logicalCharOffset
      );
      const rangeLocator = new Locator.InTextRangeLocator(
        startLocator,
        endLocator
      );

      const locationValue = rangeLocator.serialize();
      selection.locator = selection.location;
      selection.location = locationValue;

      logger.warn(
        `Selection broken: calculated "location" field value for selection ${selection._id}`
      );

      return selection;
    } catch (err) {
      logger.error(
        `Something went wrong during processing selection #${selection._id}: ${err}`
      );

      return selection;
    }
  });

  return selections;
}
