import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../src/store/ActivityStore.js'), 'ActivityStore.js')
  resolveStoreModules(require('../src/store/AnnotationsStore.js'), 'AnnotationsStore.js')
  resolveStoreModules(require('../src/store/AssessmentStore.js'), 'AssessmentStore.js')
  resolveStoreModules(require('../src/store/BloggerStore.js'), 'BloggerStore.js')
  resolveStoreModules(require('../src/store/BookmarkStore.js'), 'BookmarkStore.js')
  resolveStoreModules(require('../src/store/BookStore.js'), 'BookStore.js')
  resolveStoreModules(require('../src/store/CampaignsStore.js'), 'CampaignsStore.js')
  resolveStoreModules(require('../src/store/CategoriesStore.js'), 'CategoriesStore.js')
  resolveStoreModules(require('../src/store/CompilationsStore.js'), 'CompilationsStore.js')
  resolveStoreModules(require('../src/store/ContextStore.js'), 'ContextStore.js')
  resolveStoreModules(require('../src/store/DictionaryStore.js'), 'DictionaryStore.js')
  resolveStoreModules(require('../src/store/ExternalBlogStore.js'), 'ExternalBlogStore.js')
  resolveStoreModules(require('../src/store/FavouritePublicationsStore.js'), 'FavouritePublicationsStore.js')
  resolveStoreModules(require('../src/store/LibraryStore.js'), 'LibraryStore.js')
  resolveStoreModules(require('../src/store/LoginStore.js'), 'LoginStore.js')
  resolveStoreModules(require('../src/store/ManagePopupStore.js'), 'ManagePopupStore.js')
  resolveStoreModules(require('../src/store/MaterialsStore.js'), 'MaterialsStore.js')
  resolveStoreModules(require('../src/store/MediaDetectorStore.js'), 'MediaDetectorStore.js')
  resolveStoreModules(require('../src/store/MediumStore.js'), 'MediumStore.js')
  resolveStoreModules(require('../src/store/OfflineSearchStore.js'), 'OfflineSearchStore.js')
  resolveStoreModules(require('../src/store/OpenManagePublicationsStore.js'), 'OpenManagePublicationsStore.js')
  resolveStoreModules(require('../src/store/OpenParameterStore.js'), 'OpenParameterStore.js')
  resolveStoreModules(require('../src/store/PaymentsStore.js'), 'PaymentsStore.js')
  resolveStoreModules(require('../src/store/PlaybackStore.js'), 'PlaybackStore.js')
  resolveStoreModules(require('../src/store/ProgressStore.js'), 'ProgressStore.js')
  resolveStoreModules(require('../src/store/PublicationStore.js'), 'PublicationStore.js')
  resolveStoreModules(require('../src/store/ReadingSettingsStore.js'), 'ReadingSettingsStore.js')
  resolveStoreModules(require('../src/store/RecentBookStore.js'), 'RecentBookStore.js')
  resolveStoreModules(require('../src/store/StudyClassStore.js'), 'StudyClassStore.js')
  resolveStoreModules(require('../src/store/SubscriptionStore.js'), 'SubscriptionStore.js')
  resolveStoreModules(require('../src/store/TextCopyStore.js'), 'TextCopyStore.js')
  resolveStoreModules(require('../src/store/UserDemographicDataStore.js'), 'UserDemographicDataStore.js')
  resolveStoreModules(require('../src/store/UserSegmentationDataStore.js'), 'UserSegmentationDataStore.js')
  resolveStoreModules(require('../src/store/UserStore.js'), 'UserStore.js')
  resolveStoreModules(require('../src/store/VocabularyAssessmentStore.js'), 'VocabularyAssessmentStore.js')
  resolveStoreModules(require('../src/store/WikiStore.js'), 'WikiStore.js')
  resolveStoreModules(require('../src/store/ZoomStore.js'), 'ZoomStore.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
