import get from 'lodash/get';
import MarkerUtils from '@shared/publication/dom-utils/marker-utils';
import Locator from '@shared/publication/locator';
import HighlightUtils from '@/services/utils/HighlightUtils';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';

const SELECTION_RANGE_SEPARATOR = AppConstantsUtil.SELECTION_RANGE_SEPARATOR;

class Highlight {
  constructor(buildData) {
    this.stems = buildData.stems;
    this.quotes = buildData.quotes;
    this.selection = buildData.selection;
    const startLocator = this.getStartSelection();
    const endLocator = this.getEndSelection();
    if (startLocator && startLocator.blockId) {
      this.isEmpty = false;
    } else {
      this.isEmpty =
        (startLocator?.logicalCharOffset === 0 &&
          endLocator?.logicalCharOffset === 0) ||
        (this.stems.length === 0 &&
          this.quotes.length === 0 &&
          (!startLocator ||
            !startLocator.toJSON ||
            startLocator.toJSON() == ''));
    }
  }

  getStartSelection() {
    return get(this.selection, 'start', null);
  }

  getEndSelection() {
    return get(this.selection, 'end', null);
  }

  getStartLocator(paragraphContainer, store) {
    const startSelection = this.getStartSelection();
    const locatorFromHtml = this._getLocatorBySelection(
      startSelection,
      paragraphContainer
    );
    if (locatorFromHtml) {
      return locatorFromHtml;
    }
    const locatorFromStore = this._getLocatorFromBookStore(
      startSelection,
      store
    );
    return locatorFromStore;
  }

  getEndLocator(paragraphContainer, store) {
    const endSelection = this.getEndSelection();
    const locatorFromHtml = this._getLocatorBySelection(
      endSelection,
      paragraphContainer
    );
    if (locatorFromHtml) {
      return locatorFromHtml;
    }
    const locatorFromStore = this._getLocatorFromBookStore(endSelection, store);
    return locatorFromStore;
  }

  getRangeLocator(paragraphContainer, store) {
    const startLocator = this.getStartLocator(paragraphContainer, store);
    const endSelection = this.getEndLocator(paragraphContainer, store);
    return new Locator.InTextRangeLocator(startLocator, endSelection);
  }

  _getLocatorBySelection(selection, paragraphContainer) {
    if (selection.prefixedParagraphId) {
      return selection;
    }
    let paraId;
    try {
      paraId = MarkerUtils.getParaIdByBlockId(
        selection.blockId,
        paragraphContainer
      );
    } catch {
      return null;
    }
    return new Locator.InTextLocator(paraId, selection.logicalCharOffset);
  }

  _getLocatorFromBookStore(selection, store) {
    if (selection.prefixedParagraphId) {
      return selection;
    }
    const paraId = store.getters['BookStore/getParaIdByBlockId'](
      selection.blockId
    );
    return new Locator.InTextLocator(paraId, selection.logicalCharOffset);
  }

  clearHighlight() {
    this.isEmpty = true;
    this.stems = [];
    this.quotes = [];
    this.selection = HighlightUtils.createSelectionHighlightFromString(
      SELECTION_RANGE_SEPARATOR
    );
  }

  toJSON() {
    return {
      isEmpty: this.isEmpty,
      stems: this.stems,
      quotes: this.quotes,
      selection: this.selection
    };
  }

  setStem(stems) {
    this.stems = stems;
  }

  setQuotes(quotes) {
    this.quotes = quotes;
  }
}

class HighlightBuilder {
  setStems(stems) {
    this.stems = stems;
    return this;
  }
  setQuotes(quotes) {
    this.quotes = quotes;
    return this;
  }
  setSelection(selection) {
    this.selection = selection;
    return this;
  }
  build() {
    return new Highlight(this);
  }
}

class PresentPublicationSettings {
  constructor(buildData) {
    this.audioPlayer = buildData.audioPlayer;
    this.showSelection = buildData.showSelection;
    this.showAnnotanion = buildData.showAnnotanion;
    this.showSearchNavigation = buildData.showSearchNavigation;
    this.showProgressToolbar = buildData.showProgressToolbar;
    this.scrollSettings = buildData.scrollSettings;
  }

  setAudioPlayer(audioPlayer) {
    this.audioPlayer = audioPlayer;
  }
}

class ScrollSettings {
  constructor(buildData) {
    this.showScrollBar = buildData.showScrollBar;
    this.scrollSize = buildData.scrollSize;
    this.viewPortSize = buildData.viewPortSize;
  }
}

class PresentPublicationSettingsBuilder {
  setAudioPlayer(audioPlayer) {
    this.audioPlayer = audioPlayer;
    return this;
  }

  setShowSelection(showSelection) {
    this.showSelection = showSelection;
    return this;
  }

  setShowAnnotanion(showAnnotanion) {
    this.showAnnotanion = showAnnotanion;
    return this;
  }

  setShowSearchNavigation(showSearchNavigation) {
    this.showSearchNavigation = showSearchNavigation;
    return this;
  }

  setShowProgressToolbar(showProgressToolbar) {
    this.showProgressToolbar = showProgressToolbar;
    return this;
  }

  setScrollSettings(scrollSettings) {
    this.scrollSettings = scrollSettings;
    return this;
  }

  build() {
    return new PresentPublicationSettings(this);
  }
}

class ScrollSettingsBuilder {
  setShowScrollBar(showScrollBar) {
    this.showScrollBar = showScrollBar;
    return this;
  }
  setScrollSize(scrollSize) {
    this.scrollSize = scrollSize;
    return this;
  }
  setViewPortSize(viewPortSize) {
    this.viewPortSize = viewPortSize;
    return this;
  }

  build() {
    return new ScrollSettings(this);
  }
}

class SelectedTextMeta {
  constructor(params) {
    this.text = params.text || '';
    this.textLink = params.textLink || '';
    this.paragraphNumber = params.paragraphNumber || '';
    this.publicationAuthor = params.publicationAuthor || '';
    this.publicationTitle = params.publicationTitle || '';
  }
}

class SelectedTextMetaBuilder {
  setText(text) {
    this.text = text;
    return this;
  }
  setTextLink(textLink) {
    this.textLink = textLink;
    return this;
  }
  setParagraphNumber(paragraphNumber) {
    this.paragraphNumber = paragraphNumber;
    return this;
  }
  setPublicationAuthor(publicationAuthor) {
    this.publicationAuthor = publicationAuthor;
    return this;
  }
  setPublicationTitle(publicationTitle) {
    this.publicationTitle = publicationTitle;
    return this;
  }
  build() {
    return new SelectedTextMeta(this);
  }
}

function getSettingsBuilder() {
  return new PresentPublicationSettingsBuilder();
}

function getHighlightBuilder() {
  return new HighlightBuilder();
}

function getSelectedTextMetaBuilder() {
  return new SelectedTextMetaBuilder();
}

function buildHighlightFromRawData(rawData) {
  const { stems, quotes, selectionString } = rawData;
  const parsedStems = HighlightUtils.parseRawStems(stems);
  const parsedQuotes = HighlightUtils.parseRawQuotes(quotes);
  const selection = HighlightUtils.createSelectionHighlightFromString(
    selectionString
  );

  const builder = getHighlightBuilder();
  return builder
    .setStems(parsedStems)
    .setQuotes(parsedQuotes)
    .setSelection(selection)
    .build();
}

function transformHighlightToClientClass({ quotes, selection, stems }) {
  let transformedSelection = selection;
  if (typeof selection.start === 'string') {
    transformedSelection = {
      start: Locator.deserialize(selection.start),
      end: Locator.deserialize(selection.end)
    };
  }
  const builder = getHighlightBuilder();
  return builder
    .setQuotes(quotes)
    .setSelection(transformedSelection)
    .setStems(stems)
    .build();
}

function buildEmptyHighlight() {
  const builder = getHighlightBuilder();
  return builder
    .setStems([])
    .setQuotes([])
    .setSelection(
      HighlightUtils.createSelectionHighlightFromString(
        SELECTION_RANGE_SEPARATOR
      )
    )
    .build();
}

function buildReaderSettings(viewPortHeight, viewPortWidth) {
  const builder = getSettingsBuilder();
  const scrollSettings = buildReadingScrollSettings(
    viewPortHeight,
    viewPortWidth
  );
  return builder
    .setAudioPlayer(false)
    .setShowSelection(true)
    .setShowAnnotanion(true)
    .setShowSearchNavigation(true)
    .setShowProgressToolbar(true)
    .setScrollSettings(scrollSettings);
}

function buildReadingScrollSettings(viewPortHeight, viewPortWidth) {
  const builder = new ScrollSettingsBuilder();
  const viewPortSize = {
    height: viewPortHeight,
    width: viewPortWidth
  };
  return builder
    .setShowScrollBar(true)
    .setScrollSize(300)
    .setViewPortSize(viewPortSize);
}

export default {
  getHighlightBuilder,
  getSelectedTextMetaBuilder,
  buildHighlightFromRawData,
  transformHighlightToClientClass,
  buildEmptyHighlight,
  buildReaderSettings
};
