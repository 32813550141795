<template>
  <Popup
    :content-class="customPopupSelector"
    :header-title="$t('OnboardingPageTitle.text')"
    scrollable
    fullscreen
    persistent
    hide-overlay
    :secondary-footer-handler="close"
    :secondary-label="$t('UserSegmentationPopup.cancelButton.label')"
    :secondary-footer-handler-color="'primary'"
    :secondary-footer-handler-type-text="true"
    :secondary-footer-handler-type-outlined="false"
    :primary-footer-handler="save"
    :primary-label="$t('UserSegmentationPopup.saveButton.label')"
    :primary-footer-handler-disabled="
      !(isOnline && isUserSegmentationFormValid)
    "
    @popupEvent="close"
  >
    <template #custom-content>
      <v-card-text>
        <div class="content-default-width pb-6">
          <UserDataCollection />
        </div>
      </v-card-text>
    </template>
  </Popup>
</template>

<script>
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import AppStateEnum from '@/enums/AppStateEnum';

import Popup from '@/components/base/Popup/Popup.vue';
import UserDataCollection from '@/components/views/Assessment/UserDataCollection/UserDataCollection';
import LocalStorageService from '@/services/LocalStorageService';

export default {
  name: PopupNamesEnum.USER_SEGMENTATION_POPUP,
  components: {
    Popup,
    UserDataCollection
  },
  props: {
    popupContext: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      popupSelector: this.camelToKebab(PopupNamesEnum.USER_SEGMENTATION_POPUP)
    };
  },
  computed: {
    isOnline() {
      return this.$store.getters['ContextStore/isOnline'];
    },
    isUserSegmentationFormValid() {
      return this.$store.getters['UserStore/isUserSegmentationFormValid'];
    },
    shouldHidePopup() {
      const shouldHidePopup = !!this.$store.getters[
        'LoginStore/getSeoRedirectRoute'
      ];
      return shouldHidePopup;
    },
    customPopupSelector() {
      const additionalSelector = this.shouldHidePopup
        ? 'hide-segmentation-popup'
        : '';
      return `${this.popupSelector} ${additionalSelector}`;
    }
  },
  methods: {
    close() {
      this.$store.dispatch('ManagePopupStore/closePopup', {
        name: PopupNamesEnum.USER_SEGMENTATION_POPUP
      });
      LocalStorageService.set('popupClosed', true);
    },
    save() {
      this.$store.dispatch('UserSegmentationDataStore/saveOnboardData');
      this.close();
    },
    toLibrary() {
      this.close();
      this.$router.push({
        name: AppStateEnum.MANAGE_PUBLICATION
      });
    },
    camelToKebab(string) {
      return string.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
    }
  }
};
</script>

<style lang="less">
.user-segmentation-popup {
  &.hide-segmentation-popup {
    display: none;
  }

  button.button-close {
    display: none;
  }
}

.user-segmentation-popup-dialog-class {
  z-index: 5000 !important;
}
</style>
