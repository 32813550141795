import filter from 'lodash/filter';
import Locator from '@shared/publication/locator';
import Highlighter from '@shared/publication/highlighter';

const NON_SELECTABLE = '[data-selectable=none]';
const GENERIC_ANNOTATION_CLASS = 'nota-annotation';
const READER_ANNOTAION_CLASS = GENERIC_ANNOTATION_CLASS + '-reader';

/**
 * Finds and highlights element in text
 * @param {Object} materialItem  - elementToHighlight
 * @param {HTMLElement} container
 * @param {String} categoryClass
 */
function highlightMaterialItem(materialItem, container, categoryClass) {
  const { prefix, idAttribute, readerClass } = materialItem.getHighlightData();
  const className = `${categoryClass} ${readerClass} ${prefix}-${materialItem.id}`;
  const start = materialItem?.start || materialItem?.locator?.startLocator;
  const end = materialItem?.end || materialItem?.locator?.endLocator;
  const rangeLocator = new Locator.InTextRangeLocator(start, end);

  Highlighter.updateDecoration(null, rangeLocator, container, className);

  const annWrapperNodes = container.querySelectorAll(
    `.${prefix}-${materialItem.id}`
  );
  const annWrappers = Array.from(annWrapperNodes);
  const wrappersLength = annWrappers.length;
  for (let i = 0; i < wrappersLength; i++) {
    annWrappers[i].setAttribute(idAttribute, materialItem.id);
  }
}

function _serializeStyleRules(rules) {
  let preparedRules = '';
  switch (typeof rules) {
    case 'string':
      return rules;
    case 'object':
      Object.keys(rules).forEach(key => {
        const newKey = key.replace(/[A-Z]/g, repl => '-' + repl.toLowerCase());
        preparedRules += `${newKey}: ${rules[key]};`;
      });
      return preparedRules;
  }
}

function clearCategoryStyles({ styleContainer, NS }) {
  const styleEl = styleContainer.querySelector(`style[data-id="${NS}"]`);
  if (styleEl) {
    styleEl.innerHTML = '';
  }
}

function injectCategoryStyles({ styleContainer, styles, NS }) {
  let styleEl = styleContainer.querySelector(`style[data-id="${NS}"]`);
  if (!styleEl) {
    styleEl = window.document.createElement('style');
    styleEl.type = 'text/css';
    styleEl.setAttribute('data-id', NS);
    styleContainer.appendChild(styleEl);
  }
  const elementStyles = [];
  Object.keys(styles).forEach(selector => {
    const rules = styles[selector];
    const styleContent = `${selector} {${_serializeStyleRules(rules)}}`;
    elementStyles.push(styleContent);
  });
  const styleElContent = ' ' + elementStyles.join('\n');
  styleEl.innerHTML += styleElContent;
}

function unhighlightMaterialItem(materialItem, container) {
  const { idAttribute } = materialItem.getHighlightData();
  const highlights = container.querySelectorAll(
    `[${idAttribute}="${materialItem.id}"]`
  );
  Highlighter.undecorateElements(highlights);
}

function unhighlightByAnnClass(annClass, container) {
  Highlighter.undecorateByClass(annClass, container);
}

function getAnnotationByEventPath(path) {
  return path.filter(
    element =>
      element.classList && element.classList.contains(GENERIC_ANNOTATION_CLASS)
  );
}

/**
 *
 * @param {InTextLocator} start
 * @param {InTextLocator} end
 * @param {HTMLElement} container
 * @returns {Array} normalized compound ranges
 */
function getNormalizeCompoundRange(start, end, container) {
  let compoundRangeStart = _getStartCompoundRange(start, container);
  if (!compoundRangeStart) {
    return null;
  }

  let compoundRangeEnd = _getEndCompoundRange(end, container);
  if (!compoundRangeEnd) {
    return null;
  }

  let range = container.ownerDocument.createRange();
  range.setStart(compoundRangeStart.container, compoundRangeStart.offset);
  range.setEnd(compoundRangeEnd.container, compoundRangeEnd.offset);

  return normalizeCompoundRange(range);
}

function _getStartCompoundRange(start, container) {
  return getStartingRangeBoundaryByLocator(start, container, false);
}

function _getEndCompoundRange(end, container) {
  return getStartingRangeBoundaryByLocator(end, container, true);
}

function normalizeCompoundRange(range) {
  let startContainer = range.startContainer;
  let startOffset = range.startOffset;
  let endContainer = range.endContainer;
  let endOffset = range.endOffset;
  if (startContainer === endContainer) {
    return [range];
  }

  let textNodes = [];
  collectRangeTextNodes(textNodes, startContainer, endContainer);
  if (textNodes.length === 1) {
    return [range];
  }

  let isCorrectTextNodes =
    startContainer === textNodes[0] &&
    endContainer === textNodes[textNodes.length - 1];
  if (!isCorrectTextNodes) {
    return null;
  }

  textNodes = filter(textNodes, function(textNode) {
    return !textNode.parentNode.closest(NON_SELECTABLE) && textNode.data !== '';
  });

  let ranges = textNodes.map(function(textNode) {
    let r = document.createRange();
    r.setStart(textNode, 0);
    r.setEnd(textNode, textNode.data.length);
    return r;
  });

  ranges[0].setStart(startContainer, startOffset);
  ranges[ranges.length - 1].setEnd(endContainer, endOffset);
  if (ranges[ranges.length - 1].endOffset === 0) {
    ranges.pop();
  }
  return ranges;
}

function collectRangeTextNodes(textNodes, node, endNode, singleElementMode) {
  while (node !== endNode) {
    if (_isCompoundElement(node)) {
      if (collectRangeTextNodes(textNodes, node.firstChild, endNode, true)) {
        return true;
      }
    } else if (_isValidTextNode(node)) {
      textNodes.push(node);
    }

    if (node.nextSibling === null) {
      if (singleElementMode) {
        return false;
      } else {
        do {
          node = node.parentNode;
        } while (node.nextSibling === null);
        node = node.nextSibling;
      }
    } else {
      node = node.nextSibling;
    }
  }

  textNodes.push(endNode);
  return true;
}

function getStartingRangeBoundaryByLocator(
  locator,
  paraContainer,
  isEndingBoundary
) {
  return getRangeBoundaryByLocator(locator, paraContainer, isEndingBoundary);
}

function getRangeBoundaryByLocator(locator, paraContainer, isEndingBoundary) {
  let paraId = locator.prefixedParagraphId;
  let el =
    paraContainer.id === paraId
      ? paraContainer
      : paraContainer.querySelector('#' + paraId);
  let textNodes = extractTextNodes(el, function(_el) {
    return !_el.matches(NON_SELECTABLE);
  });
  let accumulatedOffset = 0;
  let currentNodeOffset = 0;
  for (let i = 0, le = textNodes.length; i < le; i++) {
    currentNodeOffset = textNodes[i].data.replace(/\s/, '').length;
    accumulatedOffset += currentNodeOffset;
    if (accumulatedOffset > locator.logicalCharOffset) {
      return {
        container: textNodes[i],
        offset:
          locator.logicalCharOffset - (accumulatedOffset - currentNodeOffset)
      };
    } else if (
      accumulatedOffset === locator.logicalCharOffset &&
      isEndingBoundary
    ) {
      return {
        container: textNodes[i],
        offset: currentNodeOffset
      };
    }
  }
  return null;
}

function extractTextNodes(node, filterFn) {
  let result = [];
  if (!node) {
    return result;
  }
  if (filterFn && !filterFn(node)) {
    return result;
  }
  node = node.firstChild;
  while (node) {
    if (_isValidTextNode(node)) {
      result.push(node);
    } else if (_isCompoundElement(node)) {
      result.push.apply(result, extractTextNodes(node, filterFn));
    }
    node = node.nextSibling;
  }
  return result;
}

function _isValidTextNode(node) {
  return node.nodeType === 3 && !/^\n\s+$/.test(node.data);
}

function _isCompoundElement(node) {
  return node.nodeType === 1 && node.firstChild !== null;
}

function getAnnotationClasses() {
  return [READER_ANNOTAION_CLASS];
}

export default {
  highlightMaterialItem,
  clearCategoryStyles,
  injectCategoryStyles,
  unhighlightMaterialItem,
  unhighlightByAnnClass,
  getAnnotationByEventPath,
  getNormalizeCompoundRange,
  getAnnotationClasses
};
