import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('CookieQueryParamsService.js');
import CookieStoreService from '@/services/CookieStoreService';

const QUERY_PARAM = 'query_params';

function getAllParams() {
  const queryParams = CookieStoreService.getCookie(QUERY_PARAM);
  let parsedParams = {};
  try {
    parsedParams = JSON.parse(queryParams) || {};
  } catch (error) {
    logger.error(`Ger error on parse params error: ${error}`);
  }
  return parsedParams;
}

function removeAllParams() {
  CookieStoreService.deleteCookie(QUERY_PARAM);
}

function removeParam(paramName) {
  const cookieQuery = getAllParams();
  delete cookieQuery[paramName];
  if (Object.keys(cookieQuery).length === 0) {
    removeAllParams();
  } else {
    CookieStoreService.setCookie(QUERY_PARAM, JSON.stringify(cookieQuery));
  }
}

export default {
  getAllParams,
  removeAllParams,
  removeParam
};
