import BrandsEnum from '@/enums/BrandsEnum';
import dayJS from '@/dayJS';

class AboutContext {
  constructor(contextParameters) {
    this.iconName = '';
    this.version = contextParameters.version;
    this.buildNumber = contextParameters.build_number;
    this.buildVersion = `${contextParameters.version}.${contextParameters.build_number}`;
    this.buildDate = dayJS
      .get(contextParameters.build_date)
      .format('MMMM D, YYYY');
  }
}

class FFAAboutContext extends AboutContext {
  constructor(contextParameters) {
    super(contextParameters);
    this.iconName = 'ico-logo-ffa';
  }
}

class OceanAboutContext extends AboutContext {
  constructor(contextParameters) {
    super(contextParameters);
    this.iconName = 'ico-logo-ocean';
  }
}

function create(brand, contextParameters) {
  switch (brand) {
    case BrandsEnum.FFA: {
      return new FFAAboutContext(contextParameters);
    }
    case BrandsEnum.OCEAN: {
      return new OceanAboutContext(contextParameters);
    }
  }
}

export default {
  create
};
