export default {
  StudyCourse: require('./controller/StudyCourse').default,
  StudyClass: require('./controller/StudyClass').default,
  UserPublication: require('./controller/UserPublication').default,
  UserProgress: require('./controller/UserProgress').default,
  SuggestionsProgress: require('./controller/SuggestionsProgress').default,
  ContentProvider: require('./controller/ContentProvider').default,
  Materials: require('./controller/Materials').default,
  Compilations: require('./controller/Compilations').default,
  FavouritePublications: require('./controller/FavouritePublications').default,
  BookmarksController: require('./controller/BookmarksController').default,
  UserSettingsController: require('./controller/UserSettingsController').default
};
