'use strict';

var TextUtils = require('./dom-utils/text-utils');
var MarkerUtils = require('./dom-utils/marker-utils');
var Locator = require('./locator');

var Highlighter = {
  /**
   * Example function.
   *
   * @param {Element} element
   */
  //not used disable for optimize build size
  // decorateSentences: function(element) {
  //   var preparedText = TextUtils.extractContent(element);
  //   var sentencesRealOffsets = TextUtils.locateSentencesInText(preparedText);
  //   var sentencesStableOffsets = TextUtils.turnIntoStableOffsets(
  //     sentencesRealOffsets,
  //     preparedText
  //   );
  //   var domLocatorBlocks = TextUtils.convertIntoDomLocatorBlocks(
  //     sentencesStableOffsets,
  //     element
  //   );

  //   // for demo purposes
  //   var decoratorClassNames = [
  //     'nota-annotation-cat-48-69-67-68-6c-69-67-68-74',
  //     'nota-annotation-cat-52-65-6d-65-6d-62-65-72'
  //   ];
  //   var decoratorClassIndex = 0;
  //   domLocatorBlocks.reverse().forEach(function(domLocatorBlock) {
  //     _decorateSection(
  //       domLocatorBlock,
  //       decoratorClassNames[(decoratorClassIndex = 1 - decoratorClassIndex)]
  //     );
  //   });
  // },

  /**
   *
   * @param {Element} element
   */
  wrapWords: function wrapWords(element) {
    if (
      element.hasAttribute('data-ww') &&
      element.innerHTML.indexOf('data-wi') !== -1
    ) {
      // TODO: check for rewraps?
      return;
    }

    var preparedText = TextUtils.extractContent(element);
    var wordsStableOffsets = TextUtils.collectWordsStableOffsets(preparedText);
    var domLocatorBlocks = TextUtils.convertIntoDomLocatorBlocks(
      wordsStableOffsets,
      element
    );

    var wordIndex = domLocatorBlocks.length;
    while (wordIndex--) {
      _decorateSection(domLocatorBlocks[wordIndex], '', { wi: wordIndex });
    }
    element.setAttribute('data-ww', '');

    const customSpaceElements = element.querySelectorAll('.dSpc');
    const spaceEscapeDblSeq = '\u00A0\u00A0';
    customSpaceElements.forEach(el => {
      el.replaceWith(document.createTextNode(spaceEscapeDblSeq));
    });
  },

  /**
   *
   * @param {InTextRangeLocator} locator
   * @param {string} decoratorClassName
   * @param {Object} [decoratorData]
   */
  decorateReadingPosition: function decorateReadingPosition(
    locator,
    decoratorClassName,
    decoratorData
  ) {
    var paragraphId = locator.startLocator.paragraphId;

    var paragraphElement = MarkerUtils.getParagraphById(paragraphId);
    if (!paragraphElement) {
      return;
    }

    var stableOffsets = [
      locator.startLocator.logicalCharOffset,
      locator.endLocator.logicalCharOffset
    ];
    var domLocatorBlocks = TextUtils.convertIntoDomLocatorBlocks(
      [stableOffsets],
      paragraphElement
    );
    _decorateSections(domLocatorBlocks, decoratorClassName, decoratorData);
  },

  /**
   *
   * @param {InTextLocator} locator
   * @param {Element} container
   * @param {string} decoratorClassName
   * @param {Object} [decoratorData]
   * @returns {Array.<Element>}
   */
  decorateInTextLocator: function decorateInTextLocator(
    locator,
    container,
    decoratorClassName,
    decoratorData
  ) {
    var paragraphElement = MarkerUtils.getParagraphById(
      locator.paragraphId,
      container
    );
    var domLocatorBlock = TextUtils.convertIntoDomLocatorBlock(
      [locator.logicalCharOffset, locator.logicalCharOffset + 1],
      paragraphElement
    );
    var decorators = _decorateSection(
      domLocatorBlock,
      decoratorClassName,
      decoratorData
    );
    return decorators;
  },

  /**
   *
   * @param {PublicationStartLocator|InTextLocator} locator
   * @param {Element} container
   * @param {number} offset
   * @param {Function} alignerFunction
   */
  decorateAndAlign: function decorateAndAlign(
    locator,
    container,
    offset,
    alignerFunction
  ) {
    var spacerClass = 'audio-highlight-spacer';
    var readingPositionDecorators = Highlighter.decorateInTextLocator(
      locator,
      container,
      spacerClass
    );
    alignerFunction(readingPositionDecorators[0], offset);
    readingPositionDecorators.forEach(_removeDecorator);
  },

  /**
   *
   * @param {InTextRangeLocator} inTextRangeLocator
   * @param {Element} container
   * @param {string} className
   * @param {number} [extendTo]
   */
  decorateInTextRangeLocator: function decorateInTextRangeLocator(
    inTextRangeLocator,
    container,
    className,
    extendTo
  ) {
    _processInTextRangeLocator(
      inTextRangeLocator,
      container,
      function decorateParagraphChunk(paragraph, chunkStart, chunkEnd) {
        var domLocatorBlock = TextUtils.convertIntoDomLocatorBlock(
          [chunkStart, chunkEnd],
          paragraph,
          extendTo
        );
        _decorateSection(domLocatorBlock, className);
      }
    );
  },

  decorateInTextRangeLocatorInPara: function decorateInTextRangeLocator(
    inTextRangeLocator,
    paragraph,
    className
  ) {
    var chunkStart = inTextRangeLocator.startLocator.logicalCharOffset;
    var chunkEnd = inTextRangeLocator.endLocator.logicalCharOffset;
    var domLocatorBlock = TextUtils.convertIntoDomLocatorBlock(
      [chunkStart, chunkEnd],
      paragraph
    );
    _decorateSection(domLocatorBlock, className);
  },

  /**
   *
   * @param {string} className
   * @param {Element} container
   */
  undecorateByClass: function undecorateByClass(className, container) {
    // TODO: extract into MarkerUtils
    var elements = Array.prototype.slice.call(
      container.querySelectorAll('.' + className)
    );
    elements.forEach(function(element) {
      _removeDecorator(element);
    });
  },

  /**
   *
   * @param  {Array<Element>} elements
   */
  undecorateElements: function(elements) {
    elements.forEach(_removeDecorator);
  },

  /**
   *
   * @param {InTextRangeLocator} inTextRangeLocator
   * @param {Element} container
   * @param {string} className
   * @param {number} [extendTo]
   */
  undecorateInTextRangeLocator: function undecorateInTextRangeLocator(
    inTextRangeLocator,
    container,
    className,
    extendTo
  ) {
    _processInTextRangeLocator(
      inTextRangeLocator,
      container,
      function undecorateParagraphChunk(paragraph, chunkStart, chunkEnd) {
        if (chunkStart === 0 && chunkEnd === Infinity) {
          Highlighter.undecorateByClass(className, paragraph);
          return;
        }

        var domLocatorBlock = TextUtils.convertIntoDomLocatorBlock(
          [chunkStart, chunkEnd],
          paragraph,
          extendTo
        );
        _undecorateSection(domLocatorBlock, className);
      }
    );
  },

  /**
   *
   * @param {string} oldClass
   * @param {string} newClass
   * @param {Element} container
   */
  redecorateByClass: function redecorateByClass(oldClass, newClass, container) {
    // TODO: extract into MarkerUtils
    var elements = Array.prototype.slice.call(
      container.querySelectorAll('.' + oldClass)
    );
    elements.forEach(function(element) {
      element.classList.remove(oldClass);
      element.classList.add(newClass);
    });
  },

  /**
   *
   * @param {?InTextRangeLocator} rangeFrom
   * @param {InTextRangeLocator} rangeTo
   * @param {Element} container
   * @param {string} className
   */
  updateDecoration: function updateDecoration(
    rangeFrom,
    rangeTo,
    container,
    className
  ) {
    var decorationDifference =
      rangeFrom === null
        ? [[], [rangeTo]]
        : Locator.getRangeDifference(rangeFrom, rangeTo);

    if (decorationDifference === null) {
      return;
    }

    decorationDifference[0].forEach(function undecorateDroppedLocator(
      rangeDropped
    ) {
      if (rangeDropped.startLocator.equals(rangeDropped.endLocator)) {
        return; // assertion
      }
      var extendTo = 0;

      if (!rangeDropped.endLocator.follows(rangeTo.startLocator)) {
        extendTo |= TextUtils.INCLUDE_TRAILING_WHITESPACE; // jshint ignore: line
      }
      if (!rangeDropped.startLocator.precedes(rangeTo.endLocator)) {
        extendTo |= TextUtils.INCLUDE_PRECEDING_WHITESPACE; // jshint ignore: line
      }
      Highlighter.undecorateInTextRangeLocator(
        rangeDropped,
        container,
        className,
        extendTo
      );
    });
    decorationDifference[1].forEach(function decorateGainedLocator(
      rangeGained
    ) {
      if (rangeGained.startLocator.equals(rangeGained.endLocator)) {
        return;
      }
      var extendTo = 0;
      if (rangeFrom && rangeGained.endLocator.equals(rangeFrom.startLocator)) {
        extendTo |= TextUtils.INCLUDE_TRAILING_WHITESPACE; // jshint ignore: line
      }
      if (rangeFrom && rangeGained.startLocator.equals(rangeFrom.endLocator)) {
        extendTo |= TextUtils.INCLUDE_PRECEDING_WHITESPACE; // jshint ignore: line
      }
      Highlighter.decorateInTextRangeLocator(
        rangeGained,
        container,
        className,
        extendTo
      );
    });
  },

  wrapSiblingElements: function wrapSiblingElements(
    elements,
    decoratorClassName
  ) {
    const decoratorElement = _createDecorator(elements[0], decoratorClassName);
    elements.forEach((element, index) => {
      const cloneNode = element.cloneNode(true);
      decoratorElement.appendChild(cloneNode);
      if (index > 0) {
        element.remove();
      }
    });
    elements[0].parentNode.replaceChild(decoratorElement, elements[0]);
  },

  decorateStableOffsets: function decorateStableOffsets(
    stableOffsets,
    element,
    decoratorClassName,
    decoratorData
  ) {
    var domLocatorBlocks = TextUtils.convertIntoDomLocatorBlocks(
      stableOffsets,
      element
    );

    domLocatorBlocks.reverse().forEach(function(section) {
      _decorateSection(section, decoratorClassName, decoratorData);
    });
  }
};

module.exports = Highlighter;
/**
 *
 * @param {InTextRangeLocator} inTextRangeLocator
 * @param {Element} container
 * @param {Function} paraChunkProcessor
 * @private
 */
function _processInTextRangeLocator(
  inTextRangeLocator,
  container,
  paraChunkProcessor
) {
  var startParagraphId = inTextRangeLocator.startLocator.paragraphId;
  var startParagraph = MarkerUtils.getParagraphById(
    startParagraphId,
    container
  );

  var endParagraphId = inTextRangeLocator.endLocator.paragraphId;
  var endParagraph = MarkerUtils.getParagraphById(endParagraphId, container);

  var processedParagraphs = MarkerUtils.getParagraphElementsInRange(
    startParagraph,
    endParagraph
  );
  processedParagraphs.forEach(function(paragraph) {
    Highlighter.wrapWords(paragraph);
    var chunkStart =
      paragraph === startParagraph
        ? inTextRangeLocator.startLocator.logicalCharOffset
        : 0;
    var chunkEnd =
      paragraph === endParagraph
        ? inTextRangeLocator.endLocator.logicalCharOffset
        : Infinity;
    paraChunkProcessor(paragraph, chunkStart, chunkEnd);
  });
}

/**
 *
 * @param {Array.<DomLocatorBlock>} domLocatorBlocks
 * @param {string} [decoratorClassName]
 * @param {Object} [decoratorData]
 * @returns {Array.<Element>}
 * @private
 */
function _decorateSections(
  domLocatorBlocks,
  decoratorClassName,
  decoratorData
) {
  var i = domLocatorBlocks.length;
  var sectionsDecorators = [];
  while (i--) {
    Array.prototype.unshift.apply(
      sectionsDecorators,
      _decorateSection(domLocatorBlocks[i], decoratorClassName, decoratorData)
    );
  }
  return sectionsDecorators;
}

/**
 *
 * @param {DomLocatorBlock} domLocatorBlock
 * @param {string} [decoratorClassName]
 * @param {Object} [decoratorData]
 * @returns {Array.<Element>}
 * @private
 */
function _decorateSection(domLocatorBlock, decoratorClassName, decoratorData) {
  var sectionDecorators = domLocatorBlock.map(function(domLocator) {
    return _decorateDomLocator(domLocator, decoratorClassName, decoratorData);
  });
  return sectionDecorators;
}

/**
 *
 * @param {DomLocatorBlock} domLocatorBlock
 * @param {string} decoratorClassName
 * @private
 */
function _undecorateSection(domLocatorBlock, decoratorClassName) {
  domLocatorBlock.forEach(function undecorateDomLocator(domLocator) {
    if (domLocator.start === domLocator.end) {
      // safety net, assertion actually
      return;
    }
    var cursorElement = domLocator.textNode;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      cursorElement = cursorElement.parentNode;
      if (cursorElement === null) {
        return;
      }

      if (
        cursorElement.classList &&
        cursorElement.classList.contains(decoratorClassName)
      ) {
        _removeDecorator(cursorElement);
        break;
      }
    }
  });
}

/**
 *
 * @param {DomLocator} domLocator
 * @param {string} [decoratorClassName]
 * @param {Object} [decoratorData]
 * @returns {Element}
 * @private
 */
function _decorateDomLocator(domLocator, decoratorClassName, decoratorData) {
  var textNode = domLocator.textNode;
  var start = domLocator.start;
  var end = domLocator.end;
  var decoratorElement = _createDecorator(
    textNode,
    decoratorClassName,
    decoratorData
  );
  var decorableTextNode = textNode;
  var textNodeLength = textNode.data.length;
  if (start !== 0) {
    decorableTextNode = textNode.splitText(start);
  }
  if (end !== textNodeLength) {
    decorableTextNode.splitText(end - start);
  }
  decorableTextNode.parentNode.insertBefore(
    decoratorElement,
    decorableTextNode
  );
  decoratorElement.appendChild(decorableTextNode);
  return decoratorElement;
}

/**
 *
 * @param {Node} decoratedNode
 * @param {string} [decoratorClassName]
 * @param {Object} [decoratorData]
 * @returns {Element}
 * @private
 */
function _createDecorator(decoratedNode, decoratorClassName, decoratorData) {
  var decoratorElement = decoratedNode.ownerDocument.createElement('span');
  if (decoratorClassName && typeof decoratorClassName === 'string') {
    decoratorElement.className = decoratorClassName;
  }
  if (decoratorData && typeof decoratorData === 'object') {
    Object.keys(decoratorData).forEach(function(key) {
      decoratorElement.setAttribute('data-' + key, decoratorData[key]);
    });
  }
  return decoratorElement;
}

/**
 *
 * @param {Element} decoratorElement
 * @private
 */
function _removeDecorator(decoratorElement) {
  var parentNode = decoratorElement.parentNode;
  var childNodes = Array.prototype.slice.call(decoratorElement.childNodes);
  var documentFragment = decoratorElement.ownerDocument.createDocumentFragment();
  childNodes.forEach(function(node) {
    documentFragment.appendChild(node);
  });
  parentNode.replaceChild(documentFragment, decoratorElement);
  parentNode.normalize(); // TODO: test in IE
}
